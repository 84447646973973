import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const DischargeInfoTable = props => {
    const { data } = props;

    const getData = d => {
        const rows = [];
        const ccMap = {};

        if (d.roles.youthCountCC?.frequency) {
            for (const cc of d.roles.youthCountCC.frequency) {
                if (cc.value) {
                    ccMap[cc.value] = {
                        meanLength: 0,
                        stdLength: 0,
                        meanLevel: 0,
                        stdLevel: 0,
                        numDischarged: 0,
                        numYouth: cc.count,
                    };
                }
            }
        }
        if (d.roles.meanLengthCC?.frequency) {
            for (const cc of d.roles.meanLengthCC.frequency) {
                if (cc.value) {
                    ccMap[cc.value].meanLength += cc.meanLength;
                    ccMap[cc.value].stdLength += cc.sdLength;
                }
            }
        }
        if (d.roles.meanNeedsCC?.frequency) {
            for (const cc of d.roles.meanNeedsCC.frequency) {
                if (cc.value) {
                    ccMap[cc.value].meanLevel += cc.avg;
                    ccMap[cc.value].stdLevel += cc.stddev;
                }
            }
        }
        if (d.roles.dischargedCC?.frequency) {
            for (const cc of d.roles.dischargedCC.frequency) {
                if (cc.value) {
                    ccMap[cc.value].numDischarged = cc.count;
                }
            }
        }
        for (const cc of Object.keys(ccMap)) {
            rows.push({
                cc,
                careCoord: cc.split(', ')[0],
                ...ccMap[cc],
            });
        }
        return rows;
    };

    const getTotal = td => {
        const totalRow = {
            careCoord: 'Total:',
            cc: 'totalRow',
            numDischarged: 0,
            numYouth: 0,
            meanLength: 0,
            stdLength: 0,
            meanLevel: 0,
            stdLevel: 0,
        };
        for (const row of td) {
            totalRow.numYouth += row.numYouth;
            totalRow.numDischarged += row.numDischarged;
            totalRow.meanLength += row.meanLength;
            totalRow.meanLevel += row.meanLevel;
            totalRow.stdLength += row.stdLength;
            totalRow.stdLevel += row.stdLevel;
        }
        totalRow.meanLength = `${round(totalRow.meanLength / td.length, 2)} (${round(
            totalRow.stdLength / td.length,
            2
        )})`;
        totalRow.meanLevel = `${round(totalRow.meanLevel / td.length, 2)} (${round(totalRow.stdLevel / td.length, 2)})`;
        return totalRow;
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        {
            name: 'numYouth',
            label: 'Number of Youth',
            sort: true,
            filter: true,
        },
        {
            name: 'numDischarged',
            label: 'Number of Youth Discharged',
            tooltip:
                'The number of youth who were discharged during this date range for the selected evaluation cycles.',
            sort: true,
            filter: true,
        },
        {
            name: 'meanLength',
            label: 'Mean Length of Stay (SD)',
            tooltip: 'Value reported in days. If left blank, youth have not been discharged within the date range.',
            render: (_, row) => `${round(row.meanLength, 2)} (${round(row.stdLength, 2)})`,
            sort: true,
            filter: true,
        },
        {
            name: 'meanLevel',
            label: 'Mean Level of Needs Met (SD)',
            tooltip:
                'Upon discharge, level of needs met is assessed on a scale from 0 (no needs met) to 10 (all needs met). The value below reports the average and standard deviation for all youth who were discharged within the date range AND had a value for the Needs Met scale. N represents the number of youth included in the average. If left blank, youth have not yet been discharged within the date range OR level of needs met for discharged youth were not assessed.',
            render: (_, row) => `${round(row.meanLevel, 2)} (${round(row.stdLevel, 2)})`,
            sort: true,
            filter: true,
        },
    ];

    const tableData = getData(data);
    const totalRow = getTotal(tableData);

    return (
        <Table
            data={tableData}
            rowKey="cc"
            orderBy="careCoord"
            columns={cols}
            footer={totalRow}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="discharge-info"
        />
    );
};

DischargeInfoTable.propTypes = {
    data: PropTypes.object,
};

export default DischargeInfoTable;
