import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round, mean } from 'lodash';

const ItemQuestionTable = props => {
    const { wfiQuestions } = window.cccisd.appDefs;
    var { items, groupLevel } = props;

    const doAlt = items.includes('D9');

    const getData = () => {
        let rows = [];
        for (var group of Object.keys(props.groupLevelMap)) {
            var row = { group };
            var itemTotals = {};
            var groupItemN = {};
            for (var siteId of props.groupLevelMap[group]) {
                const site = props.data.groups.siteList.find(s => siteId === s.group.groupId);
                if (site) {
                    for (var item of items) {
                        const siteItemN = site.descendantRoles[props.respondent.name][item + 'N'];
                        const siteItemTotal = site.descendantRoles[props.respondent.name][item];
                        if (siteItemTotal) {
                            if (itemTotals[item]) {
                                itemTotals[item] += siteItemTotal;
                            } else {
                                itemTotals[item] = siteItemTotal;
                            }
                        }
                        if (siteItemN) {
                            if (groupItemN[item]) {
                                groupItemN[item] += siteItemN;
                            } else {
                                groupItemN[item] = siteItemN;
                            }
                        }
                    }
                }
            }
            let groupHasN = Object.keys(groupItemN).some(i => groupItemN[i]);

            if (groupHasN) {
                for (var i of items) {
                    const total = itemTotals[i] || 0;
                    const score = round(total / groupItemN[i] || 0, 2);
                    const percent = round((total / groupItemN[i]) * 100 || 0, 2);
                    const percentCell = `${total || 0} (${percent}%), N=${groupItemN[i] || 0}`;
                    const scoreCell = `${score}, N=${groupItemN[i] || 0}`;
                    row[i] = doAlt ? scoreCell : percentCell;
                }
                rows.push(row);
            }
        }
        return rows;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        ...items.map(i => ({
            name: i,
            label: i,
            tooltip: wfiQuestions[`${props.respondent.label.replace(' ', '')}_${i}`].question,
        })),
    ];
    const getTotal = d => {
        const tr = { group: 'Total:' };
        for (const row of d) {
            const keys = Object.keys(row).filter(r => r !== 'group');
            for (const q of keys) {
                const score = doAlt ? parseFloat(row[q].split(',')[0], 10) : parseFloat(row[q].split(' ')[0], 10);
                const n = parseInt(row[q].split('=')[1], 10);
                if (n > 0) {
                    const rowPercent = doAlt ? score : round((score / n) * 100 || 0, 2);
                    if (tr[q]) {
                        tr[q].push(rowPercent);
                    } else {
                        tr[q] = [rowPercent];
                    }
                }
            }
        }
        for (const k of Object.keys(tr).filter(r => r !== 'group')) {
            tr[k] = doAlt ? round(mean(tr[k]), 2) : `${round(mean(tr[k]), 2)}%`;
        }
        return tr;
    };

    const tableData = getData();
    const totalRow = getTotal(tableData);

    return (
        <Table
            data={tableData}
            rowKey="group"
            columns={cols}
            footer={totalRow}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            csvFilename="section-D-items"
            isCsvDownload
        />
    );
};

ItemQuestionTable.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.object,
    items: PropTypes.array,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default ItemQuestionTable;
