import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table from 'cccisd-table';
import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';
import Skeleton from 'react-loading-skeleton';
import sections from './sections.js';
import query from './query.graphql';
import { std } from './helpers.js';
import { round, max, min, mean } from 'lodash';

import Collapsable from 'js/vendor/reports/components/Collapsable';

import { showTable, showChart, renderSubtitle, renderDesc } from 'js/vendor/reports/helpers.js';

function ReportOverviewSection(props) {
    const [sectionData, setSectionData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getSectionData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);
    const getSectionData = async () => {
        const variables = {
            pawnIds: props.youthPawnIds || [],
            deploymentIds: props.filters.evalCycleSelectorField,
            ccList: props.filters.careCoordSelectorField.map(cc => {
                const pawnDashIndex = cc.lastIndexOf('-');
                return cc.substring(0, pawnDashIndex);
            }),
        };
        const response = await taskMasterClient.query({
            query,
            variables,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        setSectionData(response.data);
        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };
    const otherResponseTableData = (data, section) => {
        const rows = [];
        const key = `${section.name}_otherResponses`;
        if (data.flows[key].frequency) {
            for (const cc of data.flows[key].frequency) {
                for (const res of cc.frequency) {
                    if (res.value) {
                        rows.push({
                            ccId: cc.value,
                            careCoord: cc.value.split(',')[0],
                            response: res.value,
                        });
                    }
                }
            }
        }
        return rows;
    };

    const getTableData = (data, section, cols) => {
        const rows = [];
        const ccMap = {};
        for (const col of cols) {
            if (!['careCoord', 'numberOfYouth'].includes(col.name)) {
                const key = `${section.name}_${col.name}`;
                if (data.flows[key].frequency) {
                    for (const cc of data.flows[key].frequency) {
                        if (ccMap[cc.value]) {
                            ccMap[cc.value].numberOfYouth += cc.valueCount;
                            if (ccMap[cc.value][col.name]) {
                                ccMap[cc.value][col.name] += cc.valueCount;
                            } else {
                                ccMap[cc.value][col.name] = cc.valueCount;
                            }
                        } else {
                            ccMap[cc.value] = {
                                [col.name]: cc.valueCount,
                                numberOfYouth: cc.valueCount,
                            };
                        }
                    }
                }
            }
        }
        for (const cc of Object.keys(ccMap)) {
            const row = ccMap[cc];
            row.careCoord = cc.split(',')[0];
            row.ccId = cc;
            for (const col of cols) {
                if (!row[col.name]) {
                    row[col.name] = 0;
                }
            }
            rows.push(row);
        }
        return rows;
    };

    const ageAtEnrollTableData = data => {
        const rows = [];
        for (const cc of data.flows.ageAtEnrollment.frequency) {
            const ccAgeList = [];
            for (const age of cc.frequency) {
                if (age.value && parseInt(age.value, 10) && parseInt(age.value, 10) < 100) {
                    for (let i = 0; i < age.valueCount; i++) {
                        const c = parseInt(age.value, 10);
                        ccAgeList.push(c);
                    }
                }
            }
            rows.push({
                ccId: cc.value,
                careCoord: cc.value.split(',')[0],
                numberOfYouth: ccAgeList.length,
                minAge: min(ccAgeList),
                maxAge: max(ccAgeList),
                avgAge: round(mean(ccAgeList), 2),
                sdAge: round(std(ccAgeList), 2),
            });
        }

        return rows;
    };

    const summarize = data => {
        const summary = {};
        if (!data) return summary;
        data.forEach(row => {
            for (const column of Object.keys(row)) {
                if (column in summary) {
                    summary[column] += row[column];
                } else {
                    summary[column] = row[column];
                }
            }
        });
        summary.careCoord = 'Total:';
        summary.ccId = 'total-row';
        return summary;
    };

    const getAgeAtEnrollTotal = data => {
        const allAgeList = [];
        for (const cc of data.flows.ageAtEnrollment.frequency) {
            for (const age of cc.frequency) {
                if (age.value && parseInt(age.value, 10) && parseInt(age.value, 10) < 100) {
                    for (let i = 0; i < age.valueCount; i++) {
                        const c = parseInt(age.value, 10);
                        allAgeList.push(c);
                    }
                }
            }
        }
        const ageMean = round(mean(allAgeList), 2);
        const ageSD = round(std(allAgeList), 2);
        const ageMin = min(allAgeList);
        const ageMax = max(allAgeList);
        const d = {
            careCoord: 'Total:',
            numberOfYouth: allAgeList.length,
            meanAgeAtEnrollmentSD: `${ageMean} (${ageSD})`,
            range: `${ageMin} - ${ageMax}`,
        };
        return d;
    };

    if (loading) {
        return <Skeleton rows={15} />;
    }

    return (
        <>
            {sections.map(section => {
                const tableData =
                    !loading && section.name === 'ageAtEnrollment'
                        ? ageAtEnrollTableData(sectionData)
                        : getTableData(sectionData, section, section.columns);
                const tableContData =
                    !loading && section.columnsCont && getTableData(sectionData, section, section.columnsCont);
                const tableOtherData =
                    !loading && section.otherResponses ? otherResponseTableData(sectionData, section) : [];
                const tableSummary =
                    !loading && section.name === 'ageAtEnrollment'
                        ? getAgeAtEnrollTotal(sectionData)
                        : summarize(tableData);
                const tableContSummary = !loading && section.columnsCont && summarize(tableContData);
                return (
                    <div key={section.title}>
                        {renderSubtitle(section.title)}
                        {section.desc && renderDesc(section.desc)}
                        {showChart(section.chart(section.convertData(sectionData, section)), loading)}
                        {showTable(
                            <Table
                                columns={section.columns}
                                data={tableData}
                                rowKey="ccId"
                                orderBy="careCoord"
                                showPerPageOptions={false}
                                isCsvDownload
                                footer={tableSummary}
                            />,
                            loading
                        )}
                        {section.columnsCont &&
                            showTable(
                                <Table
                                    columns={section.columnsCont}
                                    data={tableContData}
                                    rowKey="ccId"
                                    orderBy="careCoord"
                                    showPerPageOptions={false}
                                    isCsvDownload
                                    footer={tableContSummary}
                                />,
                                loading
                            )}
                        {section.otherResponses &&
                            showTable(
                                <Collapsable
                                    title={`${section.title} "Other" Responses`}
                                    collapsed
                                    content={
                                        <Table
                                            data={tableOtherData}
                                            rowKey="ccId"
                                            orderBy="careCoord"
                                            columns={[
                                                {
                                                    name: 'careCoord',
                                                    label: 'Care Coordinator',
                                                    filter: true,
                                                    sort: true,
                                                },
                                                {
                                                    name: 'response',
                                                    label: 'Response',
                                                    sort: true,
                                                    filter: true,
                                                },
                                            ]}
                                            showPerPageOptions={false}
                                            isCsvDownload
                                        />
                                    }
                                />,
                                loading
                            )}
                    </div>
                );
            })}
        </>
    );
}

ReportOverviewSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(ReportOverviewSection);
