import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const DuplicateInfoTable = props => {
    const { data, groupLevel, groupLevelMap } = props;

    const getData = () => {
        let rows = [];
        for (var youth of data) {
            if (youth.assignmentProgressList.length > 1) {
                rows.push({
                    wrapId: youth.fields.wrapId,
                    group: getGroupOfSiteId(youth.parentGroup.site.group.groupId),
                    cycles: youth.assignmentProgressList.map(cycle => cycle.deployment.label),
                });
            }
        }
        return rows;
    };

    const getGroupOfSiteId = siteId => {
        for (var group of Object.keys(groupLevelMap)) {
            if (groupLevelMap[group].includes(siteId)) {
                return group;
            }
        }
    };

    const cols = [
        {
            name: 'wrapId',
            label: 'Wrap ID',
        },
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
        },
        {
            name: 'cycles',
            label: 'Evaluation Cycles',
        },
    ];

    const tableData = getData();

    return <Table data={tableData} rowKey="wrapId" columns={cols} showPerPageOptions={false} isCsvDownload />;
};

DuplicateInfoTable.propTypes = {
    data: PropTypes.array,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default DuplicateInfoTable;
