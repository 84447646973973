import React from 'react';
import PropTypes from 'prop-types';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import style from './style.css';

const ReportView = props => {
    return (
        <Collapsable
            view
            title={props.title}
            content={
                <>
                    {props.desc && <div className={style.desc} dangerouslySetInnerHTML={{ __html: props.desc }} />}
                    {props.children}
                </>
            }
        />
    );
};

ReportView.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    children: PropTypes.array,
};

export default ReportView;
