import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import hash from 'object-hash';
import { renderDescription } from 'js/vendor/reports/helpers.js';

const TableRender = props => {
    const { settings, filters, table, loadData, renderDefault } = props;
    useEffect(() => {
        if (!table.component) {
            loadData();
        }
    }, [hash(filters)]);
    const renderIt = () => {
        return (
            <>
                {settings[table.name + 'Table'] && renderDefault()}
                {table.bottomText && renderDescription(table.bottomText)}
            </>
        );
    };

    if (table.collapsed) {
        return <Collapsable collapsed title={table.label} content={renderIt()} />;
    }

    return <div>{renderIt()}</div>;
};

TableRender.propTypes = {
    settings: PropTypes.object,
    table: PropTypes.object,
    loadData: PropTypes.func,
    renderDefault: PropTypes.func,
    filters: PropTypes.object,
};

export default TableRender;
