import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from 'cccisd-nivo/pie';
import { pieLegend } from 'js/vendor/reports/helpers.js';
import { rowData } from '../../tables/MeetingAttendanceTable/rows.js';
import { round } from 'lodash';

const MeetingAttendanceChart = props => {
    const getData = d => {
        let totals = { proPresent: 0, natPresent: 0, proTeam: 0, natTeam: 0 };
        const data = d.flows.assignmentProgressSummary;
        for (var kind of rowData) {
            if (kind.natural) {
                totals.natTeam += data[kind.name + 'Team'];
                totals.natPresent += data[kind.name + 'Present'];
            } else {
                totals.proTeam += data[kind.name + 'Team'];
                totals.proPresent += data[kind.name + 'Present'];
            }
        }
        const proAttendance = round(
            (totals.proPresent / (totals.proPresent + totals.natPresent)) * 100
        );
        const natAttendance = round(
            (totals.natPresent / (totals.proPresent + totals.natPresent)) * 100
        );
        return [
            {
                id: 'Professional Supports Percent Attendance',
                label: `Professional Supports (${proAttendance}%)`,
                value: proAttendance,
            },
            {
                id: 'Natural Supports Percent Attendance',
                label: `Natural Supports (${natAttendance}%)`,
                value: natAttendance,
            },
        ];
    };

    const data = getData(props.data);

    return (
        <div style={{ width: '770px', height: '500px' }}>
            <ResponsivePie
                data={data}
                enableArcLinkLabels={false}
                theme={{ fontSize: 16 }}
                keys={['id']}
                arcLabelsSkipAngle={10}
                arcLinkLabelsSkipAngle={20}
                legends={pieLegend}
                margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
            />
        </div>
    );
};

MeetingAttendanceChart.propTypes = {
    data: PropTypes.object,
};

export default MeetingAttendanceChart;
