import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { client } from 'cccisd-apollo';
import Skeleton from 'react-loading-skeleton';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import { getGroupLevelMap } from 'js/vendor/reports/helpers.js';
// import { getTeamMemberFilter } from 'js/vendor/reports/reportTemplates/WFI-EZ/Youth/queryFilters.js';
import { setWidgetLoaded } from 'js/reducers/report.js';

const YouthFieldCountTable = props => {
    const [data, setData] = useState([]);
    const [otherData, setOtherData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.groups) {
            setLoading(true);
            getData();
        }
    }, [props.filters, props.groups]);

    useEffect(() => {
        getData();
    }, []);

    const groupLevel = props.settings.groupLevel || 'group';

    const groupLevelMap = getGroupLevelMap(
        props.groups,
        groupLevel,
        props.filters.siteSelectorField
    );

    const getData = async () => {
        const response = await client.query({
            query: props.query,
            variables: {
                pawnIds: props.youthPawnIds,
                // filter: getTeamMemberFilter(props.filters.teamMemberSelectorField, false, true),
                deploymentIds: props.filters.evalCycleSelectorField || [],
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        let tableData = [];
        let responseMap = {};
        let totalRow = {
            value: 'Total:',
        };
        let otherResponseMap = {};
        const { deploymentList } = response.data.flows;
        let pawns = {};
        let newRespondentList = {};

        for (var deployment of deploymentList) {
            const { assignmentProgressList, respondentList } = deployment;
            if (assignmentProgressList) {
                for (var youth of assignmentProgressList) {
                    const res = youth.pawn.fields[props.field];
                    if (res) {
                        if (responseMap[res]) {
                            responseMap[res] += 1;
                        } else {
                            responseMap[res] = 1;
                        }
                        if (res === 'other') {
                            const otherRes = youth.pawn.fields[props.otherField];
                            const youthSite = Object.keys(groupLevelMap).find(group =>
                                groupLevelMap[group].includes(youth.pawn.groupId)
                            );
                            if (otherResponseMap[youthSite]) {
                                if (otherResponseMap[youthSite][otherRes]) {
                                    otherResponseMap[youthSite][otherRes] += 1;
                                } else {
                                    otherResponseMap[youthSite][otherRes] = 1;
                                }
                            } else {
                                otherResponseMap[youthSite] = { [otherRes]: 1 };
                            }
                        }
                    }
                }
            }

            if (respondentList) {
                // get unique set of respondentList
                newRespondentList = respondentList.filter(respondent => {
                    if (pawns[respondent.pawn.pawn.pawnId]) {
                        return false;
                    }
                    pawns[respondent.pawn.pawn.pawnId] = true;
                    return true;
                });
                for (var ryouth of newRespondentList) {
                    const res = ryouth.pawn.fields[props.field];
                    if (res) {
                        if (responseMap[res]) {
                            responseMap[res] += 1;
                        } else {
                            responseMap[res] = 1;
                        }
                        if (res === 'other') {
                            const otherRes = ryouth.pawn.fields[props.otherField];
                            const youthSite = Object.keys(groupLevelMap).find(group =>
                                groupLevelMap[group].includes(ryouth.pawn.pawn.groupId)
                            );
                            if (otherResponseMap[youthSite]) {
                                if (otherResponseMap[youthSite][otherRes]) {
                                    otherResponseMap[youthSite][otherRes] += 1;
                                } else {
                                    otherResponseMap[youthSite][otherRes] = 1;
                                }
                            } else {
                                otherResponseMap[youthSite] = { [otherRes]: 1 };
                            }
                        }
                    }
                }
            }
        }
        let rows = [];
        for (var group of Object.keys(otherResponseMap)) {
            for (var res of Object.keys(otherResponseMap[group])) {
                rows.push({ label: group, response: res, count: otherResponseMap[group][res] });
            }
        }
        setOtherData(rows);
        var total = 0;
        for (var r of Object.keys(responseMap)) {
            if (props.filters.teamMemberSelectorField.includes(r)) {
                tableData.push({ value: unpackCamel(r), count: responseMap[r] });
                total += responseMap[r];
            }
        }
        totalRow.count = total;
        tableData.push(totalRow);
        setData(tableData);
        setLoading(false);
        props.setWidgetLoaded(props.table.name + 'Table');
    };

    const unpackCamel = s =>
        s.replace(/([A-Z])/g, ' $1').replace(/^./, str => {
            return str.toUpperCase();
        });

    const getColumns = () => {
        return [
            {
                name: 'value',
                label: unpackCamel(props.field),
                sort: true,
                filter: true,
            },
            { name: 'count', label: 'Count', sort: true, filter: true },
        ];
    };

    const getOtherColumns = () => {
        return [
            {
                name: 'label',
                label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
                sort: true,
                filter: true,
            },
            { name: 'response', label: 'Response', sort: true, filter: true },
            { name: 'count', label: 'Count', sort: true, filter: true },
        ];
    };

    if (data.length === 0 && !loading) {
        return <h4>There were no other responses for youth counted in this report</h4>;
    }

    let foot = data[data.length - 1];
    return (
        <>
            {loading ? (
                <Skeleton count={15} />
            ) : (
                <Table
                    isCsvDownload
                    columns={getColumns()}
                    data={data.slice(0, data.length - 1)}
                    rowKey="value"
                    orderBy="value"
                    showPerPageOptions={false}
                    footer={foot}
                />
            )}

            <Collapsable
                collapsed
                title='Team Member "Other" Responses'
                content={
                    loading ? (
                        <Skeleton count={15} />
                    ) : (
                        <Table
                            isCsvDownload
                            columns={getOtherColumns()}
                            data={otherData}
                            rowKey="label"
                            orderBy="label"
                            showPerPageOptions={false}
                        />
                    )
                }
            />
        </>
    );
};

YouthFieldCountTable.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    field: PropTypes.string,
    otherField: PropTypes.string,
    table: PropTypes.object,
    query: PropTypes.object,
    setData: PropTypes.func,
    setOtherTableLoading: PropTypes.func,
    responsePrepend: PropTypes.string,
    groupLevel: PropTypes.string,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    setWidgetLoaded: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(YouthFieldCountTable);
