import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { showTable, showChart, renderTitle, renderSubtitle, renderDescription } from 'js/vendor/reports/helpers.js';
import Collapsable from 'js/vendor/reports/components/Collapsable';

import subscales from './subscales.js';
import OverallKeyElementsChart from './charts/OverallKeyElementsChart.js';
import SubscaleItemChart from './charts/SubscaleItemChart.js';
import ItemMeanTable from './tables/ItemMeanTable.js';
import ItemQuestionTable from './tables/ItemQuestionTable.js';
import OverallKeyElementsTable from './tables/OverallKeyElementsTable.js';
import SubscaleItemTable from './tables/SubscaleItemTable.js';
import CommentsTable from './tables/CommentsTable.js';
import BenchmarkTable from './tables/BenchmarkTable.js';
import query from './query.graphql';

const SecBFidelitySection = props => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const { respondentSelectorField } = props.filters;

    const respondents = [
        { id: '3', label: 'Caregiver', key: 'cg' },
        { id: '5', label: 'Youth', key: 'y' },
    ].filter(p => respondentSelectorField.includes(p.id));

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getSectionData();
        }
    }, [
        props.filters.evalCycleSelectorField,
        props.filters.siteSelectorField,
        props.youthPawnIds,
        respondentSelectorField,
    ]);

    const getSectionData = async () => {
        const variables = {
            deploymentIds: props.filters.evalCycleSelectorField || [],
            ccList: props.filters.careCoordSelectorField.map(cc => {
                const pawnDashIndex = cc.lastIndexOf('-');
                return cc.substring(0, pawnDashIndex);
            }),
        };
        const response = await taskMasterClient.query({
            query,
            variables,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        setData(response.data);
        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };

    const levels = [
        { name: 'highFidelity', label: 'High Fidelity' },
        { name: 'adequate', label: 'Adequate' },
        { name: 'borderline', label: 'Borderline' },
        { name: 'inadequate', label: 'Inadequate' },
    ];

    return (
        <div>
            {renderTitle('Key Elements and Overall Fidelity')}
            {showChart(<OverallKeyElementsChart data={data} respondents={respondents} />, loading)}
            {respondentSelectorField.includes('3') &&
                renderSubtitle('Section B. Key Elements for WFI-EZ Caregiver Form')}
            {respondentSelectorField.includes('3') &&
                showChart(
                    <OverallKeyElementsChart data={data} respondents={respondents.filter(r => r.id === '3')} />,
                    loading
                )}
            {respondentSelectorField.includes('3') &&
                showTable(
                    <OverallKeyElementsTable data={data} respondent={respondents.find(r => r.id === '3')} />,
                    loading
                )}
            {respondentSelectorField.includes('3') &&
                showTable(<BenchmarkTable type="Caregiver" upper scales={subscales} levels={levels} />)}
            {respondentSelectorField.includes('5') && renderSubtitle('Section B. Key Elements for WFI-EZ Youth Form')}
            {respondentSelectorField.includes('5') &&
                showChart(
                    <OverallKeyElementsChart data={data} respondents={respondents.filter(r => r.id === '5')} />,
                    loading
                )}
            {respondentSelectorField.includes('5') &&
                showTable(
                    <OverallKeyElementsTable data={data} respondent={respondents.find(r => r.id === '5')} />,
                    loading
                )}
            {respondentSelectorField.includes('5') &&
                showTable(<BenchmarkTable type="Youth" upper scales={subscales} levels={levels} />)}

            {renderTitle('Subscales and Item Responses')}
            {respondents.map(res => (
                <div key={res.id}>
                    {subscales
                        .filter(s => s.items)
                        .map(s => (
                            <div key={s.label}>
                                {renderSubtitle(s.label)}
                                {s.desc}
                                {showChart(
                                    <SubscaleItemChart data={data} subscale={s} respondents={respondents} />,
                                    loading
                                )}
                                {renderSubtitle(
                                    `Section B ${s.label} for WFI-EZ ${res.id === '3' ? 'Caregiver' : 'Youth'} Form`
                                )}
                                {showChart(<SubscaleItemChart data={data} subscale={s} respondents={[res]} />, loading)}
                                {showTable(<SubscaleItemTable data={data} items={s.items} respondent={res} />, loading)}
                                {showTable(<ItemMeanTable respondent={res} items={s.items} />, loading)}

                                {renderDescription('The table below presents the survey question for each item.')}
                                {showTable(<ItemQuestionTable respondent={res} items={s.items} />, loading)}
                            </div>
                        ))}
                    <Collapsable
                        collapsed
                        title={`Additional comments from ${res.label} form`}
                        content={showTable(<CommentsTable data={data} respondent={res} />, loading)}
                    />
                </div>
            ))}
        </div>
    );
};

SecBFidelitySection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded })(SecBFidelitySection);
