import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const ItemQuestionMeans = props => {
    const { wfiQuestions } = window.cccisd.appDefs;
    const { items, level } = props;

    const getData = () => {
        let rows = [];
        for (var item of items) {
            const { Item_Lower, Item_Upper, WS_Mean, WS_SD } = wfiQuestions[`${level}_${item}`];
            rows.push({ item, Item_Lower, Item_Upper, WS_Mean, WS_SD });
        }
        return rows;
    };

    const cols = [
        { name: 'item', label: 'Item' },
        { name: 'Item_Lower', label: 'Lower' },
        { name: 'Item_Upper', label: 'Upper' },
        { name: 'WS_Mean', label: `Wrapstat ${level.replace(/([a-z](?=[A-Z]))/g, '$1 ')} Mean` },
        {
            name: 'WS_SD',
            label: `Wrapstat ${level.replace(/([a-z](?=[A-Z]))/g, '$1 ')} Standard Deviation`,
        },
    ];
    return (
        <Table isCsvDownload columns={cols} data={getData()} rowKey="item" orderBy="item" showPerPageOptions={false} />
    );
};

ItemQuestionMeans.propTypes = {
    level: PropTypes.string,
    items: PropTypes.array,
};

export default ItemQuestionMeans;
