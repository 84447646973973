import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const EvaluatorTypeTable = props => {
    const { groupLevel, groupLevelMap, data } = props;

    const labels = {
        '1': {
            label: 'Internal Staff',
            name: 'internal',
        },
        '2': {
            label: 'External Evaluator',
            name: 'external',
        },
    };

    const getData = d => {
        const rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            const youthInGroup = d.flows.assignmentProgressList.filter(y =>
                groupLevelMap[group].includes(y.pawn.parentGroup.site.group.groupId)
            );
            let groupTotal = { internal: 0, external: 0, groupSum: 0 };
            for (var youth of youthInGroup) {
                const evaluator = youth.devTags.CompletedBy;
                if (evaluator) {
                    groupTotal[labels[evaluator].name] += 1;
                    groupTotal.groupSum += 1;
                }
            }
            rows.push({
                group,
                internal: groupTotal.internal,
                external: groupTotal.external,
                groupSum: groupTotal.groupSum,
            });
        }
        return rows;
    };

    const getTotalRow = td => {
        let total = { internal: 0, external: 0, groupSum: 0 };
        for (var row of td) {
            total.internal += row.internal;
            total.external += row.external;
            total.groupSum += row.groupSum;
        }
        return {
            group: 'Total:',
            internal: `${total.internal} (${round((total.internal / total.groupSum) * 100, 2)}%)`,
            external: `${total.external} (${round((total.external / total.groupSum) * 100, 2)}%)`,
        };
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        ...Object.keys(labels).map(n => ({
            name: labels[n].name,
            label: labels[n].label,
            render(value, row) {
                return value > 0 ? `${value} (${round((value / row.groupSum) * 100, 2)}%)` : 0;
            },
        })),
    ];

    const tableData = getData(data);
    const totalRow = getTotalRow(tableData);

    return (
        <Table
            data={tableData}
            rowKey="group"
            columns={cols}
            showPerPageOptions={false}
            footer={totalRow}
            isCsvDownload
        />
    );
};

EvaluatorTypeTable.propTypes = {
    data: PropTypes.object,
    groupLevelMap: PropTypes.object,
    groupLevel: PropTypes.string,
};

export default EvaluatorTypeTable;
