import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import Collapsable from 'js/vendor/reports/components/Collapsable';

// queries
import monthsInWraparoundQuery from './graphql/monthsInWraparound.graphql';
import dischargeInfoQuery from './graphql/dischargeInformation.graphql';
import otherExitReasonQuery from './graphql/otherExitReason.graphql';
import dischargeSuccessRatesQuery from './graphql/dischargeSuccessRates.graphql';
import exitReasonQuery from './graphql/exitReason.graphql';

// charts
import MonthsInWraparoundChart from './charts/MonthsInWraparoundChart';
import DischargeSuccessRateChart from './charts/DischargeSuccessRateChart';
import ExitReasonChart from './charts/ExitReasonChart';

// tables
import ExitReasonOtherResponseTable from './tables/ExitReasonOtherResponseTable';
import ExitReasonsTables from './tables/ExitReasonsTables';
import MonthsInWraparoundTable from './tables/MonthsInWraparoundTable';
import DischargeInfoTable from './tables/DischargeInfoTable';

import { showTable, showChart, renderTitle, renderDesc, renderDescription } from 'js/vendor/reports/helpers.js';

function EnrollmentDischargeSection(props) {
    const [monthsInWraparoundData, setMonthsInWraparoundData] = useState(null);
    const [dischargeInfoData, setDischargeInfoData] = useState(null);
    const [otherExitReasonData, setOtherExitReasonData] = useState(null);
    const [dischargeSuccessRatesData, setDischargeSuccessRatesData] = useState(null);
    const [exitReasonData, setExitReasonData] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getData();
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getData = async () => {
        const queries = [
            { setter: setMonthsInWraparoundData, query: monthsInWraparoundQuery },
            { setter: setDischargeInfoData, query: dischargeInfoQuery },
            { setter: setDischargeSuccessRatesData, query: dischargeSuccessRatesQuery },
            { setter: setExitReasonData, query: exitReasonQuery },
            { setter: setOtherExitReasonData, query: otherExitReasonQuery },
        ].map(q => ({
            setter: q.setter,
            query: {
                query: q.query,
                variables: {
                    deploymentIds: props.filters.evalCycleSelectorField || [],
                    pawnIds: props.youthPawnIds || [],
                    ccList: props.filters.careCoordSelectorField.map(cc => {
                        const pawnDashIndex = cc.lastIndexOf('-');
                        return cc.substring(0, pawnDashIndex);
                    }),
                    dateTo: props.filters.dateSelectorTo,
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            },
        }));
        await Promise.all(
            queries.map(query =>
                taskMasterClient.query({ ...query.query, cancelTag: 'report' }).then(response => {
                    query.setter(response.data);
                    return false;
                })
            )
        );
        setLoading(false);

        props.setWidgetLoaded(props.section.name + 'Section');
    };

    return (
        <>
            {renderTitle('Months in Wraparound')}
            {renderDescription(
                'This section displays the mean length of time (months) that youth in the sample have been in Wraparound.'
            )}
            {showChart(<MonthsInWraparoundChart data={monthsInWraparoundData} />, loading)}
            {showTable(<MonthsInWraparoundTable data={monthsInWraparoundData} />, loading)}
            {renderTitle('Discharge Information')}
            {renderDesc(
                'This section displays discharge information for youth who were discharged during this date range for the selected evaluation cycles'
            )}
            {showTable(<DischargeInfoTable data={dischargeInfoData} />, loading)}
            {renderTitle('Discharge Success Rate')}
            {showChart(<DischargeSuccessRateChart data={dischargeSuccessRatesData} />, loading)}
            {renderTitle('Exit Reason')}
            {showChart(<ExitReasonChart data={exitReasonData} />, loading)}
            {showTable(<ExitReasonsTables data={exitReasonData} />, loading)}
            {showTable(
                <Collapsable
                    title={`Exit Reason "Other" Responses`}
                    collapsed
                    content={<ExitReasonOtherResponseTable data={otherExitReasonData} />}
                />,
                loading
            )}
        </>
    );
}

EnrollmentDischargeSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
};

const mapStateToProps = state => ({
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded })(EnrollmentDischargeSection);
