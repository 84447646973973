import Builder, { initialValues as builderInitialValues } from 'js/vendor/reports/widgets/TablePicker/Builder';
import Player from 'js/vendor/reports/widgets/TablePicker/Player';

// components
import RespondentSummarySection from './custom/sections/RespondentSummarySection';
import SampleResponseSummarySection from './custom/sections/SampleResponseSummarySection';
import ReportOverviewSection from './custom/sections/ReportOverviewSection';
import EnrollmentDischargeSection from './custom/sections/EnrollmentDischargeSection';
import SecAInvolvementSection from './custom/sections/SecAInvolvementSection';
import SecBFidelitySection from './custom/sections/SecBFidelitySection';
import SecBStrengthsSection from './custom/sections/SecBStrengthsSection';
import SecCSatisfactionSection from './custom/sections/SecCSatisfactionSection';
import SecDOutcomesSection from './custom/sections/SecDOutcomesSection';

export const templateViews = [
    {
        handle: 'summary',
        label: 'Respondent Summary',
        sections: [
            {
                name: 'respondentSummary',
                label: 'Respondent Summary',
                component: RespondentSummarySection,
            },
        ],
    },
    {
        handle: 'responseSummary',
        label: 'Sample and Response Summary',
        sections: [
            {
                name: 'responseSummary',
                label: 'Sample and Response Summary',
                component: SampleResponseSummarySection,
            },
        ],
    },
    {
        handle: 'reportOverview',
        label: 'Report Overview',
        sections: [
            {
                name: 'reportOverview',
                label: 'Report Overview',
                component: ReportOverviewSection,
            },
        ],
    },
    {
        handle: 'enrollDischarge',
        label: 'Enrollment and Discharge Information',
        sections: [
            {
                name: 'enrollDischarge',
                label: 'Enrollment and Discharge Information',
                component: EnrollmentDischargeSection,
            },
        ],
    },
    {
        handle: 'secAInvolvement',
        label: 'Section A. Wraparound Involvement',
        sections: [
            {
                name: 'secAInvolvement',
                label: 'Section A. Wraparound Involvement',
                component: SecAInvolvementSection,
            },
        ],
    },
    {
        handle: 'secBFidelity',
        label: 'Section B. Fidelity',
        sections: [
            {
                name: 'secBFidelity',
                label: 'Section B. Fidelity',
                component: SecBFidelitySection,
            },
        ],
    },
    {
        handle: 'secBStrengths',
        label: 'Section B. Strengths and Areas to Improve',
        sections: [
            {
                name: 'secBStrengths',
                label: 'Section B. Strengths and Areas to Improve',
                component: SecBStrengthsSection,
            },
        ],
    },
    {
        handle: 'secCSatisfaction',
        label: 'Section C. Satisfaction',
        sections: [
            {
                name: 'secCSatisfaction',
                label: 'Section C. Satisfaction',
                component: SecCSatisfactionSection,
            },
        ],
    },
    {
        handle: 'secDOutcomes',
        label: 'Section D. Outcomes',
        sections: [
            {
                name: 'secDOutcomes',
                label: 'Section D. Outcomes',
                component: SecDOutcomesSection,
            },
        ],
    },
];

export default templateViews.map(s => ({
    handle: s.handle,
    label: s.label,
    builderInitialValues,
    builder: Builder,
    player: Player,
}));
