import React from 'react';
import PropTypes from 'prop-types';
import { round } from 'lodash';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const ItemSubscaleChart = props => {
    const { data, item, respondent } = props;

    const getData = () => {
        const bars = { item };
        if (data.flows[respondent.label].frequency) {
            for (const cc of data.flows[respondent.label].frequency) {
                let ccValue = cc.value.split(', ')[0];
                bars[ccValue] = round(cc[`${item}avg`] / cc[`${item}count`], 2) * 100;
            }
        }
        return bars;
    };

    const chartData = getData();

    if (Object.keys(chartData).length < 2) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    const longestLabel = Math.max(
        ...Object.keys(chartData).map(o => {
            return o.length + 13;
        })
    );
    return (
        <div style={{ width: '770px', height: `${300}px` }}>
            <ResponsiveBar
                indexBy="item"
                groupMode="grouped"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={Object.keys(chartData).filter(k => k !== 'item')}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={[chartData]}
                margin={{ top: 15, right: 6.5 * longestLabel, bottom: 6.5, left: 50 }}
            />
        </div>
    );
};

ItemSubscaleChart.propTypes = {
    data: PropTypes.object,
    item: PropTypes.string,
    respondent: PropTypes.object,
};

export default ItemSubscaleChart;
