import React from 'react';
import SiteSelector from 'js/vendor/reports/filters/SiteSelector';
import EvalCycleSelector from 'js/vendor/reports/filters/EvalCycleSelectorWFI-EZ';
import DateSelector from 'js/vendor/reports/filters/DateSelector';
import ReportViewSelector from 'js/vendor/reports/filters/ReportViewSelector';
import RespondentSelector from 'js/vendor/reports/filters/RespondentSelector';
import TeamMemberSelector from 'js/vendor/reports/filters/TeamMemberSelector';
import FilterHack from 'js/vendor/reports/filters/FilterHack';
import style from 'js/vendor/reports/style.css';
import InfoTooltip from 'js/vendor/reports/components/InfoTooltip';
import widgets from './widgets.js';

import { Field, CccisdFieldWrapper } from 'cccisd-formik';

// Define filters you want to use

export default [
    {
        name: 'siteSelectorField',
        label: 'Site Selector',
        component: props => {
            return (
                <Field name="siteSelectorField">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <div className={style.flex}>
                                <SiteSelector field={field} form={form} {...props} />
                                <InfoTooltip text="Select sites for inclusion in this report" />
                            </div>
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: false,
    },
    {
        name: 'dateSelectorFrom',
        label: 'Date Selector (From)',
        component: props => {
            return (
                <div className={style.flex} style={{ marginRight: '0px' }}>
                    <Field name="dateSelectorFrom">
                        {({ field, form }) => (
                            <CccisdFieldWrapper field={field} form={form}>
                                <DateSelector dateFrom field={field} form={form} {...props} />
                            </CccisdFieldWrapper>
                        )}
                    </Field>
                    <div style={{ margin: '.25em .25em 0em .25em' }}>to</div>
                </div>
            );
        },
        isRequired: false,
    },
    {
        name: 'dateSelectorTo',
        label: 'Date Selector (To)',
        component: props => {
            return (
                <div className={style.flex}>
                    <Field name="dateSelectorTo">
                        {({ field, form }) => (
                            <CccisdFieldWrapper field={field} form={form}>
                                <DateSelector dateTo field={field} form={form} {...props} />
                            </CccisdFieldWrapper>
                        )}
                    </Field>
                    <InfoTooltip text="Data from evaluation cycles are included if the evaluation cycle was active at any point within the specified date range." />
                </div>
            );
        },
        isRequired: false,
    },
    {
        name: 'showHideView',
        label: 'View Selector',
        component: props => {
            return (
                <Field name="showHideView">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <div className={style.flex}>
                                <ReportViewSelector
                                    views={widgets
                                        .filter(w => w.handle !== 'summary')
                                        .map(w => w.label)}
                                    field={field}
                                    form={form}
                                    {...props}
                                />
                                <InfoTooltip text="Pick which parts of this report to generate" />
                            </div>
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: false,
    },
    {
        name: 'respondentSelectorField',
        label: 'Eval Cycle Selector',
        component: props => {
            return (
                <Field name="respondentSelectorField">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <div className={style.flex}>
                                <RespondentSelector field={field} form={form} {...props} />
                                <InfoTooltip text="Select respondent types for inclusion in this report" />
                            </div>
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: false,
    },
    {
        name: 'teamMemberSelectorField',
        label: 'Team Member Selector',
        component: props => {
            return (
                <Field name="teamMemberSelectorField">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <div className={style.flex}>
                                <TeamMemberSelector field={field} form={form} {...props} />
                            </div>
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: false,
    },
    {
        name: 'evalCycleSelectorField',
        label: 'Eval Cycle Selector',
        component: props => {
            return (
                <Field name="evalCycleSelectorField">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <div className={style.flex}>
                                <EvalCycleSelector field={field} form={form} {...props} />
                                <InfoTooltip text="Select evaluation cycles for inclusion in this report" />
                            </div>
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: false,
    },
    {
        name: 'filterHack',
        label: 'Enable Apply Filters',
        component: props => {
            return (
                <Field name="filterHack">
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form}>
                            <FilterHack form={form} field={field} />
                        </CccisdFieldWrapper>
                    )}
                </Field>
            );
        },
        isRequired: false,
    },
];
