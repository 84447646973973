import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { hiddenChart } from 'js/vendor/reports/helpers.js';
import { mean, round } from 'lodash';

const RespondentKeyElementsChart = props => {
    const { subscales, data, respondent, groupLevelMap } = props;
    const getData = () => {
        const chartData = [];
        for (const subscale of subscales) {
            const rows = [];
            const barGroup = { subscale: subscale.label };
            for (const group of Object.keys(groupLevelMap)) {
                let row = { group };
                let totalScaleMeans = {};
                let sitesWithScale = {};
                for (const site of data.groups.siteList) {
                    if (groupLevelMap[group].includes(site.group.groupId)) {
                        const key = respondent.short + subscale.short;
                        const avg = site.descendantRoles[respondent.name][key];
                        if (avg) {
                            if (totalScaleMeans[subscale.name]) {
                                totalScaleMeans[subscale.name] += avg;
                            } else {
                                totalScaleMeans[subscale.name] = avg;
                            }
                            if (sitesWithScale[subscale.name]) {
                                sitesWithScale[subscale.name] += 1;
                            } else {
                                sitesWithScale[subscale.name] = 1;
                            }
                        }
                    }
                }
                for (let scalename of Object.keys(totalScaleMeans)) {
                    const groupScaleMean = round(totalScaleMeans[scalename] / sitesWithScale[scalename], 2);
                    row[scalename] = groupScaleMean;
                }
                const rowHasData = Object.keys(row).some(sc => row[sc] > 0);
                if (rowHasData) {
                    rows.push(row);
                }
            }
            barGroup[respondent.label] = round(mean(rows.map(r => r[subscale.name])), 2);
            chartData.push(barGroup);
        }
        return chartData;
    };

    const chartData = getData();

    const isNoData = d => {
        let noData = true;
        for (let i of d) {
            if (noData && i[props.respondent.label] !== 0) {
                noData = false;
            }
        }
        return noData;
    };

    if (isNoData(chartData)) {
        return hiddenChart(`No data for ${props.respondent.label} WFI-EZ Form.`);
    }
    return (
        <div style={{ width: '770px', height: '500px' }}>
            <ResponsiveBar
                indexBy="subscale"
                groupMode="grouped"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                legends={[]}
                keys={[props.respondent.label]}
                theme={{ fontSize: 16 }}
                data={chartData}
                axisBottom={{ tickRotation: 45 }}
                margin={{ top: 15, right: 50, bottom: 200, left: 50 }}
            />
        </div>
    );
};

RespondentKeyElementsChart.propTypes = {
    data: PropTypes.object,
    scales: PropTypes.array,
    groups: PropTypes.array,
    respondent: PropTypes.object,
    groupLevelMap: PropTypes.object,
    subscales: PropTypes.array,
};

export default RespondentKeyElementsChart;
