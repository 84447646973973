import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';
const { tom20Questions } = window.cccisd.appDefs;

const ItemTable = props => {
    const { data, subscale } = props;

    const getData = d => {
        const rows = [];
        const ccmap = {};
        for (const item of subscale.items) {
            for (const cc of d.flows[item].frequency) {
                if (cc.value) {
                    if (ccmap[cc.value]) {
                        ccmap[cc.value][item] = { score: cc.avg, nVal: cc.count };
                    } else {
                        ccmap[cc.value] = { [item]: { score: cc.avg, nVal: cc.count } };
                    }
                }
            }
        }
        for (const cc of Object.keys(ccmap)) {
            const row = {
                cc,
                careCoord: cc.split(', ')[0],
            };
            for (const item of Object.keys(ccmap[cc])) {
                row[`${item}-score`] = ccmap[cc][item].score !== null ? ccmap[cc][item].score * 100 : null;
                row[`${item}-nVal`] = ccmap[cc][item].nVal;
            }
            rows.push(row);
        }
        return rows;
    };

    const getTotal = td => {
        let total = { careCoord: 'Total: ', cc: 'totalRow' };
        for (var key of Object.keys(td[0])) {
            if (key.includes('score')) {
                let allNull = true;
                for (var row of td) {
                    if (row[key] !== null) {
                        allNull = false;
                        break;
                    }
                }
                if (allNull) {
                    total[key] = '';
                } else {
                    let colTotal = 0;
                    let numNull = 0;
                    for (var r of td) {
                        if (r[key] === null) {
                            numNull += 1;
                        } else {
                            colTotal += r[key];
                        }
                    }
                    total[key] = `${round(colTotal / (td.length - numNull), 2)}%`;
                }
            }
        }
        return total;
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        ...subscale.items.map(i => ({
            name: `${i}-score`,
            sort: true,
            label: i.split('').reverse().join(''),
            tooltip: tom20Questions[i.toLowerCase().split('').reverse().join('')].question,
            render: (_, row) =>
                row[`${i}-score`] !== null ? `${round(row[`${i}-score`], 2)}%, N=${row[`${i}-nVal`]}` : 'N/A*',
        })),
    ];

    const tableData = getData(data);
    const totalRow = getTotal(tableData);
    const showNotice = tableData.some(row => cols.some(col => row[col.name] === null));

    return (
        <>
            <Table
                data={tableData}
                rowKey="cc"
                orderBy="careCoord"
                columns={cols}
                footer={totalRow}
                showPerPageOptions={false}
                isCsvDownload
            />
            {showNotice && (
                <p>
                    *All TOMs for this item were scored as &quot;N/A&quot;, thus a summary score can not be calculated
                </p>
            )}
        </>
    );
};

ItemTable.propTypes = {
    data: PropTypes.object,
    subscale: PropTypes.object,
};

export default ItemTable;
