import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { teamMembers } from './vars.js';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';

import attendanceQuery from './graphql/attendance.graphql';
import teamMembershipQuerySite from './graphql/teamMembershipSite.graphql';
import teamMembershipQueryAgency from './graphql/teamMembershipAgency.graphql';
import teamMembershipQueryCollaborator from './graphql/teamMembershipCollaborator.graphql';
import teamMembershipQueryOrganization from './graphql/teamMembershipOrganization.graphql';
import evaluatorTypeQuery from './graphql/evaluatorType.graphql';

import Collapsable from 'js/vendor/reports/components/Collapsable';

import EvaluatorTypeChart from './charts/EvaluatorTypeChart';
import EvaluatorTypeTable from './tables/EvaluatorTypeTable';
import MeetingFormatChart from './charts/MeetingFormatChart';
import MeetingFormatTable from './tables/MeetingFormatTable';
import TypeOfMeetingChart from './charts/TypeOfMeetingChart';
import TypeOfMeetingTable from './tables/TypeOfMeetingTable';
import OtherTypeOfMeetingTable from './tables/OtherTypeOfMeetingTable';
import LengthOfMeetingTable from './tables/LengthOfMeetingTable';
import MeetingAttendanceTable from './tables/MeetingAttendanceTable';
import MeetingAttendanceChart from './charts/MeetingAttendanceChart';
// import MeetingAttendanceGroupsChart from './charts/MeetingAttendanceGroupsChart';

import OtherMeetingAttendanceTable from './tables/OtherMeetingAttendanceTable';

import {
    showTable,
    showChart,
    renderSubtitle,
    renderTitle,
    renderDesc,
    getGroupLevelMap,
} from 'js/vendor/reports/helpers.js';

const queryMap = {
    site: teamMembershipQuerySite,
    agency: teamMembershipQueryAgency,
    collaborator: teamMembershipQueryCollaborator,
    organization: teamMembershipQueryOrganization,
};

function TeamMemberAttendance(props) {
    const [attendance, setAttendance] = useState(null);
    const [teamMembership, setTeamMembership] = useState(null);
    const [evaluatorType, setEvaluatorType] = useState(null);

    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    const groupLevel = props.groupLevel || 'group';
    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            setGroupLevelMap(
                getGroupLevelMap(props.groups, groupLevel, props.filters.siteSelectorField)
            );
            getData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getData = async () => {
        const queries = [
            {
                query: {
                    query: attendanceQuery,
                    variables: {
                        deploymentIds: props.filters.evalCycleSelectorField || [],
                        pawnIds: props.youthPawnIds || [],
                        teamMembers,
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
                setter: setAttendance,
            },
            {
                query: {
                    query: queryMap[groupLevel],
                    variables: {
                        deploymentIds: props.filters.evalCycleSelectorField || [],
                        pawnIds: props.youthPawnIds || [],
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
                setter: setTeamMembership,
            },
            {
                query: {
                    query: evaluatorTypeQuery,
                    variables: {
                        deploymentIds: props.filters.evalCycleSelectorField || [],
                        pawnIds: props.youthPawnIds || [],
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
                setter: setEvaluatorType,
            },
        ];
        await Promise.all(
            queries.map(query =>
                taskMasterClient.query({ ...query.query, cancelTag: 'report' }).then(response => {
                    query.setter(response.data);
                })
            )
        );

        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };

    return (
        <>
            {renderDesc(
                'This section displays basic information about the observerd Child and Family Team Meetings.'
            )}
            {renderSubtitle('Evaluator Type')}
            {showChart(<EvaluatorTypeChart data={evaluatorType} />, loading)}
            {showTable(
                <EvaluatorTypeTable
                    groupLevelMap={groupLevelMap}
                    groupLevel={groupLevel}
                    data={evaluatorType}
                />,
                loading
            )}
            {renderSubtitle('Meeting Format')}
            {showChart(<MeetingFormatChart data={attendance} />, loading)}
            {showTable(
                <MeetingFormatTable
                    groupLevelMap={groupLevelMap}
                    groupLevel={groupLevel}
                    data={attendance}
                />,
                loading
            )}
            {renderSubtitle('Type of Meeting')}
            {showChart(<TypeOfMeetingChart data={attendance} />, loading)}
            {showTable(
                <TypeOfMeetingTable
                    groupLevelMap={groupLevelMap}
                    groupLevel={groupLevel}
                    data={attendance}
                />,
                loading
            )}
            {showTable(
                <Collapsable
                    title="Show Other Responses"
                    collapsed
                    content={<OtherTypeOfMeetingTable data={attendance} />}
                />,
                loading
            )}
            {renderSubtitle('Length of Meeting in Minutes')}
            {showTable(
                <LengthOfMeetingTable
                    groupLevelMap={groupLevelMap}
                    groupLevel={groupLevel}
                    data={teamMembership}
                />,
                loading
            )}
            {renderTitle('Meeting Attendance')}
            {renderDesc(
                'This section displays information on who was part of the Wraparound teams for the completed TOM 2.0s. The tables below show both the total number of Wraparound team members by type and the number that were actually present at the observed meeting.'
            )}
            {showChart(<MeetingAttendanceChart data={attendance} />, loading)}

            {/* {showChart(
                <MeetingAttendanceGroupsChart
                    data={attendance}
                    groupLevel={groupLevel}
                    groupLevelMap={groupLevelMap}
                />,
                loading
            )} */}

            {showTable(<MeetingAttendanceTable data={attendance} />, loading)}
            {showTable(
                <Collapsable
                    title={`Other "Meeting Attendance" Responses`}
                    collapsed
                    content={<OtherMeetingAttendanceTable data={attendance} />}
                />,
                loading
            )}
        </>
    );
}

TeamMemberAttendance.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(TeamMemberAttendance);
