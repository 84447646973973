import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { taskMasterClient } from 'cccisd-apollo';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import query from './query.graphql';
import { getGroupLevelMap } from 'js/vendor/reports/helpers.js';
import { round } from 'lodash';
import { setWidgetLoaded } from 'js/reducers/report.js';

const MonthsInWraparound = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    const groupLevel = props.groupLevel || 'group';
    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            setGroupLevelMap(getGroupLevelMap(props.groups, groupLevel, props.filters.siteSelectorField));
            getData();
        }
    }, [
        props.filters.siteSelectorField,
        props.filters.evalCycleSelectorField,
        props.filters.teamMemberSelectorField,
        props.groups.length,
        props.filters.dateSelectorTo,
    ]);

    const getData = async () => {
        const response = await taskMasterClient.query({
            query,
            variables: {
                pawnIds: props.youthPawnIds,
                siteIds: props.filters.siteSelectorField || [],
                deploymentIds: props.filters.evalCycleSelectorField || [],
                teamMemberTypes: props.filters.teamMemberSelectorField || [],
                dateTo: props.filters.dateSelectorTo || null,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        setData(response.data);
        setLoading(false);
        props.setWidgetLoaded(props.table.name + 'Table');
    };

    const formatData = d => {
        let tableRows = [];
        let totalLow = Infinity;
        let totalHigh = 0;
        let totalMean = 0;
        let numSitesWithMean = 0;
        for (var g of Object.keys(groupLevelMap)) {
            let groupTotalMean = 0;
            let numSitesWithData = 0;
            let groupTotalSD = 0;
            let rangeLow = Infinity;
            let rangeHigh = 0;
            let row = { label: g };
            for (var siteId of groupLevelMap[g]) {
                const site = d.groups.siteList.find(s => s.group.groupId === siteId);
                if (site && site.descendantRoles.youthSummary.avg) {
                    groupTotalMean += site.descendantRoles.youthSummary.avg;
                    groupTotalSD += site.descendantRoles.youthSummary.stddev;
                    let { min, max } = site.descendantRoles.youthSummary;
                    min = parseInt(min, 10);
                    max = parseInt(max, 10);
                    numSitesWithData += 1;
                    if (min < rangeLow) {
                        rangeLow = min;
                    }
                    if (max > rangeHigh) {
                        rangeHigh = max;
                    }
                    if (min < totalLow) {
                        totalLow = min;
                    }
                    if (max > totalHigh) {
                        totalHigh = max;
                    }
                }
            }
            numSitesWithMean += 1;

            row.mean = `${round(groupTotalMean / numSitesWithData || 0, 2)} (${round(
                groupTotalSD / numSitesWithData || 0,
                2
            )})`;
            row.range = `${rangeLow === Infinity ? 0 : Math.floor(rangeLow)} - ${Math.floor(rangeHigh)}`;
            tableRows.push(row);
            totalMean += round(groupTotalMean / numSitesWithData || 0, 2);
        }
        tableRows.push({
            label: 'Total:',
            mean: round(totalMean / numSitesWithMean, 2),
            range: `${totalLow} - ${totalHigh}`,
        });
        return tableRows;
    };

    const cols = [
        {
            name: 'label',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        {
            name: 'mean',
            label: `Mean (SD) Months in Wraparound`,
            tooltip:
                'Months in Wraparound is calculated using the Enrollment Start Date and Discharge Date. If a youth does not have a Discharge Date within the specified date range, the date range End Date is used.',
            sort: true,
            filter: true,
        },
        {
            name: 'range',
            label: `Range: Months in Wraparound`,
            tooltip:
                'Months in Wraparound is calculated using the Enrollment Start Date and Discharge Date. If a youth does not have a Discharge Date within the specified date range, the date range End Date is used.',
            sort: true,
            filter: true,
        },
    ];

    if (loading) {
        return <Skeleton count={15} />;
    }

    const tableData = formatData(data);
    const footer = tableData.pop();

    return (
        <>
            <div>
                <Table
                    isCsvDownload
                    columns={cols}
                    data={tableData}
                    rowKey="label"
                    orderBy="label"
                    perPage={5000}
                    perPageOptions={[250, 500, 1000, 5000]}
                    csvFilename="months-in-wraparound"
                    footer={footer}
                />
            </div>
        </>
    );
};

MonthsInWraparound.propTypes = {
    filters: PropTypes.object,
    table: PropTypes.object,
    settings: PropTypes.object,
    query: PropTypes.object,
    hideTotalCol: PropTypes.bool,
    groupLevel: PropTypes.string,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    setWidgetLoaded: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(MonthsInWraparound);
