import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { renderDescription } from 'js/vendor/reports/helpers.js';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';

function RespondentSummarySection(props) {
    const { respondentSelectorField } = props.filters;

    useEffect(() => {
        if (props.groups.length > 0) {
            getData();
            props.setWidgetLoaded(props.section.name + 'Section');
        }
    }, [
        props.filters.evalCycleSelectorField,
        props.filters.siteSelectorField,
        props.youthPawnIds,
        props.filters.respondentSelectorField,
    ]);

    const getData = () => {
        const rows = [];
        if (props.ccoYouthMap) {
            let i = 0;
            for (const cco of Object.keys(props.ccoYouthMap)) {
                if (props.filters.careCoordSelectorField.some(cc => cc.startsWith(cco))) {
                    rows.push({
                        cc: `${cco}-${i}`,
                        careCoord: cco,
                        caregiver: props.ccoYouthMap[cco].filter(p => p.assignmentId === 3).length,
                        youth: props.ccoYouthMap[cco].filter(p => p.assignmentId === 5).length,
                    });
                }
                i += 1;
            }
        }
        return rows;
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
    ];

    if (respondentSelectorField.includes('3'))
        cols.push({
            name: 'caregiver',
            label: 'Caregiver',
            tooltip: 'Refers to the number of youth included in the report with 1 or more WFI-EZ Caregiver forms.',
            sort: true,
            filter: true,
        });

    if (respondentSelectorField.includes('5'))
        cols.push({
            name: 'youth',
            label: 'Youth',
            tooltip: 'Refers to the number of youth included in the report with 1 or more WFI-EZ Youth forms.',
            sort: true,
            filter: true,
        });

    const tableData = getData();
    const totalRow = {
        careCoord: 'Total: ',
        youth: tableData.reduce((a, c) => a + c.youth, 0),
        caregiver: tableData.reduce((a, c) => a + c.caregiver, 0),
    };

    return (
        <div>
            {renderDescription(
                'This table shows the number of valid WFI-EZs collected for each care coordinator in the sample. This table will always display the total number of WFI-EZs collected for the Youth and/or Caregiver forms during the selected date range.'
            )}
            <Table
                data={tableData}
                rowKey="cc"
                orderBy="careCoord"
                columns={cols}
                footer={totalRow}
                perPage={5000}
                perPageOptions={[25, 50, 100, 500]}
                isCsvDownload
                csvFilename="respondent-summary"
            />
            {renderDescription(
                "Note that the aggregated data presented in this report reflect the “mean of means,” or average of each care coordinator's average score, rather than the mean for all individual records across care coordinators. This is done so that a single care coordinator with many records does not overly influence the presentation of overall fidelity."
            )}
        </div>
    );
}

RespondentSummarySection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
    ccoYouthMap: PropTypes.object,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
    ccoYouthMap: state.app.report.ccoYouthMap,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(RespondentSummarySection);
