import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const OverallScoreElementsTable = props => {
    const { groupLevel, groupLevelMap, data, subscales } = props;

    const getData = d => {
        const rows = [];
        const numItems = getNumItems();
        const numKeyItems = getNumKeyItems();
        for (var group of Object.keys(groupLevelMap)) {
            let groupOverall = null;
            let groupKeyElem = null;
            let numSitesInGroup = 0;
            for (var site of d.groups.siteList) {
                if (groupLevelMap[group].includes(site.group.groupId)) {
                    numSitesInGroup += 1;
                    for (var subscale of subscales) {
                        for (var item of subscale.items) {
                            const score = site.descendantRoles.youthSummary[item];
                            if (score !== null) {
                                groupOverall += score;
                                if (subscale.keyElement) {
                                    groupKeyElem += score;
                                }
                            }
                        }
                    }
                }
            }

            if (numSitesInGroup > 0) {
                rows.push({
                    group,
                    overall: groupOverall !== null ? groupOverall / (numItems * numSitesInGroup) : null,
                    keyElements: groupKeyElem !== null ? groupKeyElem / (numKeyItems * numSitesInGroup) : null,
                });
            }
        }
        return rows;
    };

    const getTotal = td => {
        let totalScore = 0;
        let totalKeyElements = 0;
        const nullScores = {};
        for (let i = 0; i < td.length; i++) {
            const row = td[i];
            if (row.overall === null) {
                if (!nullScores.overall) {
                    nullScores.overall = [];
                }
                nullScores.overall.push(i);
            } else {
                totalScore += row.overall;
            }
            if (row.keyElements === null) {
                if (!nullScores.keyElements) {
                    nullScores.keyElements = [];
                }
                nullScores.keyElements.push(i);
            } else {
                totalKeyElements += row.keyElements;
            }
        }
        const total = { group: 'Total:' };
        const numNullOverall = nullScores.overall ? nullScores.overall.length : 0;
        const numNullKeyElm = nullScores.keyElements ? nullScores.keyElements : 0;

        if (numNullOverall === td.length) {
            total.overall = '';
        } else {
            total.overall = `${round((totalScore / (td.length - numNullOverall)) * 100, 2)}%`;
        }
        if (numNullKeyElm === td.length) {
            total.keyElements = '';
        } else {
            total.keyElements = `${round((totalKeyElements / (td.length - numNullKeyElm)) * 100, 2)}%`;
        }
        return total;
    };

    const getNumItems = () => {
        let total = 0;
        for (var sub of subscales) {
            total += sub.items.length;
        }
        return total;
    };

    const getNumKeyItems = () => {
        let total = 0;
        for (var sub of subscales) {
            if (sub.keyElement) {
                total += sub.items.length;
            }
        }
        return total;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        {
            name: 'overall',
            label: 'Overall Score',
            render: (value, row) => (value !== null ? `${round(value * 100, 2)}%` : 'N/A'),
        },
        {
            name: 'keyElements',
            label: 'Key Elements Score',
            render: (value, row) => (value !== null ? `${round(value * 100, 2)}%` : 'N/A'),
        },
    ];

    const tableData = getData(data);
    const totalRow = getTotal(tableData);

    let showNotice = false;
    for (const row of tableData) {
        if (!showNotice) {
            if (Object.keys(row).some(key => row[key] === null)) {
                showNotice = true;
            }
        }
    }

    return (
        <>
            <Table
                data={tableData}
                rowKey="group"
                columns={cols}
                footer={totalRow}
                showPerPageOptions={false}
                isCsvDownload
            />
            {showNotice && (
                <p>
                    *All TOMs for this item were scored as &quot;N/A&quot;, thus a summary score can not be calculated
                </p>
            )}
        </>
    );
};

OverallScoreElementsTable.propTypes = {
    data: PropTypes.object,
    groupLevelMap: PropTypes.object,
    groupLevel: PropTypes.string,
    subscales: PropTypes.array,
};

export default OverallScoreElementsTable;
