export const teamMembers = [
    'CC_Team',
    'CC_Present',
    'CC_Notes',
    'Y_Team',
    'Y_Present',
    'Y_Notes',
    'Prnt_Team',
    'Prnt_Present',
    'Prnt_Notes',
    'FPrnt_Team',
    'FPrnt_Present',
    'FPrnt_Notes',
    'CG_Team',
    'CG_Present',
    'CG_Notes',
    'Sib_Team',
    'Sib_Present',
    'Sib_Notes',
    'Sch_Team',
    'Sch_Present',
    'Sch_Notes',
    'JJ_Team',
    'JJ_Present',
    'JJ_Notes',
    'SS_Team',
    'SS_Present',
    'SS_Notes',
    'CASA_Team',
    'CASA_Present',
    'CASA_Notes',
    'Attrny_Team',
    'Attrny_Present',
    'Attrny_Notes',
    'MHP_Team',
    'MHP_Present',
    'MHP_Notes',
    'MHA_Team',
    'MHA_Present',
    'MHA_Notes',
    'Med_Team',
    'Med_Present',
    'Med_Notes',
    'FS_Team',
    'FS_Present',
    'FS_Notes',
    'YS_Team',
    'YS_Present',
    'YS_Notes',
    'OldSib_Team',
    'OldSib_Present',
    'OldSib_Notes',
    'ExtFam_Team',
    'ExtFam_Present',
    'ExtFam_Notes',
    'FrndP_Team',
    'FrndP_Present',
    'FrndP_Notes',
    'FrndY_Team',
    'FrndY_Present',
    'FrndY_Notes',
    'Oth_Team',
    'Oth_Present',
    'Oth_Notes',
];
