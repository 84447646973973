import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round, startCase, mean } from 'lodash';
import { hiddenChart } from 'js/vendor/reports/helpers.js';

const AreasToImproveTable = props => {
    const { wfiQuestions } = window.cccisd.appDefs;
    const { respondent, data, groupLevel, groupLevelMap, scales } = props;

    const getData = () => {
        let rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            var itemTotals = {};
            var groupItemNs = {};
            for (var siteId of groupLevelMap[group]) {
                const site = data.groups.siteList.find(s => siteId === s.group.groupId);

                for (let subscale of scales) {
                    if (subscale.items) {
                        for (let item of subscale.items) {
                            const siteN = site.descendantRoles[respondent.name]['N' + item];
                            if (siteN) {
                                if (groupItemNs[item]) {
                                    groupItemNs[item] += siteN;
                                } else {
                                    groupItemNs[item] = siteN;
                                }
                            }
                            const siteItemMean = site.descendantRoles[respondent.name][item];
                            if (siteItemMean) {
                                if (itemTotals[item]) {
                                    itemTotals[item] += siteItemMean;
                                } else {
                                    itemTotals[item] = siteItemMean;
                                }
                            }
                        }
                    }
                }
            }
            for (let subscale of scales) {
                if (subscale.items) {
                    for (let item of subscale.items) {
                        const countYes = itemTotals[item] || 0;
                        const avg = round(countYes / groupItemNs[item], 2) || 0;
                        const { question, WS_Mean, WS_SD } =
                            wfiQuestions[`${startCase(respondent.name).replace(' ', '')}_${item}`];
                        const row = {
                            group,
                            item,
                            mean: avg,
                            groupKey: group + item,
                            itemDesc: question,
                            wrapMean: parseFloat(WS_Mean, 10),
                            wrapSD: parseFloat(WS_SD, 10),
                        };
                        if (groupItemNs[item] > 0) {
                            rows.push(row);
                        }
                    }
                }
            }
        }
        const weakRows = rows.filter(row => row.wrapMean - row.mean > 0.4);
        return weakRows;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        {
            name: 'item',
            label: 'Item Number',
        },
        {
            name: 'itemDesc',
            label: 'Item Description',
        },
        {
            name: 'mean',
            label: `${respondent.label} Mean`,
        },
        {
            name: 'wrapSD',
            label: `${respondent.label} Wrapstat Mean (SD)`,
            render: (value, row) => `${row.wrapMean} (${value})`,
        },
    ];

    const getTotal = d => {
        const tr = { group: 'Total:' };
        for (const row of d) {
            for (const q of Object.keys(row).filter(r => ['mean', 'wrapMean', 'wrapSD'].includes(r))) {
                if (tr[q]) {
                    tr[q].push(row[q]);
                } else {
                    tr[q] = [row[q]];
                }
            }
        }
        for (const k of Object.keys(tr).filter(r => ['mean', 'wrapMean', 'wrapSD'].includes(r))) {
            if (k === 'wrapSD') {
                tr[k] = `${round(mean(tr.wrapMean), 2)} (${round(mean(tr[k]), 2)})`;
            } else {
                tr[k] = `${round(mean(tr[k]), 2)}`;
            }
        }
        return tr;
    };

    const tableData = getData();
    const totalRow = getTotal(tableData);

    if (tableData.length > 0) {
        return (
            <Table
                data={tableData}
                rowKey="groupKey"
                columns={cols}
                isCsvDownload
                footer={totalRow}
                perPage={5000}
                perPageOptions={[250, 500, 1000, 5000]}
                csvFilename="section-B-areas-to-improve"
            />
        );
    }
    return hiddenChart('No fidelity items scored 0.4 or below the Wrapstat Mean.');
};

AreasToImproveTable.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.object,
    scales: PropTypes.array,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default AreasToImproveTable;
