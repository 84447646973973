import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import Component from './component.js';
import { loadGroups } from 'js/reducers/sites.js';
import { client } from 'cccisd-apollo';
import cycleQuery from '../graphql/allCycles.graphql';
const previousSitesKey = `${window.cccisd.fortress.user.acting.respondent_hash}-sites`;
import { setCycles } from 'js/reducers/report.js';
import Loader from 'cccisd-loader';
import moment from 'moment';
import widgets from '../widgets.js';
import { respondents } from 'js/vendor/reports/filters/RespondentSelector';
import { teamtypes } from 'js/vendor/reports/filters/TeamMemberSelector';

const toDate = new Date();

const Player = props => {
    const groups = useSelector(state => state.app.sites.groups);
    const [sites, setSites] = useState([]);
    const [cycles, setLocalCycles] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const prevSites = localStorage.getItem(previousSitesKey)
        ? localStorage
              .getItem(previousSitesKey)
              .split(',')
              .map(s => parseInt(s, 10))
        : false;

    useEffect(() => {
        if (groups.length === 0) {
            (async () => {
                await dispatch(loadGroups());
                getInitialSites(groups);
            })();
        }
    }, []);

    useEffect(() => {
        getInitialSites(groups);
    }, []);

    useEffect(() => {
        if (sites) {
            getAllCycles();
        }
    }, [sites]);

    const getInitialSites = g => {
        var siteIds = [];
        const isUber = window.cccisd.fortress.user.acting.data_type === 'uberadmin';
        for (var group of g) {
            if (group.group.groupType === 'site') {
                siteIds.push(group.group.groupId);
            }
        }
        if (!prevSites) {
            localStorage.setItem(previousSitesKey, siteIds);
        }
        if (isUber) {
            setSites(prevSites || siteIds);
        } else {
            setSites(siteIds);
        }
    };

    const getAllCycles = async () => {
        const response = await client.query({
            query: cycleQuery,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });

        const options = response.data.flows.deploymentList
            .filter(d => {
                if (!sites.includes(parseInt(d.groupId, 10))) {
                    return false;
                }
                if (![2, 3, 4, 5].includes(d.assignment.assignmentId)) {
                    return false;
                }
                if (d.openedDaysAgo >= 365) {
                    return false;
                }
                if (d.openedDaysAgo <= 0) {
                    return false;
                }
                return true;
            })
            .map(d => ({
                value: d.deploymentId,
                label: d.label,
            }));
        props.setCycles(response.data.flows.deploymentList);
        setLocalCycles(options);
        setLoading(false);
    };

    if (loading) {
        return <Loader loading />;
    }

    const initialFilterValues = {
        showHideView: widgets.filter(w => w.handle !== 'summary').map(w => w.label),
        respondentSelectorField: respondents.map(r => r.value),
        teamMemberSelectorField: teamtypes.map(t => t.value),
        dateSelectorFrom: moment(toDate).subtract(12, 'months').format('YYYY-MM-DD HH:mm'),
        dateSelectorTo: moment(toDate).format('YYYY-MM-DD HH:mm'),
        siteSelectorField: sites,
        evalCycleSelectorField: cycles.map(c => c.value),
        filterHack: true,
    };

    return <Component initialFilterValues={initialFilterValues} groups={groups} {...props} />;
};

const mapStateToProps = state => ({
    sites: state.app.report.sites,
    respondents: state.app.report.respondents,
    teamMemberTypes: state.app.report.teamMemberTypes,
    dateFrom: state.app.report.dateFrom,
    dateTo: state.app.report.dateTo,
    cycles: state.app.report.cycles,
});

export default connect(mapStateToProps, { setCycles })(Player);
