import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const ExitReasonOtherResponseTable = props => {
    const { data } = props;

    const getData = d => {
        const rows = [];
        if (d.roles.youthSummary?.frequency) {
            for (var cc of d.roles.youthSummary.frequency) {
                for (var res of cc.frequency) {
                    if (res.value) {
                        rows.push({
                            response: res.value,
                            count: res.valueCount,
                        });
                    }
                }
            }
        }
        return rows;
    };

    const getTotalRow = td => {
        let total = 0;
        for (var row of td) {
            total += row.count;
        }
        return {
            response: 'Total:',
            count: total,
        };
    };

    const cols = [
        {
            name: 'response',
            label: 'Response',
        },
        {
            name: 'count',
            label: 'Count',
        },
    ];

    const tableData = getData(data);
    const totalRow = getTotalRow(tableData);

    return (
        <Table
            data={tableData}
            rowKey="response"
            columns={cols}
            footer={totalRow}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="exit-reasons-other"
        />
    );
};

ExitReasonOtherResponseTable.propTypes = {
    data: PropTypes.object,
};

export default ExitReasonOtherResponseTable;
