import React from 'react';
import Table from 'cccisd-table';
import PropTypes from 'prop-types';
import Collapsable from 'js/vendor/reports/components/Collapsable';

const CaregiverRelationTable = props => {
    const { data } = props;
    const getData = () => {
        // const relations = window.cccisd.appDefs.pawn.fields.find(f => f.handle === 'relationshipToYouth').values
        const rows = [];
        const relationMap = {};
        if (data.roles.caregiver.frequency) {
            for (const cc of data.roles.caregiver.frequency) {
                if (cc.frequency) {
                    for (const relation of cc.frequency) {
                        if (relation.value) {
                            if (relationMap[relation.value]) {
                                relationMap[relation.value] += relation.valueCount;
                            } else {
                                relationMap[relation.value] = relation.valueCount;
                            }
                        }
                    }
                }
            }
        }
        for (const val of Object.keys(relationMap)) {
            rows.push({ relation: val, count: relationMap[val] });
        }
        return rows;
    };

    const getTotal = td => {
        let total = 0;
        for (const row of td) {
            total += row.count;
        }
        return { relation: 'Total:', count: total };
    };

    const getOtherResponses = () => {
        const rows = [];
        if (data.roles.caregiver_other.frequency) {
            for (const r of data.roles.caregiver_other.frequency) {
                if (r.value) {
                    rows.push({ value: r.value, count: r.valueCount });
                }
            }
        }
        return rows;
    };

    const cols = [
        {
            name: 'relation',
            label: 'Relationship Type',
        },
        {
            name: 'count',
            label: 'Count',
            sort: true,
            filter: true,
        },
    ];

    const otherCols = [
        {
            name: 'value',
            label: 'Response',
            sort: true,
            filter: true,
        },
        {
            name: 'count',
            label: 'Count',
            sort: true,
            filter: true,
        },
    ];

    const tableData = getData();
    const totalRow = getTotal(tableData);
    const otherData = getOtherResponses();

    return (
        <>
            <Table
                isCsvDownload
                columns={cols}
                data={tableData}
                rowKey="relation"
                orderBy="count"
                isAscentOrder={false}
                showPerPageOptions={false}
                footer={totalRow}
            />
            <Collapsable
                collapsed
                title='Caregiver "Other" Responses'
                content={
                    <Table
                        isCsvDownload
                        columns={otherCols}
                        data={otherData}
                        rowKey="value"
                        orderBy="value"
                        showPerPageOptions={false}
                    />
                }
            />
        </>
    );
};

CaregiverRelationTable.propTypes = {
    data: PropTypes.object,
};

export default CaregiverRelationTable;
