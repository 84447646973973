import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';
import { subscales } from './subscales.js';
import fidelityQuery from './graphql/fidelity.graphql';

// charts
// import ItemChart from './charts/ItemChart';
import SubscaleItemChart from './charts/SubscaleItemChart';
import SubscaleChart from './charts/SubscaleChart';
import OverallKeyElementChart from './charts/OverallKeyElementChart';

// tables
import ItemTable from './tables/ItemTable';
import OverallScoreElementsTable from './tables/OverallScoreElementsTable';
import SubscaleTable from './tables/SubscaleTable';
import QuestionTable from './tables/QuestionTable';
import ItemQuestionMeans from './tables/ItemQuestionMeans';

import {
    showTable,
    showChart,
    renderTitle,
    renderSubtitle,
    renderDesc,
    renderDescription,
    getGroupLevelMap,
} from 'js/vendor/reports/helpers.js';

function FidelitySection(props) {
    const [fidelity, setFidelity] = useState(null);

    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    const groupLevel = props.groupLevel || 'group';
    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            setGroupLevelMap(
                getGroupLevelMap(props.groups, groupLevel, props.filters.siteSelectorField)
            );
            getData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getData = async () => {
        const response = await taskMasterClient.query({
            query: fidelityQuery,
            variables: {
                deploymentIds: props.filters.evalCycleSelectorField || [],
                pawnIds: props.youthPawnIds || [],
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });

        setFidelity(response.data);
        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };

    return (
        <>
            {renderDesc(
                'This section displays information on the TOM 2.0 detailing what the observers did and did not see at the Child and Family Team Meetings. These items can be further subdivided into seven subscale scores.'
            )}
            {renderTitle('Key Elements and Overall Fidelity')}
            {renderDescription(
                'The Overall TOM 2.0 Score averages all seven subscales included in the TOM 2.0 form. The Key Elements Score averages five of the subscales: Effective Teamwork, Driven by Strengths and Families, Based on Priority Needs, Use of Natural and Community Supports, and Outcomes-Based Process.'
            )}
            {showChart(
                <OverallKeyElementChart
                    data={fidelity}
                    subscales={subscales}
                    groupLevelMap={groupLevelMap}
                />,
                loading
            )}
            {showTable(
                <OverallScoreElementsTable
                    data={fidelity}
                    groupLevelMap={groupLevelMap}
                    groupLevel={groupLevel}
                    subscales={subscales}
                />,
                loading
            )}
            {renderTitle('Subscale and Item Responses')}
            {renderDesc(
                "This section displays information for each subscale and item on the TOM 2.0. The item response shows the percentage of observers who responded 'Yes' to that question."
            )}
            {renderSubtitle('Overview of Subscales Scores')}
            {showChart(
                <SubscaleChart
                    data={fidelity}
                    subscales={subscales}
                    groupLevelMap={groupLevelMap}
                />,
                loading
            )}
            {showTable(
                <SubscaleTable
                    data={fidelity}
                    groupLevel={groupLevel}
                    groupLevelMap={groupLevelMap}
                    subscales={subscales.slice(0, 4)}
                />,
                loading
            )}
            {showTable(
                <SubscaleTable
                    data={fidelity}
                    groupLevel={groupLevel}
                    groupLevelMap={groupLevelMap}
                    subscales={subscales.slice(4, subscales.length)}
                />,
                loading
            )}
            {subscales.map(subscale => (
                <div key={subscale.label}>
                    {renderSubtitle(subscale.label)}
                    {renderDescription(
                        `The ${subscale.label} subscale consists of items ${subscale.items[0]
                            .split('')
                            .reverse()
                            .join('')} - ${subscale.items[subscale.items.length - 1]
                            .split('')
                            .reverse()
                            .join(
                                ''
                            )}. Items have two response options, Yes (1) or No (0). Some items have a third option, N/A, which renders the item invalid.`
                    )}
                    {showChart(
                        <SubscaleItemChart
                            data={fidelity}
                            subscale={subscale}
                            groupLevelMap={groupLevelMap}
                        />,
                        loading
                    )}

                    {showTable(
                        <ItemTable
                            data={fidelity}
                            groupLevel={groupLevel}
                            groupLevelMap={groupLevelMap}
                            subscale={subscale}
                        />,
                        loading
                    )}
                    {showTable(<ItemQuestionMeans items={subscale.items} />, loading)}
                    {renderDescription(
                        'The table below presents the survey question for each item.'
                    )}
                    {showTable(<QuestionTable items={subscale.items} />)}
                </div>
            ))}
        </>
    );
}

FidelitySection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(FidelitySection);
