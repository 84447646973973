import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round, mean } from 'lodash';

const SubscaleTable = props => {
    const { data, subscales } = props;

    const getData = d => {
        const rows = [];
        const ccmap = {};
        for (const subscale of subscales) {
            for (const item of subscale.items) {
                for (const cc of d.flows[item].frequency) {
                    if (cc.value) {
                        if (ccmap[cc.value]) {
                            if (ccmap[cc.value][subscale.name]) {
                                ccmap[cc.value][subscale.name].push(cc.avg);
                            } else {
                                ccmap[cc.value][subscale.name] = [cc.avg];
                            }
                        } else {
                            ccmap[cc.value] = { [subscale.name]: [cc.avg] };
                        }
                    }
                }
            }
        }
        for (const cc of Object.keys(ccmap)) {
            const row = {
                cc,
                careCoord: cc.split(', ')[0],
            };
            for (const sub of Object.keys(ccmap[cc])) {
                let allNull = true;
                for (const i of ccmap[cc][sub]) {
                    if (i !== null) {
                        allNull = false;
                        break;
                    }
                }
                row[sub] = allNull ? null : mean(ccmap[cc][sub]) * 100;
            }
            rows.push(row);
        }

        return rows;
    };

    const getTotal = td => {
        let total = { careCoord: 'Total: ', cc: 'totalRow' };
        for (const key of Object.keys(td[0])) {
            if (!['careCoord', 'cc'].includes(key)) {
                const keyTotal = [];
                let allNull = true;
                for (const row of td) {
                    if (row[key] !== null) {
                        allNull = false;
                        keyTotal.push(row[key]);
                    }
                }
                if (allNull) {
                    total[key] = '';
                } else {
                    total[key] = `${round(mean(keyTotal), 2)}%`;
                }
            }
        }
        return total;
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        ...subscales.map(s => ({
            name: s.name,
            label: s.label,
            sort: true,
            render: (value, row) => (value !== null ? `${round(value, 2)}%` : 'N/A*'),
        })),
    ];

    const tableData = getData(data);
    const totalRow = getTotal(tableData);

    let showNotice = false;
    for (const row of tableData) {
        if (!showNotice) {
            if (Object.keys(row).some(key => row[key] === null)) {
                showNotice = true;
            }
        }
    }

    return (
        <>
            <Table
                data={tableData}
                rowKey="cc"
                orderBy="careCoord"
                columns={cols}
                footer={totalRow}
                showPerPageOptions={false}
                isCsvDownload
            />
            {showNotice && (
                <p>
                    *All TOMs for this item were scored as &quot;N/A&quot;, thus a summary score can not be calculated
                </p>
            )}
        </>
    );
};

SubscaleTable.propTypes = {
    data: PropTypes.object,
    subscales: PropTypes.array,
};

export default SubscaleTable;
