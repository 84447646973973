import React from 'react';
import PropTypes from 'prop-types';
import subscales from '../subscales.js';
import { mean, round } from 'lodash';
import Table from 'cccisd-table';

const OverallKeyElementsTable = props => {
    const { data, respondent } = props;

    const getData = () => {
        const rows = [];
        if (data.flows[respondent.label].frequency)
            for (const cc of data.flows[respondent.label].frequency) {
                if (cc.value) {
                    const row = { cc: cc.value, careCoord: cc.value.split(', ')[0] };
                    for (const sub of subscales) {
                        row[sub.label] = cc[`${respondent.key}${sub.key}`];
                    }
                    rows.push(row);
                }
            }
        return rows;
    };

    const getTotal = d => {
        const total = {};
        for (const row of d) {
            for (const sub of subscales) {
                if (total[sub.label]) {
                    total[sub.label].push(row[sub.label]);
                } else {
                    total[sub.label] = [row[sub.label]];
                }
            }
        }
        for (const k of Object.keys(total)) {
            total[k] = `${round(mean(total[k]), 2)}%`;
        }
        total.careCoord = 'Total:';
        return total;
    };

    const cols = [
        { name: 'careCoord', label: 'Care Coordinator' },
        ...subscales.map(s => ({
            name: s.label,
            label: s.label,
            sort: true,
            render: value => (value !== null ? `${value}%` : 'N/A'),
        })),
    ];

    const tableData = getData();
    const totalRow = getTotal(tableData);

    return (
        <Table
            rowKey="cc"
            orderBy="careCoord"
            columns={cols}
            data={tableData}
            footer={totalRow}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="section-b-overall-key-elements"
        />
    );
};

OverallKeyElementsTable.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.object,
};

export default OverallKeyElementsTable;
