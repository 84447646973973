import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round, mean, min, max } from 'lodash';
import { std } from '../../../ReportOverviewSection/helpers.js';

const LengthOfMeetingTable = props => {
    const { data } = props;

    const getData = d => {
        const rows = [];
        let allMeetings = [];
        for (var c of Object.keys(d)) {
            if (d[c].meetingLengths) {
                const { meetingLengths } = d[c];
                allMeetings.push(...meetingLengths);
                rows.push({
                    cc: c,
                    careCoord: c.split(',')[0],
                    ccMin: min(meetingLengths),
                    ccMax: max(meetingLengths),
                    ccMean: mean(meetingLengths),
                    ccStd: std(meetingLengths),
                });
            }
        }
        rows.push({
            cc: 'Total: ',
            careCoord: 'Total: ',
            ccMean: `${round(mean(allMeetings), 2)}`,
            range: `${min(allMeetings)} - ${max(allMeetings)}`,
        });
        return rows;
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        {
            name: 'ccMean',
            label: 'Mean',
            sort: true,
            tooltip: 'Length of meeting is reported in minutes',
            render: (_, row) => `${round(row.ccMean, 2)}`,
        },
        {
            name: 'range',
            label: 'Range',
            render: (_, row) => `${row.ccMin} - ${row.ccMax}`,
        },
    ];

    const tableData = getData(data);
    const totalRow = tableData.pop();

    return (
        <Table
            data={tableData}
            rowKey="cc"
            orderBy="careCoord"
            columns={cols}
            showPerPageOptions={false}
            footer={totalRow}
            isCsvDownload
        />
    );
};

LengthOfMeetingTable.propTypes = {
    data: PropTypes.object,
};

export default LengthOfMeetingTable;
