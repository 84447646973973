import Builder, {
    initialValues as builderInitialValues,
} from 'js/vendor/reports/widgets/TablePicker/Builder';
import Player from 'js/vendor/reports/widgets/TablePicker/Player';

// queries
import dischargeSuccessRates from './graphql/dischargeSuccessRates.graphql';
import monthsInWraparound from './graphql/monthsInWraparound.graphql';
import transgenderIdentity from './graphql/transgenderIdentity.graphql';
import ageAtEnrollment from './graphql/ageAtEnrollment.graphql';
import genderIdentity from './graphql/genderIdentity.graphql';
import sexualOrientation from './graphql/sexualOrientation.graphql';
import race from './graphql/race.graphql';
import ethnicity from './graphql/ethnicity.graphql';
import legalCustodian from './graphql/legalCustodian.graphql';
import exitReason from './graphql/exitReason.graphql';

// components
import OtherGenderIdentity from './custom/tables/OtherGenderIdentity';
import OtherRace from './custom/tables/OtherRace';
import OtherSexualOrientation from './custom/tables/OtherSexualOrientation';
import OtherLegalCustodian from './custom/tables/OtherLegalCustodian';

import OtherExitReason from './custom/tables/OtherExitReason';
import MonthsInWraparound from './custom/tables/MonthsInWraparound';
import FidelitySection from './custom/sections/FidelitySection';
import SampleResponseSummary from './custom/sections/SampleResponseSummary';
import TeamMemberAttendanceSection from './custom/sections/TeamMemberAttendanceSection';
import CommentSection from './custom/sections/CommentSection';

// helpers
import {
    convertAgeAtEnrollment,
    convertDischargeSuccessRate,
    convertMonthsInWraparound,
    convertDemographicsChart,
    convertDemographicsBarChart,
    convertExitReasonBarChart,
    demographicsVars,
    monthsInWraparoundVars,
    pieLegend,
} from 'js/vendor/reports/helpers.js';
import { siteTableFilter, siteTableFilterSummary } from './queryFilters.js';

const respondentSummarySections = [
    {
        name: 'respondentSummary',
        label: 'Respondent Summary',
        tables: [
            {
                name: 'respondentSummary',
                label: 'Respondent Summary',
                tableFilter: siteTableFilterSummary,
            },
        ],
        charts: [],
    },
];

const reportOverviewSections = [
    {
        name: 'ageAtEnrollment',
        label: 'Age at Enrollment',
        tables: [
            {
                name: 'ageAtEnrollment',
                label: 'Age at Enrollment',
                tableFilter: siteTableFilter,
            },
        ],
        charts: [
            {
                name: 'ageAtEnrollment',
                position: 'top',
                type: 'bar',
                query: ageAtEnrollment,
                convertData: convertAgeAtEnrollment,
                variables: demographicsVars,
            },
        ],
    },
    {
        name: 'genderIdentity',
        label: 'Gender Identity',
        tables: [
            {
                name: 'genderIdentity',
                label: 'Gender Identity',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherGenderIdentity',
                label: 'Gender Identity "Other" Responses',
                component: OtherGenderIdentity,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'genderIdentity',
                position: 'top',
                type: 'pie',
                query: genderIdentity,
                convertData: convertDemographicsChart,
                variables: demographicsVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'transgenderIdentity',
        label: 'Transgender Identity',
        tables: [
            {
                name: 'transgenderIdentity',
                label: 'Transgender Identity',
                tableFilter: siteTableFilter,
            },
        ],
        charts: [
            {
                name: 'transgenderIdentity',
                position: 'top',
                type: 'pie',
                query: transgenderIdentity,
                convertData: convertDemographicsChart,
                variables: demographicsVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'sexualOrientation',
        label: 'Sexual Orientation',
        tables: [
            {
                name: 'sexualOrientation',
                label: 'Sexual Orientation',
                tableFilter: siteTableFilter,
            },
            {
                name: 'sexualOrientation2',
                label: 'Sexual Orientation (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherSexualOrientation',
                label: 'Sexual Orientation "Other" Responses',
                component: OtherSexualOrientation,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'sexualOrientation',
                position: 'top',
                type: 'pie',
                query: sexualOrientation,
                convertData: convertDemographicsChart,
                variables: demographicsVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'race',
        label: 'Race',
        tables: [
            {
                name: 'race',
                label: 'Race',
                tableFilter: siteTableFilter,
            },
            {
                name: 'race2',
                label: 'Race (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherRace',
                label: 'Race "Other" Responses',
                component: OtherRace,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'race',
                position: 'top',
                type: 'pie',
                query: race,
                convertData: convertDemographicsChart,
                variables: demographicsVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'ethnicity',
        label: 'Ethnicity',
        tables: [
            {
                name: 'ethnicity',
                label: 'Ethnicity',
                tableFilter: siteTableFilter,
            },
        ],
        charts: [
            {
                name: 'ethnicity',
                position: 'top',
                type: 'pie',
                query: ethnicity,
                convertData: convertDemographicsChart,
                variables: demographicsVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'legalCustodian',
        label: 'Legal Custodian',
        tables: [
            {
                name: 'legalCustodian',
                label: 'Legal Custodian',
                tableFilter: siteTableFilter,
            },
            {
                name: 'legalCustodian2',
                label: 'Legal Custodian (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherLegalCustodian',
                label: 'Legal Custodian "Other" Responses',
                component: OtherLegalCustodian,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'legalCustodian',
                position: 'top',
                type: 'bar',
                query: legalCustodian,
                convertData: convertDemographicsBarChart,
                variables: demographicsVars,
                margin: { top: 50, right: 130, bottom: 100, left: 60 },
                size: { width: '770px', height: '450px' },
                legend: pieLegend,
            },
        ],
    },
];

const enrollmentDischargeSections = [
    {
        name: 'monthsInWraparound',
        label: 'Months in Wraparound',
        tables: [
            {
                name: 'monthsInWraparound',
                label: 'Months in Wraparound',
                component: MonthsInWraparound,
                tableFilter: siteTableFilter,
            },
        ],
        charts: [
            {
                name: 'monthsInWraparound',
                position: 'top',
                type: 'bar',
                query: monthsInWraparound,
                convertData: convertMonthsInWraparound,
                variables: monthsInWraparoundVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'dischargeInformation',
        label: 'Discharge Information',
        tables: [
            {
                name: 'dischargeInformation',
                label: 'Discharge Information',
                tableFilter: siteTableFilter,
            },
        ],
    },
    {
        name: 'dischargeSuccessRates',
        label: 'Discharge Success Rates',
        tables: [],
        charts: [
            {
                label: 'Discharge Success Rate',
                name: 'dischargeSuccessRates',
                position: 'top',
                type: 'pie',
                query: dischargeSuccessRates,
                convertData: convertDischargeSuccessRate,
                variables: demographicsVars,
                altField: true,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'exitReason',
        label: 'Exit Reason',
        tables: [
            { name: 'exitReason', label: 'Exit Reason', tableFilter: siteTableFilter },
            {
                name: 'exitReason2',
                label: 'Exit Reason (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'exitReason3',
                label: 'Exit Reason (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherExitReason',
                label: 'Exit Reason "Other" Responses',
                component: OtherExitReason,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'exitReason',
                position: 'top',
                type: 'bar',
                query: exitReason,
                convertData: convertExitReasonBarChart,
                variables: demographicsVars,
                legend: pieLegend,
                margin: { top: 50, right: 130, bottom: 150, left: 60 },
                size: { width: '770px', height: '450px' },
            },
        ],
    },
];

const fidelitySections = [
    {
        name: 'fidelity',
        label: 'Section B. Fidelity',
        component: FidelitySection,
    },
];

const responseSummarySections = [
    {
        name: 'responseSummary',
        label: 'Sample and Response Summary',
        component: SampleResponseSummary,
    },
];

const teamMemberAttendance = [
    {
        name: 'teamMemberAttendance',
        label: 'Team Member Attendance',
        component: TeamMemberAttendanceSection,
    },
];

const commentSections = [
    {
        name: 'comments',
        label: 'Comments',
        component: CommentSection,
    },
];

export const templateViews = [
    {
        handle: 'summary',
        label: 'Respondent Summary',
        sections: respondentSummarySections,
    },
    {
        handle: 'responseSummary',
        label: 'Sample and Response Summary',
        sections: responseSummarySections,
    },
    {
        handle: 'reportOverview',
        label: 'Report Overview',
        sections: reportOverviewSections,
    },
    {
        handle: 'enrollmentDischarge',
        label: 'Enrollment and Discharge Information',
        sections: enrollmentDischargeSections,
    },
    {
        handle: 'teamMemberAttendance',
        label: 'Child Family and Team Meeting Information',
        sections: teamMemberAttendance,
    },
    {
        handle: 'fidelity',
        label: 'Fidelity',
        sections: fidelitySections,
    },
    {
        handle: 'comments',
        label: 'Comments',
        sections: commentSections,
    },
];

export default templateViews.map(s => ({
    handle: s.handle,
    label: s.label,
    builderInitialValues,
    builder: Builder,
    player: Player,
}));
