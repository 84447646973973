import React from 'react';
import PropTypes from 'prop-types';
import { TableViewer } from 'cccisd-laravel-appdefs';
import TableRender from '../TableRender';
import ChartRender from '../ChartRender';
import style from './style.css';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { connect } from 'react-redux';

const ReportSection = props => {
    const { section, settings, filters, youthPawnIds } = props;

    if (section.renderIf && !section.renderIf(filters)) {
        return null;
    }

    if (section.component) {
        const SectionComponent = section.component;
        return (
            <SectionComponent
                filters={props.filters}
                settings={props.settings}
                section={section}
                groupLevel={props.groupLevel}
            />
        );
    }

    const renderChart = chart => {
        return (
            (
                <ChartRender
                    chart={chart}
                    filters={filters}
                    settings={settings}
                    groupLevel={props.groupLevel}
                />
            ) || <h1>Loading</h1>
        );
    };

    const customTableRender = table => {
        const Component = table.component;
        const CustomTable = (
            <Component
                filters={filters}
                table={table}
                settings={settings}
                groupLevel={props.groupLevel}
            />
        );
        if (table.collapsed) {
            return (
                <Collapsable
                    collapsed
                    title={table.label}
                    content={CustomTable}
                    groupLevel={props.groupLevel}
                />
            );
        }
        return CustomTable;
    };

    const renderTitle = (table, index) => {
        if (table.collapsed) {
            return;
        }
        const hr = <hr className={style.sectionRule} />;

        if (index > 0) {
            return (
                <div className={style.text}>
                    <h5 className={style.sectionSubtitle}>{table.label}</h5>
                    {hr}
                    {settings[table.name + 'Desc'] && (
                        <div
                            className={style.desc}
                            dangerouslySetInnerHTML={{ __html: settings[table.name + 'Desc'] }}
                        />
                    )}
                </div>
            );
        }
        return (
            <div className={style.text}>
                <h4 className={style.sectionTitle}>{table.label}</h4>
                {hr}
                {settings[table.name + 'Desc'] && (
                    <div
                        className={style.desc}
                        dangerouslySetInnerHTML={{ __html: settings[table.name + 'Desc'] }}
                    />
                )}
            </div>
        );
    };

    const renderTable = (table, filter, render) => {
        return table.component ? (
            customTableRender(table)
        ) : (
            <TableViewer
                handle={settings[table.name + 'Table']}
                tableProps={{
                    constantFilter: filter,
                    graphqlVariables,
                    render,
                    perPage: 1000,
                    dataLoadedCallback: () => props.setWidgetLoaded(table.name + 'Table'),
                }}
            />
        );
    };

    const graphqlVariables = {
        fromDate: filters.monthSelectorFrom && filters.monthSelectorFrom,
        toDate: filters.monthSelectorTo && filters.monthSelectorTo,
        siteIds: filters.siteSelectorField,
        deploymentIds: filters.evalCycleSelectorField && filters.evalCycleSelectorField,
        teamMemberTypes: filters.teamMemberSelectorField && filters.teamMemberSelectorField,
        pawnIds: youthPawnIds || [],
    };

    const hasTables = section.tables.length > 0;
    const iterator = hasTables ? section.tables : section.charts;

    return iterator.map((t, index) => {
        const chart = section.charts && section.charts.find(c => c.name === t.name);
        let constantFilter = null;
        if (t.tableFilter) {
            constantFilter = t.tableFilter(filters, props.groupLevel && props.groupLevel);
        }
        const render = ({ loadData, renderDefault }) => (
            <TableRender
                settings={props.settings}
                table={t}
                filters={filters}
                loadData={loadData}
                renderDefault={renderDefault}
                groupLevel={props.groupLevel}
            />
        );
        return (
            <div key={t.name}>
                {renderTitle(t, index)}
                {chart && chart.position === 'top' && (
                    <div className={style.margin}>{renderChart(chart)}</div>
                )}
                {hasTables && (
                    <div className={style.margin}>{renderTable(t, constantFilter, render)}</div>
                )}
                {chart && chart.position === 'bottom' && (
                    <div className={style.margin}>{renderChart(chart)}</div>
                )}
            </div>
        );
    });
};

ReportSection.propTypes = {
    section: PropTypes.object,
    settings: PropTypes.object,
    filters: PropTypes.object,
    // from redux
    setWidgetLoaded: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({ youthPawnIds: state.app.report.youthPawnIds });

export default connect(mapStateToProps, { setWidgetLoaded })(ReportSection);
