import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { showTable, renderTitle } from 'js/vendor/reports/helpers.js';
import subscales from '../SecBFidelitySection/subscales.js';
import StrengthsTable from './tables/StrengthsTable';
import AreasToImporveTable from './tables/AreasToImproveTable';

import query from './query.graphql';

const SecBStrengthsSection = props => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const { respondentSelectorField } = props.filters;

    const respondents = [
        { id: '3', label: 'Caregiver', key: 'cg' },
        { id: '5', label: 'Youth', key: 'y' },
    ].filter(p => respondentSelectorField.includes(p.id));

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getSectionData();
        }
    }, [
        props.filters.evalCycleSelectorField,
        props.filters.siteSelectorField,
        props.youthPawnIds,
        respondentSelectorField,
    ]);

    const getSectionData = async () => {
        const variables = {
            pawnIds: props.youthPawnIds,
            deploymentIds: props.filters.evalCycleSelectorField || [],
            ccList: props.filters.careCoordSelectorField.map(cc => {
                const pawnDashIndex = cc.lastIndexOf('-');
                return cc.substring(0, pawnDashIndex);
            }),
        };
        const response = await taskMasterClient.query({
            query,
            variables,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        setData(response.data);
        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };

    return respondents.map(respondent => (
        <div key={respondent.id}>
            {renderTitle(`Section B Strengths for WFI-EZ ${respondent.label} Form`)}
            {showTable(<StrengthsTable {...{ data, respondent, subscales }} />, loading)}
            {renderTitle(`Section B Areas to Improve for WFI-EZ ${respondent.label} Form`)}
            {showTable(<AreasToImporveTable {...{ data, respondent, subscales }} />, loading)}
        </div>
    ));
};

SecBStrengthsSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded })(SecBStrengthsSection);
