import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round } from 'lodash';

const SubscaleChart = props => {
    const { data, subscales, groupLevelMap } = props;

    const getData = d => {
        let rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            let groupTotals = {};
            const sites = d.groups.siteList.filter(s => groupLevelMap[group].includes(s.group.groupId));
            for (var site of sites) {
                for (var subscale of subscales) {
                    let siteSubscaleTotal = null;
                    for (var item of subscale.items) {
                        const score = site.descendantRoles.youthSummary[item];
                        if (score !== null) {
                            siteSubscaleTotal += site.descendantRoles.youthSummary[item];
                        }
                    }
                    if (groupTotals[subscale.name]) {
                        if (siteSubscaleTotal !== null) {
                            groupTotals[subscale.name] += siteSubscaleTotal;
                        }
                    } else {
                        groupTotals[subscale.name] = siteSubscaleTotal;
                    }
                }
            }
            let row = { group };
            for (var sub of subscales) {
                if (groupTotals[sub.name] === null) {
                    row[sub.name] = null;
                } else {
                    row[sub.name] = groupTotals[sub.name] / (sub.items.length * sites.length);
                }
            }
            rows.push(row);
        }
        rows = rows.filter(row =>
            Object.keys(row).some(key => {
                if (key !== 'group') {
                    return row[key] >= 0 || row[key] === null;
                }
                return false;
            })
        );
        const totals = {};
        const nullScores = {};
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            for (var key of Object.keys(row)) {
                if (key !== 'group') {
                    const value = row[key];
                    if (value !== null) {
                        if (totals[key]) {
                            totals[key] += row[key];
                        } else {
                            totals[key] = row[key];
                        }
                    } else {
                        if (!nullScores[key]) {
                            nullScores[key] = [];
                        }
                        nullScores[key].push(i);
                    }
                }
            }
        }
        let total = { group: 'Total: ' };
        for (var s of subscales) {
            const numNull = nullScores[s.name] ? nullScores[s.name].length : 0;
            if (numNull === rows.length) {
                total[s.name] = '';
            } else {
                total[s.name] = `${round(100 * (totals[s.name] / (rows.length - numNull)), 2)}%`;
            }
        }
        let bars = [];
        for (var su of subscales) {
            bars.push({
                subscale: su.label,
                score: parseFloat(total[su.name].replace('%', ''), 10),
            });
        }
        return bars;
    };

    const chartData = getData(data);

    if (!chartData.length > 0) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    const longestLabel = Math.max(
        ...chartData.map(o => {
            return o.subscale.length;
        })
    );

    return (
        <div style={{ width: '770px', height: `${300 + 6.5 * longestLabel}px` }}>
            <ResponsiveBar
                indexBy="subscale"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={['score']}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 6.5 * longestLabel, left: 50 }}
            />
        </div>
    );
};

SubscaleChart.propTypes = {
    data: PropTypes.object,
    subscales: PropTypes.array,
    groupLevelMap: PropTypes.object,
};

export default SubscaleChart;
