import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from 'cccisd-nivo/pie';
import { pieLegend } from 'js/vendor/reports/helpers.js';
import { round } from 'lodash';

const MeetingAttendanceChart = props => {
    const { data, proSups, nattySups } = props;

    const getData = d => {
        let pro = 0;
        let nat = 0;
        for (const p of proSups) {
            const v = d.flows.meeting[`${p.name}Present`];
            if (v) {
                pro += v;
            }
        }
        for (const n of nattySups) {
            const v = d.flows.meeting[`${n.name}Present`];
            if (v) {
                nat += v;
            }
        }
        const proAttendance = round((pro / (pro + nat)) * 100);
        const natAttendance = round((nat / (pro + nat)) * 100);
        return [
            {
                id: 'Professional Supports Percent Attendance',
                label: `Professional Supports (${proAttendance}%)`,
                value: proAttendance,
            },
            {
                id: 'Natural Supports Percent Attendance',
                label: `Natural Supports (${natAttendance}%)`,
                value: natAttendance,
            },
        ];
    };

    return (
        <div style={{ width: '770px', height: '500px' }}>
            <ResponsivePie
                data={getData(data)}
                enableArcLinkLabels={false}
                theme={{ fontSize: 16 }}
                keys={['id']}
                arcLabelsSkipAngle={10}
                arcLinkLabelsSkipAngle={20}
                legends={pieLegend}
                margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
            />
        </div>
    );
};

MeetingAttendanceChart.propTypes = {
    data: PropTypes.object,
    nattySups: PropTypes.array,
    proSups: PropTypes.array,
};

export default MeetingAttendanceChart;
