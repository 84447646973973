import React from 'react';
import PropTypes from 'prop-types';
import query from './query.graphql';
import YouthFieldCountTable from '../YouthFieldCountTable';

const TeamMemberTypeCount = props => {
    return (
        <>
            <YouthFieldCountTable
                deploymentIds={props.filters.evalCycleSelectorField}
                query={query}
                field="relationshipToYouth"
                otherField="specifyOtherRelationship"
                table={props.table}
                filters={props.filters}
                settings={props.settings}
            />
        </>
    );
};

TeamMemberTypeCount.propTypes = {
    filters: PropTypes.object,
    table: PropTypes.object,
    settings: PropTypes.object,
};

export default TeamMemberTypeCount;
