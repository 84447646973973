import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from 'cccisd-nivo/pie';
import { round } from 'lodash';
import { pieLegend } from 'js/vendor/reports/helpers.js';

const MeetingFormatChart = props => {
    const labels = {
        2: {
            label: 'Via Telehealth',
            name: 'telehealth',
        },
        1: {
            label: 'In Person',
            name: 'person',
        },
    };

    const dd = props.data.flows.assignmentProgressSummary.meetingFormat;

    let total = 0;
    for (var i of dd) {
        total += i.valueCount;
    }

    let data = [];
    for (var k of Object.keys(labels)) {
        const l = labels[k];
        const e = dd.find(d => d.value === k.toString());

        if (e) {
            const label = `${l.label} (${round((e.valueCount / total) * 100, 2)}%)`;
            data.push({ id: l.name, label, value: e.valueCount });
        } else {
            const label = `${l.label} (0%)`;
            data.push({ id: l.name, label, value: 0 });
        }
    }

    return (
        <div style={{ width: '770px', height: '500px' }}>
            <ResponsivePie
                data={data}
                enableArcLinkLabels={false}
                theme={{ fontSize: 16 }}
                keys={['id']}
                arcLabelsSkipAngle={10}
                arcLinkLabelsSkipAngle={20}
                legends={pieLegend}
                margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
            />
        </div>
    );
};

MeetingFormatChart.propTypes = {
    data: PropTypes.object,
};

export default MeetingFormatChart;
