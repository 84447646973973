import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { mean, round } from 'lodash';

const ItemSubscaleTable = props => {
    const { data, respondent, careCoordSelectorField } = props;

    const cols = [
        {
            name: 'respondent',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        {
            name: 'satisfactionScore',
            label: 'Total Satisfaction Score',
            sort: true,
            filter: true,
        },
    ];

    const getData = () => {
        const ccList = careCoordSelectorField.map(cc => {
            const pawnDashIndex = cc.lastIndexOf('-');
            return cc.substring(0, pawnDashIndex);
        });
        const rows = [];
        if (data.flows[respondent.label].frequency) {
            for (const cc of data.flows[respondent.label].frequency) {
                if (ccList.includes(cc.value)) {
                    rows.push({ respondent: cc.value, satisfactionScore: round(cc.mean, 2) });
                }
            }
        }

        return rows;
    };

    const getTotal = rows => {
        const scores = [];
        let totalScore = 0;
        for (const row of rows) {
            if (row.satisfactionScore > 0) {
                scores.push(row.satisfactionScore);
            }

            totalScore = `${round(mean(scores), 2)}%`;
        }

        return {
            respondent: `${respondent.label} Mean:`,
            satisfactionScore: totalScore,
        };
    };

    const tableData = getData();
    const totalRow = getTotal(tableData);

    return (
        <Table
            rowKey="respondent"
            columns={cols}
            data={tableData}
            footer={totalRow}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="section-c-totals"
        />
    );
};

ItemSubscaleTable.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.object,
    careCoordSelectorField: PropTypes.object,
};

export default ItemSubscaleTable;
