import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const RespondentDataCollectionInfo = props => {
    const { data, groupLevel, groupLevelMap } = props;

    const getData = () => {
        let rows = [];
        let d = {};
        for (var cycle of data) {
            const siteId = parseInt(cycle.siteId, 10);
            for (var group of Object.keys(groupLevelMap)) {
                if (groupLevelMap[group].includes(siteId)) {
                    const { respondentCount, incomplete, completed, optedOut } = cycle;
                    const notStarted = respondentCount - completed - incomplete - optedOut;
                    if (d[group]) {
                        d[group].notStarted += notStarted;
                        d[group].incomplete += incomplete;
                        d[group].completed += completed;
                        d[group].optedOut += optedOut;
                    } else {
                        d[group] = {
                            notStarted,
                            incomplete,
                            completed,
                            optedOut,
                        };
                    }
                    break;
                }
            }
        }
        for (var g of Object.keys(d)) {
            const { notStarted, incomplete, completed, optedOut } = d[g];

            rows.push({
                group: g,
                notStarted,
                incomplete,
                completed,
                optedOut,
            });
        }
        return rows;
    };

    const getTotalRow = d => {
        let foot = { group: 'Total:', notStarted: 0, incomplete: 0, completed: 0, optedOut: 0 };
        for (var row of d) {
            foot.notStarted += row.notStarted;
            foot.incomplete += row.incomplete;
            foot.completed += row.completed;
            foot.optedOut += row.optedOut;
        }
        return foot;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        { name: 'notStarted', label: 'Number of Forms Not Started' },
        { name: 'incomplete', label: 'Number of Forms Incomplete' },
        { name: 'completed', label: 'Number of Forms Completed' },
        { name: 'optedOut', label: 'Number of Forms Opt-out' },
    ];

    const tableData = getData();
    const footer = getTotalRow(tableData);

    return (
        <Table
            data={tableData}
            rowKey="group"
            columns={cols}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            footer={footer}
            isCsvDownload
            csvFilename="dataa-collection-information"
        />
    );
};

RespondentDataCollectionInfo.propTypes = {
    data: PropTypes.array,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default RespondentDataCollectionInfo;
