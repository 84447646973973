import React from 'react';
import PropTypes from 'prop-types';
import ReportView from '../components/ReportView';
import ReportSection from '../components/ReportSection';

const Component = props => {
    const { sections, settings, filters, groupLevel } = props;
    const { title, desc } = props.settings;
    return (
        <ReportView title={title} desc={desc}>
            {sections.map(s => (
                <ReportSection
                    key={s.handle}
                    section={s}
                    settings={settings}
                    filters={filters}
                    groupLevel={groupLevel}
                />
            ))}
        </ReportView>
    );
};

Component.propTypes = {
    sections: PropTypes.array,
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    groupLevel: PropTypes.string,
};

// Filters are passed from reportTemplatePlayer HOC to the Report Player and Widgets
Component.defaultProps = {
    filters: {},
};

export default Component;
