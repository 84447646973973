import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const OptOutReasonTable = props => {
    const { data, suffix } = props;

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        { name: `declined${suffix}`, sort: true, label: 'Decline to Participate' },
        { name: `noResponse${suffix}`, sort: true, label: 'No Response to Communication' },
        { name: `incorrectInfo${suffix}`, sort: true, label: 'Incorrect Contact Information' },
        { name: `institution${suffix}`, sort: true, label: 'Institutionalized' },
        { name: `other${suffix}`, sort: true, label: 'Other' },
    ];

    const getData = () => {
        let rows = [];
        let i = 0;
        for (var cc of Object.keys(data)) {
            if (cc !== 'otherReasons') {
                let row = { careCoord: cc.split(', ')[0], cc: `${cc}-${i}` };
                for (var col of cols) {
                    if (col.name !== 'careCoord') {
                        row[col.name] = data[cc][col.name] || 0;
                    }
                }
                rows.push(row);
            }
            i += 1;
        }
        return rows;
    };

    const getTotalRow = d => {
        let foot = {
            careCoord: 'Total:',
            [`declined${suffix}`]: 0,
            [`noResponse${suffix}`]: 0,
            [`incorrectInfo${suffix}`]: 0,
            [`institution${suffix}`]: 0,
            [`other${suffix}`]: 0,
        };
        for (var row of d) {
            foot[`declined${suffix}`] += row[`declined${suffix}`];
            foot[`noResponse${suffix}`] += row[`noResponse${suffix}`];
            foot[`incorrectInfo${suffix}`] += row[`incorrectInfo${suffix}`];
            foot[`institution${suffix}`] += row[`institution${suffix}`];
            foot[`other${suffix}`] += row[`other${suffix}`];
        }
        return foot;
    };

    const tableData = getData();
    const footer = getTotalRow(tableData);
    return (
        <Table
            data={tableData}
            rowKey="cc"
            orderBy="careCoord"
            columns={cols}
            footer={footer}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="opt-out-reasons"
        />
    );
};

OptOutReasonTable.propTypes = {
    data: PropTypes.object,
    suffix: PropTypes.string,
};

export default OptOutReasonTable;
