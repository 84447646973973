import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { taskMasterClient } from 'cccisd-apollo';
import { loadGroups } from 'js/reducers/sites.js';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { connect } from 'react-redux';
import queryCC from './queries/queryCC.graphql';
import queryCG from './queries/queryCG.graphql';
import queryTM from './queries/queryTM.graphql';
import queryY from './queries/queryY.graphql';

import QuestionTable from '../../tables/QuestionTable';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import {
    showChart,
    showTable,
    renderTitle,
    renderSubtitle,
    getGroupLevelMap,
    renderDescription,
} from 'js/vendor/reports/helpers.js';

// charts
import OverallFidelityChart from './charts/OverallFidelityChart';
import OverallElementChart from './charts/OverallElementChart';
import ElementItemChart from './charts/ElementItemChart';
import RespondentKeyElementsChart from './charts/RespondentKeyElementsChart';

// tables
import BenchmarkTable from './tables/BenchmarkTable';
import RespondentKeyElementsTable from './tables/RespondentKeyElementsTable';
import ItemQuestionTable from './tables/ItemQuestionTable';
import CommentsTable from './tables/CommentsTable';
import ItemQuestionMeans from '../../tables/ItemQuestionMeans';

const respondents = {
    2: { name: 'careCoordinator', label: 'Care Coordinator', short: 'cc', query: queryCC },
    3: { name: 'caregiver', label: 'Caregiver', short: 'cg', query: queryCG },
    4: { name: 'teamMember', label: 'Team Member', short: 'tm', query: queryTM },
    5: { name: 'youth', label: 'Youth', short: 'y', query: queryY },
};

export const scales = [
    {
        name: 'overall_fidelity',
        label: 'Overall Fidelity',
        key: 'Overall_Fidelity',
        short: 'Overall',
    },
    {
        name: 'effective_teamwork',
        label: 'Effective Teamwork',
        key: 'Effective_Teamwork',
        short: 'ET',
        items: ['B2', 'B4', 'B7', 'B15', 'B22'],
        desc: 'The Effective Teamwork subscale consists of items B2*, B4, B7*, B15*, and B22. Items are rated on a 5 point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). An asterisk indicates that the item is reverse-scored. Users also have the option to respond “don’t know” to any item, which renders the item “blank”.',
    },
    {
        name: 'natural_community_supports',
        label: 'Natural Community Support',
        key: 'Natural_Community',
        short: 'NC',
        items: ['B9', 'B10', 'B12', 'B16', 'B18'],
        desc: 'The Natural/Community Support subscale consists of items B9, B10, B12*, B16, and B18. Items are rated on a 5 point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). An asterisk indicates that the item isreverse-scored. Users also have the option to respond “don’t know” to any item, which renders the item “blank”.',
    },
    {
        name: 'needs_based',
        label: 'Needs Based',
        key: 'Needs_Based',
        short: 'NB',
        items: ['B5', 'B6', 'B8', 'B13', 'B23'],
        desc: 'The Needs-Based subscale consists of items B5, B6, B8, B13, and B23*. Items are rated on a 5 point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). An asterisk indicates that the item is reverse-scored. Users also have the option to respond “don’t know” to any item, which renders the item “blank”.',
    },
    {
        name: 'outcomes_based',
        label: 'Outcomes Based',
        key: 'Outcomes_Based',
        short: 'OB',
        items: ['B19', 'B20', 'B21', 'B24', 'B25'],
        desc: 'The Outcomes-Based subscale consists of items B19, B20, B21, B24, and B25. Items are rated on a 5 point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). An asterisk indicates that the item is reverse-scored. Users also have the option to respond “don’t know” to any item, which renders the item “blank”.',
    },
    {
        name: 'strength_family_driven',
        label: 'Strength Family Driven',
        key: 'Strength_Family',
        short: 'SF',
        items: ['B1', 'B3', 'B11', 'B14', 'B17'],
        desc: 'The Strength and Family Driven subscale consists of items B1,B3, B11, B14, and B17*. Items are rated on a 5 point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). An asterisk indicates that the item is reverse-scored. Users also have the option to respond “don’t know” to any item, which renders the item “blank”.',
    },
];

const FundamentalsSection = props => {
    const [data, setData] = useState({});

    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    const { evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField } =
        props.filters;

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            setGroupLevelMap(getGroupLevelMap(props.groups, groupLevel, siteSelectorField));
            getData();
        }
    }, [evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField]);

    const groupLevel = props.groupLevel || 'group';
    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    const doQuery = async () => {
        let dataMap = { careCoordinator: {}, caregiver: {}, teamMember: {}, youth: {} };
        let queries = respondentSelectorField.map(id => ({
            id,
            name: respondents[id].name,
            query: {
                query: respondents[id].query,
                variables: {
                    deploymentIds: evalCycleSelectorField || [],
                    siteIds: siteSelectorField || [],
                    pawnIds: props.youthPawnIds || [],
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            },
        }));

        await Promise.all(
            queries.map(query =>
                taskMasterClient.query({ ...query.query, cancelTag: 'report' }).then(response => {
                    const { name } = query;
                    dataMap[name] = response.data;
                })
            )
        );
        return dataMap;
    };

    const CACHE_DATA = false;
    // localStorage.setItem('sectionB', false);

    const getData = async () => {
        let response;
        if (CACHE_DATA) {
            const cache = JSON.parse(localStorage.getItem('sectionB'));
            if (cache) {
                response = cache;
            } else {
                response = await doQuery();
                localStorage.setItem('sectionB', JSON.stringify(response));
            }
        } else {
            response = await doQuery();
        }
        setData(response);
        setLoading(false);
        props.setWidgetLoaded(props.section.name + 'Section');
    };

    const levels = [
        { name: 'highFidelity', label: 'High Fidelity' },
        { name: 'adequate', label: 'Adequate' },
        { name: 'borderline', label: 'Borderline' },
        { name: 'inadequate', label: 'Inadequate' },
    ];

    let hasData = true;
    for (var res of respondentSelectorField) {
        const { name } = respondents[res];
        if (!loading && !Object.keys(data[name]).includes('groups')) {
            hasData = false;
            break;
        }
    }

    if (hasData) {
        return (
            <>
                {renderTitle('Key Elements and Overall Fidelity')}
                {showChart(
                    <OverallFidelityChart
                        data={data}
                        subscales={scales}
                        groups={groupLevelMap}
                        filters={props.filters}
                        respondents={respondents}
                        groupLevelMap={groupLevelMap}
                    />,
                    loading
                )}
                {respondentSelectorField.map(id => {
                    const type = respondents[id].label
                        .replace(/([A-Z])/g, '$1')
                        .replace(/^./, str => {
                            return str.toUpperCase();
                        })
                        .replace(' ', '');

                    const respondent = respondents[id].name;

                    return (
                        <div key={id}>
                            <div>
                                {renderSubtitle(`Section B Key Elements for WFI-EZ ${respondents[id].label} Form`)}
                                {showChart(
                                    <RespondentKeyElementsChart
                                        data={data[respondent]}
                                        groups={groupLevelMap}
                                        subscales={scales}
                                        respondent={respondents[id]}
                                        groupLevelMap={groupLevelMap}
                                    />,
                                    loading
                                )}
                            </div>
                            {showTable(
                                <RespondentKeyElementsTable
                                    data={data[respondent]}
                                    respondent={respondents[id]}
                                    scales={scales}
                                    groupLevel={groupLevel}
                                    groupLevelMap={groupLevelMap}
                                />,
                                loading
                            )}
                            {showTable(<BenchmarkTable type={type} upper scales={scales} levels={levels} />, loading)}
                        </div>
                    );
                })}
                {renderTitle('Subscale and Item Responses')}

                {respondentSelectorField.map(id => {
                    const respondent = respondents[id].name;

                    return (
                        <>
                            {scales.map(subscale => {
                                if (subscale.items) {
                                    return (
                                        <div key={subscale.name}>
                                            <div>
                                                {renderSubtitle(subscale.label)}
                                                {renderDescription(subscale.desc)}
                                                {showChart(
                                                    <OverallElementChart
                                                        data={data}
                                                        respondents={respondents}
                                                        respondentSelectorField={respondentSelectorField}
                                                        subscale={subscale}
                                                        groupLevelMap={groupLevelMap}
                                                    />,
                                                    loading
                                                )}
                                            </div>
                                            <div>
                                                {renderSubtitle(
                                                    `Section B ${subscale.label} for WFI-EZ ${respondents[id].label} Form`
                                                )}
                                                {showChart(
                                                    <ElementItemChart
                                                        subscale={subscale}
                                                        respondent={respondents[id]}
                                                        data={data[respondent]}
                                                        groupLevelMap={groupLevelMap}
                                                    />,
                                                    loading
                                                )}
                                            </div>
                                            {showTable(
                                                <ItemQuestionTable
                                                    subscale={subscale}
                                                    respondent={respondents[id]}
                                                    data={data[respondent]}
                                                    groupLevelMap={groupLevelMap}
                                                    groupLevel={groupLevel}
                                                />,
                                                loading
                                            )}
                                            {showTable(
                                                <ItemQuestionMeans
                                                    items={subscale.items}
                                                    level={respondents[id].label.replace(' ', '')}
                                                />,
                                                loading
                                            )}
                                            {showTable(
                                                <QuestionTable
                                                    items={subscale.items}
                                                    type={`${respondents[id].label.replace(' ', '')}`}
                                                />,
                                                loading
                                            )}
                                        </div>
                                    );
                                }
                                return null;
                            })}
                            <Collapsable
                                collapsed
                                title={`Additional comments from ${respondents[id].label} form`}
                                content={showTable(
                                    <CommentsTable
                                        data={loading ? [] : data[respondent]}
                                        respondent={respondents[id]}
                                        groupLevel={groupLevel}
                                        groupLevelMap={groupLevelMap}
                                    />,
                                    loading
                                )}
                            />
                        </>
                    );
                })}
            </>
        );
    }
    return null;
};

FundamentalsSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    setWidgetLoaded: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(FundamentalsSection);
