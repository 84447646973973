import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const TypeOfMeetingTable = props => {
    const { groupLevel, groupLevelMap, data } = props;

    const labels = {
        '1': {
            label: 'Initial Team/Planning Meeting',
            name: 'initial',
        },
        '2': {
            label: 'Follow-up Meeting',
            name: 'followUp',
        },
        '3': {
            label: 'Transition/Discharge Meeting',
            name: 'discharge',
        },
        '4': {
            label: 'Other',
            name: 'other',
        },
    };

    const getData = d => {
        const rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            const youthInGroup = d.flows.assignmentProgressList.filter(y =>
                groupLevelMap[group].includes(y.pawn.parentGroup.site.group.groupId)
            );
            let groupTotal = { initial: 0, followUp: 0, discharge: 0, other: 0, groupSum: 0 };
            for (var youth of youthInGroup) {
                const evaluator = youth.devTags.MeetingType;
                if (evaluator) {
                    groupTotal[labels[evaluator].name] += 1;
                    groupTotal.groupSum += 1;
                }
            }
            rows.push({
                group,
                initial: groupTotal.initial,
                followUp: groupTotal.followUp,
                discharge: groupTotal.discharge,
                other: groupTotal.other,
                groupSum: groupTotal.groupSum,
            });
        }
        return rows;
    };

    const getTotalRow = td => {
        let total = { initial: 0, followUp: 0, discharge: 0, other: 0, groupSum: 0 };
        for (var row of td) {
            total.initial += row.initial;
            total.followUp += row.followUp;
            total.discharge += row.discharge;
            total.other += row.other;
            total.groupSum += row.groupSum;
        }
        return {
            group: 'Total:',
            initial: `${total.initial} (${round((total.initial / total.groupSum) * 100, 2)}%)`,
            followUp: `${total.followUp} (${round((total.followUp / total.groupSum) * 100, 2)}%)`,
            discharge: `${total.discharge} (${round(
                (total.discharge / total.groupSum) * 100,
                2
            )}%)`,
            other: `${total.other} (${round((total.other / total.groupSum) * 100, 2)}%)`,
        };
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        ...Object.keys(labels).map(n => ({
            name: labels[n].name,
            label: labels[n].label,
            render(value, row) {
                return value > 0 ? `${value} (${round((value / row.groupSum) * 100, 2)}%)` : 0;
            },
        })),
    ];

    const tableData = getData(data);
    const totalRow = getTotalRow(tableData);

    return (
        <Table
            data={tableData}
            rowKey="group"
            columns={cols}
            showPerPageOptions={false}
            footer={totalRow}
            isCsvDownload
        />
    );
};

TypeOfMeetingTable.propTypes = {
    data: PropTypes.object,
    groupLevelMap: PropTypes.object,
    groupLevel: PropTypes.string,
};

export default TypeOfMeetingTable;
