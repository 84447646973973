import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round } from 'lodash';

const items3pt = ['D5', 'D6', 'D7', 'D8', 'D9'];

const RespondentItemChart = props => {
    const { data, respondent, item } = props;
    const getData = () => {
        let barGroup = { item };
        if (data.flows[respondent.label].frequency) {
            for (const cc of data.flows[respondent.label].frequency) {
                let score = cc[`${item}sum`] / cc[`${item}count`];
                if (items3pt.includes(item)) {
                    score = round(score, 2);
                } else {
                    score = round(score * 100, 2); // 3pt scale convert to percent
                }
                if (cc.value && score) {
                    barGroup[cc.value.split(', ')[0]] = score;
                }
            }
        }
        return [barGroup];
    };

    const chartData = getData(item);

    const longestLabel = Math.max(
        ...Object.keys(chartData[0]).map(o => {
            return o.length + 13;
        })
    );
    const right = 6 * longestLabel;
    const yAxisMax = items3pt.includes(item) ? 3 : 100;

    return (
        <div
            style={{
                width: '770px',
                height: `400px`,
            }}
        >
            <ResponsiveBar
                indexBy="item"
                groupMode="grouped"
                maxValue={yAxisMax}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={Object.keys(chartData[0]).filter(k => k !== 'item')}
                theme={{ fontSize: 16 }}
                data={getData()}
                margin={{ top: 15, right, bottom: 50, left: 50 }}
            />
        </div>
    );
};

RespondentItemChart.propTypes = {
    data: PropTypes.array,
    respondent: PropTypes.object,
    item: PropTypes.array,
};

export default RespondentItemChart;
