import React from 'react';
import PropTypes from 'prop-types';
import { round, mean } from 'lodash';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const OverallSubscaleChart = props => {
    const { data, items, respondents } = props;

    const getData = () => {
        const bars = [];
        for (const item of items) {
            const itemBarGroup = { item };
            for (const res of respondents) {
                const respondentScores = [];
                if (data.flows[res.label].frequency) {
                    for (const cc of data.flows[res.label].frequency) {
                        cc[`${item}count`] && respondentScores.push(cc[`${item}avg`] / cc[`${item}count`]);
                    }
                }
                itemBarGroup[res.label] = round(mean(respondentScores) * 100, 2);
            }
            bars.push(itemBarGroup);
        }
        return bars;
    };

    const chartData = getData();

    if (Object.keys(chartData[0]).length < 2) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    return (
        <div style={{ width: '770px', height: `${300}px` }}>
            <ResponsiveBar
                indexBy="item"
                groupMode="grouped"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={respondents.map(r => r.label)}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 50, left: 50 }}
            />
        </div>
    );
};

OverallSubscaleChart.propTypes = {
    data: PropTypes.object,
    items: PropTypes.array,
    respondents: PropTypes.array,
};

export default OverallSubscaleChart;
