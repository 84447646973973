import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const EvaluationCycleTable = props => {
    const cols = [
        {
            name: 'cycleKey',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
            render: value => {
                const pawnDashIndex = value.lastIndexOf('-');
                return value.substring(0, pawnDashIndex);
            },
        },
        { name: 'cycle', label: 'Evaluation Cycle', sort: true, filter: true },
        { name: 'sampleSize', sort: true, label: 'Sample Size' },
        {
            name: 'numCompleted',
            sort: true,
            label: 'Number of completed WFI-EZ forms',
        },
        {
            name: 'responseRate',
            label: 'Response Rate',
            sort: true,
            tooltip:
                'The response rate is calculated by dividing the number of completed WFI-EZs by the number of forms that could have been administered (i.e. the youth was enrolled in Wraparound, was not institutionalized, and accurate contact information was provided).',
        },
    ];

    return (
        <Table
            data={props.data}
            rowKey="cycleKey"
            orderBy="cycleKey"
            columns={cols}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="evaluation-cycles"
        />
    );
};

EvaluationCycleTable.propTypes = {
    data: PropTypes.object,
};

export default EvaluationCycleTable;
