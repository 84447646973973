const subscales = [
    {
        label: 'Overall',
        name: 'overall_fidelity',
        items: false,
        desc: '',
        key: 'Overall',
    },
    {
        label: 'Effective Teamwork',
        name: 'effective_teamwork',
        items: ['B2*', 'B4', 'B7*', 'B15*', 'B22'],
        desc: 'The Effective Teamwork subscale consists of items B2*, B4, B7*, B15*, and B22. Items are rated on a 5 point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). An asterisk indicates that the item is reverse-scored. Users also have the option to respond "don\'t know" to any item, which renders the item "blank".',
        key: 'ET',
    },
    {
        label: 'Natural Community Support',
        name: 'natural_community_supports',
        items: ['B9', 'B10', 'B12*', 'B16', 'B18'],
        desc: 'The Natural/Community Support subscale consists of items B9, B10, B12*, B16, and B18. Items are rated on a 5 point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). An asterisk indicates that the item is reverse-scored. Users also have the option to respond “don’t know” to any item, which renders the item “blank”.',
        key: 'NC',
    },
    {
        label: 'Needs-Based',
        name: 'needs_based',
        items: ['B5', 'B6', 'B8', 'B13', 'B23*'],
        desc: 'The Needs-Based subscale consists of items B5, B6, B8, B13, and B23*. Items are rated on a 5 point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). An asterisk indicates that the item is reverse-scored. Users also have the option to respond “don’t know” to any item, which renders the item “blank”.',
        key: 'NB',
    },
    {
        label: 'Outcomes-Based',
        name: 'outcomes_based',
        items: ['B19', 'B20', 'B21', 'B24', 'B25'],
        desc: 'The Outcomes-Based subscale consists of items B19, B20, B21, B24, and B25. Items are rated on a 5 point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). An asterisk indicates that the item is reverse-scored. Users also have the option to respond “don’t know” to any item, which renders the item “blank”.',
        key: 'OB',
    },
    {
        label: 'Strength Family Driven',
        name: 'strength_family_driven',
        items: ['B1', 'B3', 'B11', 'B14', 'B17*'],
        desc: 'The Strength and Family Driven subscale consists of items B1,B3, B11, B14, and B17*. Items are rated on a 5 point Likert scale (-2 to 2, Strongly Disagree to Strongly Agree). An asterisk indicates that the item is reverse-scored. Users also have the option to respond “don’t know” to any item, which renders the item “blank”.',
        key: 'SF',
    },
];
export default subscales;
