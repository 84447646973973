import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const OptOutReasonTable = props => {
    const { data, groupLevel, groupLevelMap } = props;

    const getData = () => {
        let rows = [];
        let d = {};
        for (var cycle of data) {
            const siteId = parseInt(cycle.siteId, 10);
            for (var group of Object.keys(groupLevelMap)) {
                if (groupLevelMap[group].includes(siteId)) {
                    const { declined, noResponse, incorrectInfo, institution, other } = cycle;
                    if (d[group]) {
                        d[group].declined += declined;
                        d[group].noResponse += noResponse;
                        d[group].incorrectInfo += incorrectInfo;
                        d[group].institution += institution;
                        d[group].other += other;
                    } else {
                        d[group] = {
                            declined,
                            noResponse,
                            incorrectInfo,
                            institution,
                            other,
                        };
                    }
                    break;
                }
            }
        }
        for (var g of Object.keys(d)) {
            const { declined, noResponse, incorrectInfo, institution, other } = d[g];

            rows.push({
                group: g,
                declined,
                noResponse,
                incorrectInfo,
                institution,
                other,
            });
        }
        return rows;
    };

    const getTotalRow = d => {
        let foot = {
            group: 'Total:',
            declined: 0,
            noResponse: 0,
            incorrectInfo: 0,
            institution: 0,
            other: 0,
        };
        for (var row of d) {
            foot.declined += row.declined;
            foot.noResponse += row.noResponse;
            foot.incorrectInfo += row.incorrectInfo;
            foot.institution += row.institution;
            foot.other += row.other;
        }
        return foot;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        { name: 'declined', label: 'Declined to Participate' },
        { name: 'noResponse', label: 'No Response to Communication' },
        { name: 'incorrectInfo', label: 'Incorrect Contact Information' },
        { name: 'institution', label: 'Institutionalized' },
        { name: 'other', label: 'Other' },
    ];

    const tableData = getData();
    const footer = getTotalRow(tableData);

    return (
        <Table
            data={tableData || []}
            rowKey="group"
            columns={cols}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            footer={footer}
            isCsvDownload
            csvFilename="opt-out-reasons"
        />
    );
};

OptOutReasonTable.propTypes = {
    data: PropTypes.array,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default OptOutReasonTable;
