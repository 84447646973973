import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from 'cccisd-nivo/pie';
import { round } from 'lodash';
import { pieLegend } from 'js/vendor/reports/helpers.js';

const DischargeSuccessRateChart = props => {
    const { data } = props;

    const getData = d => {
        const completeMap = { complete: 0, incomplete: 0 };
        for (const pawn of d.flows.assignmentProgressList) {
            if (pawn.pawn.fields.exitReason && pawn.pawn.fields.exitReason.startsWith('complete')) {
                completeMap.complete += 1;
            } else {
                completeMap.incomplete += 1;
            }
        }
        return [
            {
                id: `Complete (${round(
                    (completeMap.complete / (completeMap.complete + completeMap.incomplete)) * 100,
                    2
                )}%)`,
                value: completeMap.complete,
            },
            {
                id: `Incomplete (${round(
                    (completeMap.incomplete / (completeMap.complete + completeMap.incomplete)) * 100,
                    2
                )}%)`,
                value: completeMap.incomplete,
            },
        ];
    };

    const chartData = getData(data);

    return (
        <div style={{ width: '770px', height: '500px' }}>
            <ResponsivePie
                data={chartData}
                enableArcLinkLabels={false}
                theme={{ fontSize: 16 }}
                keys={['id']}
                arcLabelsSkipAngle={10}
                arcLinkLabelsSkipAngle={20}
                legends={pieLegend}
                margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
            />
        </div>
    );
};

DischargeSuccessRateChart.propTypes = {
    data: PropTypes.object,
};

export default DischargeSuccessRateChart;
