import React from 'react';
import PropTypes from 'prop-types';
import { round, mean } from 'lodash';
import Table from 'cccisd-table';

const SubscaleItemTable = props => {
    const { data, respondent, items } = props;

    const getData = () => {
        const rows = [];
        if (data.flows[respondent.label].frequency)
            for (const cc of data.flows[respondent.label].frequency) {
                const row = { careCoord: cc.value.split(', ')[0], cc: cc.value };
                for (const itm of items) {
                    const k = itm.replace('*', '');
                    row[itm] = { count: cc[`N${k}`], score: cc[k] };
                }
                rows.push(row);
            }
        return rows;
    };

    const getTotalRow = d => {
        const t = { careCoord: 'Total:' };
        for (const i of items) {
            const allItems = [];
            for (const row of d) {
                if (row[i].count) {
                    allItems.push(row[i].score);
                }
            }
            t[i] = Number.isNaN(mean(allItems)) ? 'N/A*' : round(mean(allItems), 2);
        }
        return t;
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
        },
        ...items.map(i => ({
            name: i,
            label: i,
            tooltip: window.cccisd.appDefs.wfiQuestions[`${respondent.label}_${i.replace('*', '')}`].question,
            render: val => (val.score !== null ? `${round(val.score, 2)}, N=${round(val.count, 2)}` : 'N/A*'),
        })),
    ];

    const tableData = getData();
    const totalRow = getTotalRow(tableData);
    const showNotice = tableData.some(row => cols.some(col => row[col.name].score === null));

    return (
        <>
            <Table
                rowKey="cc"
                orderBy="careCoord"
                columns={cols}
                data={tableData}
                footer={totalRow}
                perPage={5000}
                perPageOptions={[250, 500, 1000, 5000]}
                isCsvDownload
                csvFilename="section-b-items"
            />
            {showNotice && (
                <p>
                    * Indicates all WFI-EZs for this item were scored as &quot;N/A&quot;, thus a summary score can not
                    be calculated
                </p>
            )}
        </>
    );
};

SubscaleItemTable.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.string,
    items: PropTypes.array,
};

export default SubscaleItemTable;
