import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const SummaryChart = props => {
    const { data, markers } = props;

    return (
        <div style={{ width: '770px', height: '650px' }}>
            <ResponsiveBar
                indexBy="respondent"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={['mean']}
                theme={{ fontSize: 16 }}
                data={data}
                margin={{ top: 15, right: 150, bottom: 50, left: 50 }}
                markers={markers}
            />
        </div>
    );
};

SummaryChart.propTypes = {
    data: PropTypes.array,
    markers: PropTypes.object,
};

export default SummaryChart;
