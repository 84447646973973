import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { taskMasterClient } from 'cccisd-apollo';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import query from './query.graphql';
import ElementItemChart from './charts/ElementItemChart';
import RespondentItemChart from './charts/RespondentItemChart';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import ItemMeanTable from './tables/ItemMeanTable';
import ItemQuestionTable from './tables/ItemQuestionTable';
import ItemTable from './tables/ItemTable';
import CommentsTable from './tables/CommentsTable';

import { setWidgetLoaded } from 'js/reducers/report.js';
import {
    showChart,
    showTable,
    renderTitle,
    renderSubtitle,
    renderDescription,
    hiddenChart,
} from 'js/vendor/reports/helpers.js';

const subscales = [
    {
        name: 'schoolAndCommunity',
        label: 'School and Community Outcomes',
        desc: 'The School and Community Outcomes subscale consists of items D1 – D4. Items are rated on a binary scale, “Yes” or “No”. Users also have the option to respond “don’t know” to any item, which renders the item “blank”.',
        items: ['D1', 'D2', 'D3', 'D4'],
    },
    {
        name: 'functioningOutcomes',
        label: 'Functioning Outcomes',
        desc: 'The Functioning Outcomes subscale consists of items D5 – D9. Items are rated on a 4 point Likert scale (0 to 3, Not At All to Very Much). Users also have the option to respond “don’t know” to any item, which renders the item “N/A”.',
        items: ['D5', 'D6', 'D7', 'D8', 'D9'],
    },
];

const OutcomesSection = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const { evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField } =
        props.filters;

    // section D not in youth form
    const respondents = [{ name: 'caregiver', label: 'Caregiver', id: '3' }].filter(r =>
        respondentSelectorField.includes(r.id)
    );

    const maxCC = 10;
    const hideChart =
        props.filters.careCoordSelectorField && Object.keys(props.filters.careCoordSelectorField).length > maxCC;

    useEffect(() => {
        async () => {
            if (props.groups.length > 0) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getData();
        }
    }, [evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField]);

    const getData = async () => {
        let response;
        response = await doQuery();
        setData(response.data);
        setLoading(false);
        props.setWidgetLoaded(props.section.name + 'Section');
    };

    const doQuery = () => {
        return taskMasterClient.query({
            query,
            variables: {
                pawnIds: props.youthPawnIds,
                deploymentIds: evalCycleSelectorField || [],
                ccList: props.filters.careCoordSelectorField.map(cc => {
                    const pawnDashIndex = cc.lastIndexOf('-');
                    return cc.substring(0, pawnDashIndex);
                }),
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
    };

    return (
        <>
            {renderTitle('Subcale and Item Responses')}
            {renderDescription(
                'Section D consists of two subscales, School and Community Outcomes and Functioning Outcomes.'
            )}
            {
                respondents.map(
                    respondent => (
                        <>
                            {subscales.map(subscale => {
                                let { items } = subscale;
                                return (
                                    <div key={subscale.name}>
                                        <div>
                                            {renderSubtitle(subscale.label)}
                                            {subscale.desc}
                                            {showChart(
                                                <ElementItemChart
                                                    subscale={subscale}
                                                    respondents={respondents}
                                                    data={data}
                                                />,
                                                loading
                                            )}
                                        </div>
                                        <div key={respondent.name}>
                                            {renderSubtitle(`Section D ${subscale.label} for WFI-EZ Form`)}
                                            {items.map(item => {
                                                return (
                                                    <div key={item}>
                                                        {renderSubtitle(`Item ${item} - ${respondent.label}`)}
                                                        {hideChart
                                                            ? hiddenChart(
                                                                  `Chart hidden for reports with more than ${maxCC} Care Coordinators`
                                                              )
                                                            : showChart(
                                                                  <RespondentItemChart
                                                                      data={data}
                                                                      respondent={respondent}
                                                                      item={item}
                                                                  />,
                                                                  loading
                                                              )}
                                                    </div>
                                                );
                                            })}
                                            {showTable(
                                                <ItemTable items={items} respondent={respondent} data={data} />,
                                                loading
                                            )}
                                            {showTable(
                                                <ItemMeanTable items={items} respondent={respondent} />,
                                                loading
                                            )}
                                            <div>
                                                {renderDescription(
                                                    'The table below presents the survey question for each item.'
                                                )}

                                                {showTable(
                                                    <ItemQuestionTable items={items} respondent={respondent} />,
                                                    loading
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <Collapsable
                                collapsed
                                title={`Additional comments from ${respondent.label} form`}
                                content={showTable(<CommentsTable respondent={respondent} data={data} />, loading)}
                            />
                        </>
                    ) // return
                ) // map
            }
        </>
    ); // return
}; // component

OutcomesSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    // redux
    setWidgetLoaded: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(OutcomesSection);
