import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';
import Skeleton from 'react-loading-skeleton';
import commentQuery from './query.graphql';

import CommentTable from './tables/CommentTable';

import { showTable, renderSubtitle } from 'js/vendor/reports/helpers.js';

const commentTables = [
    {
        name: 'FMA',
        label: 'Full Meeting Attendance',
    },
    {
        name: 'ET',
        label: 'Effective Teamwork',
    },
    {
        name: 'DSF',
        label: 'Driven by Strength and Families',
    },
    {
        name: 'PN',
        label: 'Based on Priority Needs',
    },
    {
        name: 'NCS',
        label: 'Use of Natural and Community Support',
    },
    {
        name: 'OB',
        label: 'Outcomes-Based Process',
    },
    {
        name: 'SF',
        label: 'Skilled Facilitation',
    },
    {
        name: 'OR',
        label: 'Overall',
    },
];

function CommentSection(props) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getData = async () => {
        const response = await taskMasterClient.query({
            query: commentQuery,
            variables: {
                pawnIds: props.youthPawnIds || [],
                deploymentIds: props.filters.evalCycleSelectorField || [],
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });

        setData(response.data);
        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };

    if (loading) {
        return <Skeleton rows={15} />;
    }

    const tablesWithData = commentTables.filter(c =>
        data.flows.assignmentProgressSummary[c.name].some(comment => comment.value)
    );

    if (tablesWithData.length === 0) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    return (
        <>
            {commentTables.map(c => {
                if (data.flows.assignmentProgressSummary[c.name].some(comment => comment.value)) {
                    return (
                        <div key={c.name}>
                            {renderSubtitle(`${c.label} - Comments`)}
                            {showTable(
                                <CommentTable
                                    data={data.flows.assignmentProgressSummary[c.name]}
                                />,
                                loading
                            )}
                        </div>
                    );
                }
                return null;
            })}
        </>
    );
}

CommentSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(CommentSection);
