import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { taskMasterClient } from 'cccisd-apollo';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import cycleInfoQuery from './queries/cycleInfoQuery.graphql';
import otherReasonsQuery from './queries/otherReasonsQuery.graphql';
import responseInfoQueryCC from './queries/responseInfoQueryCC.graphql';
import responseInfoQueryCG from './queries/responseInfoQueryCG.graphql';
import responseInfoQueryTM from './queries/responseInfoQueryTM.graphql';
import responseInfoQueryY from './queries/responseInfoQueryY.graphql';
import exclusionQuery from './queries/exclusionQuery.graphql';
import DataCollectionInfoTable from './tables/DataCollectionInfoTable';
import ExclusionTable from './tables/ExclusionTable';
import RespondentDataCollectionInfo from './tables/RespondentDataCollectionInfo';
import OtherResponseTable from './tables/OtherResponseTable';
import OptOutReasonTable from './tables/OptOutReasonTable';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { showTable, renderSubtitle, getGroupLevelMap } from 'js/vendor/reports/helpers.js';

const respondents = {
    2: {
        name: 'careCoordinator',
        label: 'Care Coordinator',
        short: 'cc',
        query: responseInfoQueryCC,
    },
    3: { name: 'caregiver', label: 'Caregiver', short: 'cg', query: responseInfoQueryCG },
    4: { name: 'teamMember', label: 'Team Member', short: 'tm', query: responseInfoQueryTM },
    5: { name: 'youth', label: 'Youth', short: 'y', query: responseInfoQueryY },
};

const SampleResponseSummary = props => {
    const [data, setData] = useState({});

    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    const { evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField } =
        props.filters;

    const groupLevel = props.groupLevel || 'group';
    useEffect(() => {
        async () => {
            if (props.groups.length === 0) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            setGroupLevelMap(getGroupLevelMap(props.groups, groupLevel, siteSelectorField));
            getData();
        }
    }, [evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField]);

    const getData = async () => {
        let dataMap = { cycleInfo: {}, responseInfo: {}, otherReasons: {}, exclusion: {} };
        let queries = [
            {
                name: 'cycleInfo',
                query: {
                    id: false,
                    query: cycleInfoQuery,
                    variables: {
                        deploymentIds: evalCycleSelectorField || [],
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
            },
            {
                name: 'exclusion',
                query: {
                    id: false,
                    query: exclusionQuery,
                    variables: {
                        deploymentIds: evalCycleSelectorField || [],
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
            },
            ...respondentSelectorField.map(id => ({
                id,
                name: 'responseInfo',
                query: {
                    query: respondents[id].query,
                    variables: {
                        deploymentIds: evalCycleSelectorField || [],
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
            })),
            ...respondentSelectorField.map(id => ({
                id,
                name: 'otherReasons',
                query: {
                    query: otherReasonsQuery,
                    variables: {
                        deploymentIds: evalCycleSelectorField || [],
                        assignmentId: parseInt(id, 10),
                    },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                },
            })),
        ];

        const respondentsNotUsed = Object.keys(respondents).filter(r => !respondentSelectorField.includes(r));

        await Promise.all(
            queries.map(query => {
                // add empty data to remaining respondent types
                for (var res of respondentsNotUsed) {
                    dataMap[query.name][res] = { flows: { deploymentList: [] } };
                }
                return taskMasterClient.query({ ...query.query, cancelTag: 'report' }).then(response => {
                    const { name } = query;
                    if (query.id) {
                        dataMap[name][query.id] = response.data;
                    } else {
                        dataMap[name] = response.data;
                    }
                    return false;
                });
            })
        );

        setData(dataMap);
        setLoading(false);
        props.setWidgetLoaded(props.section.name + 'Section');
    };

    return (
        <>
            <div>
                {renderSubtitle('Evaluation Cycles and Response Rates')}
                {showTable(
                    <Collapsable
                        title="Evaluation Cycles and Response Rates"
                        collapsed={false}
                        content={
                            <DataCollectionInfoTable
                                data={loading ? {} : data.cycleInfo.flows.deploymentList}
                                groups={props.groups}
                            />
                        }
                    />,
                    loading
                )}
            </div>
            {respondentSelectorField.map(id => {
                return (
                    <div key={id}>
                        <div>
                            {renderSubtitle(`Data Collection Information for WFI-EZ  ${respondents[id].label} Form`)}
                            {showTable(
                                <RespondentDataCollectionInfo
                                    data={loading ? [] : data.responseInfo[id].flows.deploymentList}
                                    groups={props.groups}
                                    groupLevel={groupLevel}
                                    groupLevelMap={groupLevelMap}
                                />,
                                loading
                            )}
                        </div>
                        <div>
                            {renderSubtitle(`Youth Opt-out Reason for WFI-EZ  ${respondents[id].label} Form`)}
                            {showTable(
                                <OptOutReasonTable
                                    data={loading ? [] : data.responseInfo[id].flows.deploymentList}
                                    groups={props.groups}
                                    groupLevel={groupLevel}
                                    groupLevelMap={groupLevelMap}
                                />,
                                loading
                            )}
                        </div>
                        <div>
                            {showTable(
                                <Collapsable
                                    title={`${respondents[id].label} "Other" responses`}
                                    collapsed
                                    content={
                                        <OtherResponseTable
                                            data={loading ? [] : data.otherReasons[id].flows.deploymentList}
                                        />
                                    }
                                />,
                                loading
                            )}
                        </div>
                    </div>
                );
            })}
            <div>
                {renderSubtitle('Exclusion Information')}
                {showTable(
                    <ExclusionTable
                        data={loading ? [] : data.exclusion.flows}
                        respondentSelectorField={respondentSelectorField}
                    />,
                    loading
                )}
            </div>
        </>
    );
};

SampleResponseSummary.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    setWidgetLoaded: PropTypes.func,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(SampleResponseSummary);
