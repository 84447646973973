import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round, mean } from 'lodash';

const OverallScoreElementsTable = props => {
    const { data, subscales } = props;

    const getData = d => {
        const rows = [];
        const ccmap = {};
        for (const subscale of subscales) {
            for (const item of subscale.items) {
                for (const cc of data.flows[item].frequency) {
                    if (cc.value) {
                        if (ccmap[cc.value]) {
                            if (subscale.keyElement) {
                                ccmap[cc.value].keyElms.push(cc.avg);
                            }
                            ccmap[cc.value].overall.push(cc.avg);
                        } else if (subscale.keyElement) {
                            ccmap[cc.value] = { keyElms: [cc.avg], overall: [cc.avg] };
                        } else {
                            ccmap[cc.value] = { keyElms: [], overall: [cc.avg] };
                        }
                    }
                }
            }
        }
        for (const cc of Object.keys(ccmap)) {
            const row = { cc, careCoord: cc.split(', ')[0] };
            let allOverallNull = true;
            let allKeyElmsNull = true;
            for (const i of ccmap[cc].overall) {
                if (i !== null) {
                    allOverallNull = false;
                    break;
                }
            }
            for (const k of ccmap[cc].keyElms) {
                if (k !== null) {
                    allKeyElmsNull = false;
                    break;
                }
            }
            row.overall = allOverallNull ? null : mean(ccmap[cc].overall) * 100;
            row.keyElements = allKeyElmsNull ? null : mean(ccmap[cc].keyElms) * 100;
            rows.push(row);
        }
        return rows;
    };

    const getTotal = td => {
        let allOverallNull = true;
        let allKeyElmsNull = true;
        const overallTotal = [];
        const keyElmsTotal = [];
        for (const row of td) {
            if (row.overall !== null) {
                allOverallNull = false;
                overallTotal.push(row.overall);
            }
            if (row.keyElements !== null) {
                allKeyElmsNull = false;
                keyElmsTotal.push(row.keyElements);
            }
        }
        return {
            careCoord: 'Total: ',
            cc: 'totalRow',
            overall: allOverallNull ? '' : `${round(mean(overallTotal), 2)}%`,
            keyElements: allKeyElmsNull ? '' : `${round(mean(keyElmsTotal), 2)}%`,
        };
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        {
            name: 'overall',
            label: 'Overall Score',
            sort: true,
            render: value => (value !== null ? `${round(value, 2)}%` : 'N/A'),
        },
        {
            name: 'keyElements',
            label: 'Key Elements Score',
            sort: true,
            render: value => (value !== null ? `${round(value, 2)}%` : 'N/A'),
        },
    ];

    const tableData = getData(data);
    const totalRow = getTotal(tableData);

    let showNotice = false;
    for (const row of tableData) {
        if (!showNotice) {
            if (Object.keys(row).some(key => row[key] === null)) {
                showNotice = true;
            }
        }
    }

    return (
        <>
            <Table
                data={tableData}
                rowKey="cc"
                orderBy="careCoord"
                columns={cols}
                footer={totalRow}
                showPerPageOptions={false}
                isCsvDownload
            />
            {showNotice && (
                <p>
                    *All TOMs for this Care Coordinator were scored as &quot;N/A&quot;, thus a score can not be
                    calculated
                </p>
            )}
        </>
    );
};

OverallScoreElementsTable.propTypes = {
    data: PropTypes.object,
    subscales: PropTypes.array,
};

export default OverallScoreElementsTable;
