import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round, mean } from 'lodash';

const OverallKeyElementChart = props => {
    const { data, subscales } = props;

    const getData = () => {
        const rows = [];
        const ccmap = {};
        for (const subscale of subscales) {
            for (const item of subscale.items) {
                for (const cc of data.flows[item].frequency) {
                    if (cc.value) {
                        if (ccmap[cc.value]) {
                            if (subscale.keyElement) {
                                ccmap[cc.value].keyElms.push(cc.avg);
                            }
                            ccmap[cc.value].overall.push(cc.avg);
                        } else if (subscale.keyElement) {
                            ccmap[cc.value] = { keyElms: [cc.avg], overall: [cc.avg] };
                        } else {
                            ccmap[cc.value] = { keyElms: [], overall: [cc.avg] };
                        }
                    }
                }
            }
        }
        for (const cc of Object.keys(ccmap)) {
            const row = { cc, careCoord: cc.split(', ')[0] };
            let allOverallNull = true;
            let allKeyElmsNull = true;
            for (const i of ccmap[cc].overall) {
                if (i !== null) {
                    allOverallNull = false;
                    break;
                }
            }
            for (const k of ccmap[cc].keyElms) {
                if (k !== null) {
                    allKeyElmsNull = false;
                    break;
                }
            }
            row.overall = allOverallNull ? null : mean(ccmap[cc].overall) * 100;
            row.keyElements = allKeyElmsNull ? null : mean(ccmap[cc].keyElms) * 100;
            rows.push(row);
        }
        let allOverallNull = true;
        let allKeyElmsNull = true;
        const overallTotal = [];
        const keyElmsTotal = [];
        for (const row of rows) {
            if (row.overall !== null) {
                allOverallNull = false;
                overallTotal.push(row.overall);
            }
            if (row.keyElements !== null) {
                allKeyElmsNull = false;
                keyElmsTotal.push(row.keyElements);
            }
        }
        return [
            {
                group: 'Overall TOM 2.0 Score',
                average: allOverallNull ? 0 : round(mean(overallTotal), 2),
            },
            {
                group: 'Key Elements Score',
                average: allKeyElmsNull ? 0 : round(mean(keyElmsTotal), 2),
            },
        ];
    };

    const chartData = getData();

    if (!chartData.length > 0) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    const longestLabel = Math.max(
        ...chartData.map(o => {
            return o.group.length;
        })
    );

    return (
        <div style={{ width: '770px', height: `${300 + 6.5 * longestLabel}px` }}>
            <ResponsiveBar
                indexBy="group"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={['average']}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 6.5 * longestLabel, left: 50 }}
            />
        </div>
    );
};

OverallKeyElementChart.propTypes = {
    data: PropTypes.object,
    subscales: PropTypes.array,
};

export default OverallKeyElementChart;
