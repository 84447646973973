import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const EvaluationCycleTable = props => {
    const cols = [
        {
            name: 'cycleKey',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
            render: value => {
                return value.split('-')[0].split(', ')[0];
            },
        },
        { name: 'cycle', label: 'Evaluation Cycle', sort: true, filter: true },
        { name: 'sampleSize', sort: true, label: 'Sample Size' },
        {
            name: 'numCompleted',
            sort: true,
            label: 'Number of completed TOM 2.0 forms',
            tooltip:
                'The Number Completed is the total number of completed TOM 2.0s that were administered (i.e. the youth was enrolled in Wraparound, was not institutionalized, and accurate contact information was provided).',
        },
        {
            name: 'responseRate',
            label: 'Response Rate',
            sort: true,
            tooltip:
                'The response rate is calculated by dividing the number of completed TOM 2.0s by the number of forms that could have been administered (i.e. the youth was enrolled in Wraparound, was not institutionalized, and accurate contact information was provided).',
        },
    ];

    return (
        <Table
            data={props.data}
            rowKey="cycleKey"
            orderBy="cycleKey"
            columns={cols}
            showPerPageOptions={false}
            isCsvDownload
        />
    );
};

EvaluationCycleTable.propTypes = {
    data: PropTypes.object,
};

export default EvaluationCycleTable;
