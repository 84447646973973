import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round, mean } from 'lodash';

const ItemSubscaleTable = props => {
    const { data, items, respondent } = props;

    const cols = [
        {
            name: 'respondent',
            label: respondent.label,
            sort: true,
            filter: true,
        },
        ...items.map(i => ({
            name: i,
            label: i,
            tooltip: window.cccisd.appDefs.wfiQuestions[`${respondent.label}_${i}`].question,
            render: val =>
                val.count ? `${val.avg} (${round(val.avg / val.count, 2) * 100}%), N=${val.count}` : 'N/A*',
        })),
    ];

    const getData = () => {
        const rows = [];
        if (data.flows[respondent.label].frequency) {
            for (const cc of data.flows[respondent.label].frequency) {
                const itemVals = {};
                for (const item of items) {
                    itemVals[item] = { avg: cc[`${item}avg`], count: cc[`${item}count`] };
                }
                rows.push({ respondent: cc.value.split(', ')[0], ...itemVals });
            }
        }
        return rows;
    };

    const getTotal = rows => {
        const itemVals = {};
        for (const item of items) {
            const m = round(mean(rows.filter(r => r[item].count > 0).map(r => r[item].avg / r[item].count)) * 100, 2);
            itemVals[item] = m ? `${m}%` : 'N/A*';
        }
        return {
            respondent: 'Total: ',
            ...itemVals,
        };
    };

    const tableData = getData();
    const totalRow = getTotal(tableData);
    const showNotice = tableData.some(row => cols.some(col => row[col.name].avg === null));

    return (
        <>
            <Table
                rowKey="respondent"
                columns={cols}
                data={tableData}
                footer={totalRow}
                perPage={5000}
                perPageOptions={[250, 500, 1000, 5000]}
                isCsvDownload
                csvFilename="section-A-item-subscale"
            />
            {showNotice && (
                <p>
                    * Indicates all WFI-EZs for this item were scored as &quot;N/A&quot;, thus a summary score can not
                    be calculated
                </p>
            )}
        </>
    );
};

ItemSubscaleTable.propTypes = {
    data: PropTypes.object,
    items: PropTypes.array,
    respondent: PropTypes.object,
};

export default ItemSubscaleTable;
