import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const OtherTypeOfMeetingTable = props => {
    const { data } = props;

    const getData = d => {
        const rows = [];
        for (var res of d.flows.meeting.meetingTypeOther) {
            if (res.value) {
                rows.push({
                    response: res.value,
                    count: res.valueCount,
                });
            }
        }
        return rows;
    };

    const getTotalRow = td => {
        let total = 0;
        for (var row of td) {
            total += row.count;
        }
        return {
            response: 'Total:',
            count: total,
        };
    };

    const cols = [
        {
            name: 'response',
            label: 'Response',
        },
        {
            name: 'count',
            label: 'Count',
        },
    ];

    const tableData = getData(data);
    const totalRow = getTotalRow(tableData);

    return (
        <Table
            data={tableData}
            rowKey="response"
            columns={cols}
            showPerPageOptions={false}
            footer={totalRow}
            isCsvDownload
        />
    );
};

OtherTypeOfMeetingTable.propTypes = {
    data: PropTypes.object,
};

export default OtherTypeOfMeetingTable;
