import Builder, {
    initialValues as builderInitialValues,
} from 'js/vendor/reports/widgets/TablePicker/Builder';
import Player from 'js/vendor/reports/widgets/TablePicker/Player';

// queries
import dischargeSuccessRates from './graphql/dischargeSuccessRates.graphql';
import monthsInWraparound from './graphql/monthsInWraparound.graphql';
import transgenderIdentity from './graphql/transgenderIdentity.graphql';
import ageAtEnrollment from './graphql/ageAtEnrollment.graphql';
import genderIdentity from './graphql/genderIdentity.graphql';
import sexualOrientation from './graphql/sexualOrientation.graphql';
import race from './graphql/race.graphql';
import ethnicity from './graphql/ethnicity.graphql';
import legalCustodian from './graphql/legalCustodian.graphql';
import exitReason from './graphql/exitReason.graphql';

// components
import TeamMemberTypeCount from './custom/tables/TeamMemberTypeCount';
import CaregiverRelationshipCount from './custom/tables/CaregiverRelationshipCount';
import OtherGenderIdentity from './custom/tables/OtherGenderIdentity';
import OtherRace from './custom/tables/OtherRace';
import OtherSexualOrientation from './custom/tables/OtherSexualOrientation';
import OtherLegalCustodian from './custom/tables/OtherLegalCustodian';

import OtherExitReason from './custom/tables/OtherExitReason';
import MonthsInWraparound from './custom/tables/MonthsInWraparound';
import FundamentalsSection from './custom/sections/InvolvementSectionA';
import FidelitySection from './custom/sections/FidelitySectionB';
import SatisfactionSection from './custom/sections/SatisfactionSectionC';
import OutcomesSection from './custom/sections/OutcomesSectionD';
import SampleResponseSummary from './custom/sections/SampleResponseSummary';
import StrengthsSection from './custom/sections/StrengthsSectionB';

// helpers
import {
    demographicsVars,
    convertAgeAtEnrollment,
    convertMonthsInWraparound,
    convertDemographicsChart,
    convertDemographicsBarChart,
    convertExitReasonBarChart,
    monthsInWraparoundVars,
    pieLegend,
} from 'js/vendor/reports/helpers.js';

import { convertDischargeSuccessRate } from './convert.js';

import { siteTableFilter } from './queryFilters.js';

const respondentSummarySections = [
    {
        name: 'respondentSummary',
        label: 'Respondent Summary',
        tables: [
            {
                name: 'respondentSummary',
                label: 'Respondent Summary',
                tableFilter: siteTableFilter,
                bottomText:
                    'Note that the scores presented in this chart reflect the "mean of means," or average of each site\'s average score, rather than the mean for all individual records across sites. This is done so that a single site with many records does not overly influence the presentation of overall fidelity for a collaborator within multiple sites.',
            },
        ],
        charts: [],
    },
];

const overviewSections = [
    {
        name: 'caregiverRelationshipInfo',
        label: 'Caregiver Relationship Information',
        renderIf: filters => filters.respondentSelectorField.includes('3'),
        tables: [
            {
                name: 'caregiverRelationshipInfo',
                label: 'Caregiver Relationship Information',
                component: CaregiverRelationshipCount,
            },
        ],
        charts: [],
    },
    {
        name: 'teamMemberType',
        label: 'Team Member Type',
        renderIf: filters => filters.respondentSelectorField.includes('4'),
        tables: [
            {
                name: 'teamMemberType',
                label: 'Team Member Type',
                component: TeamMemberTypeCount,
            },
        ],
        charts: [],
    },
    {
        name: 'ageAtEnrollment',
        label: 'Age at Enrollment',
        tables: [
            {
                name: 'ageAtEnrollment',
                label: 'Age at Enrollment',
                tableFilter: siteTableFilter,
            },
        ],
        charts: [
            {
                name: 'ageAtEnrollment',
                position: 'top',
                type: 'bar',
                query: ageAtEnrollment,
                convertData: convertAgeAtEnrollment,
                variables: demographicsVars,
            },
        ],
    },

    // DEMOGRAPHICS SECTIONS

    {
        name: 'genderIdentity',
        label: 'Gender Identity',
        tables: [
            {
                name: 'genderIdentity',
                label: 'Gender Identity',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherGenderIdentity',
                label: 'Gender Identity "Other" Responses',
                component: OtherGenderIdentity,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'genderIdentity',
                position: 'top',
                type: 'pie',
                query: genderIdentity,
                convertData: convertDemographicsChart,
                variables: demographicsVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'transgenderIdentity',
        label: 'Transgender Identity',
        tables: [
            {
                name: 'transgenderIdentity',
                label: 'Transgender Identity',
                tableFilter: siteTableFilter,
            },
        ],
        charts: [
            {
                name: 'transgenderIdentity',
                position: 'top',
                type: 'pie',
                query: transgenderIdentity,
                convertData: convertDemographicsChart,
                variables: demographicsVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'sexualOrientation',
        label: 'Sexual Orientation',
        tables: [
            {
                name: 'sexualOrientation',
                label: 'Sexual Orientation',
                tableFilter: siteTableFilter,
            },
            {
                name: 'sexualOrientation2',
                label: 'Sexual Orientation (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherSexualOrientation',
                label: 'Sexual Orientation "Other" Responses',
                component: OtherSexualOrientation,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'sexualOrientation',
                position: 'top',
                type: 'pie',
                query: sexualOrientation,
                convertData: convertDemographicsChart,
                variables: demographicsVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'race',
        label: 'Race',
        tables: [
            {
                name: 'race',
                label: 'Race',
                tableFilter: siteTableFilter,
            },
            {
                name: 'race2',
                label: 'Race (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherRace',
                label: 'Race "Other" Responses',
                component: OtherRace,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'race',
                position: 'top',
                type: 'pie',
                query: race,
                convertData: convertDemographicsChart,
                variables: demographicsVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'ethnicity',
        label: 'Ethnicity',
        tables: [
            {
                name: 'ethnicity',
                label: 'Ethnicity',
                tableFilter: siteTableFilter,
            },
        ],
        charts: [
            {
                name: 'ethnicity',
                position: 'top',
                type: 'pie',
                query: ethnicity,
                convertData: convertDemographicsChart,
                variables: demographicsVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'legalCustodian',
        label: 'Legal Custodian',
        tables: [
            {
                name: 'legalCustodian',
                label: 'Legal Custodian',
                tableFilter: siteTableFilter,
            },
            {
                name: 'legalCustodian2',
                label: 'Legal Custodian (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherLegalCustodian',
                label: 'Legal Custodian "Other" Responses',
                component: OtherLegalCustodian,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'legalCustodian',
                position: 'top',
                type: 'bar',
                query: legalCustodian,
                convertData: convertDemographicsBarChart,
                variables: demographicsVars,
                margin: { top: 50, right: 130, bottom: 100, left: 60 },
                size: { width: '770px', height: '450px' },
                legend: pieLegend,
            },
        ],
    },
];

const enrollmentDischargeSections = [
    {
        name: 'monthsInWraparound',
        label: 'Months in Wraparound',
        tables: [
            {
                name: 'monthsInWraparound',
                label: 'Months in Wraparound',
                component: MonthsInWraparound,
                tableFilter: siteTableFilter,
            },
        ],
        charts: [
            {
                name: 'monthsInWraparound',
                position: 'top',
                type: 'bar',
                query: monthsInWraparound,
                convertData: convertMonthsInWraparound,
                variables: monthsInWraparoundVars,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'dischargeInformation',
        label: 'Discharge Information',
        tables: [
            {
                name: 'dischargeInformation',
                label: 'Discharge Information',
                tableFilter: siteTableFilter,
            },
        ],
    },
    {
        name: 'dischargeSuccessRates',
        label: 'Discharge Success Rates',
        tables: [],
        charts: [
            {
                label: 'Discharge Success Rate',
                name: 'dischargeSuccessRates',
                position: 'top',
                type: 'pie',
                query: dischargeSuccessRates,
                convertData: convertDischargeSuccessRate,
                variables: demographicsVars,
                altField: true,
                legend: pieLegend,
            },
        ],
    },
    {
        name: 'exitReason',
        label: 'Exit Reason',
        tables: [
            { name: 'exitReason', label: 'Exit Reason', tableFilter: siteTableFilter },
            {
                name: 'exitReason2',
                label: 'Exit Reason (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'exitReason3',
                label: 'Exit Reason (Continued)',
                tableFilter: siteTableFilter,
            },
            {
                name: 'otherExitReason',
                label: 'Exit Reason "Other" Responses',
                component: OtherExitReason,
                tableFilter: siteTableFilter,
                collapsed: true,
            },
        ],
        charts: [
            {
                name: 'exitReason',
                position: 'top',
                type: 'bar',
                query: exitReason,
                convertData: convertExitReasonBarChart,
                variables: demographicsVars,
                legend: pieLegend,
                margin: { top: 50, right: 130, bottom: 150, left: 60 },
                size: { width: '770px', height: '450px' },
            },
        ],
    },
];

const fundamentalsSections = [
    {
        name: 'itemResponses',
        label: 'Item Responses',
        component: FundamentalsSection,
    },
];
const fidelitySections = [
    {
        name: 'fidelity',
        label: 'Section B. Fidelity',
        component: FidelitySection,
    },
];

const strengthsSection = [
    {
        name: 'strengths',
        label: 'Section B. Strengths and Areas to Improve',
        component: StrengthsSection,
    },
];

const satisfactionSections = [
    {
        name: 'satisfactionScore',
        label: 'Total Satisfaction',
        component: SatisfactionSection,
        renderIf: filters =>
            filters.respondentSelectorField.includes('3') ||
            filters.respondentSelectorField.includes('5'),
    },
];
const outcomesSections = [
    {
        name: 'outcomesSection',
        label: 'Outcomes',
        component: OutcomesSection,
        renderIf: filters =>
            filters.respondentSelectorField.includes('3') ||
            filters.respondentSelectorField.includes('2'),
    },
];

const responseSummarySections = [
    {
        name: 'responseSummarySection',
        label: 'Sample and Response Summary',
        component: SampleResponseSummary,
    },
];

export const templateViews = [
    {
        handle: 'summary',
        label: 'Respondent Summary',
        sections: respondentSummarySections,
    },
    {
        handle: 'responseSummary',
        label: 'Sample and Response Summary',
        sections: responseSummarySections,
    },
    {
        handle: 'overview',
        label: 'Report Overview',
        sections: overviewSections,
    },
    {
        handle: 'enrollmentDischarge',
        label: 'Enrollment and Discharge Information',
        sections: enrollmentDischargeSections,
    },
    {
        handle: 'fundamentals',
        label: 'Section A. Wraparound Involvement',
        sections: fundamentalsSections,
    },
    {
        handle: 'fidelity',
        label: 'Section B. Fidelity',
        sections: fidelitySections,
    },
    {
        handle: 'strengths',
        label: 'Section B. Strengths and Areas to Improve',
        sections: strengthsSection,
    },
    {
        handle: 'satisfaction',
        label: 'Section C. Satisfaction',
        sections: satisfactionSections,
    },
    {
        handle: 'outcomes',
        label: 'Section D. Outcomes',
        sections: outcomesSections,
    },
];

export default templateViews.map(s => ({
    handle: s.handle,
    label: s.label,
    builderInitialValues,
    builder: Builder,
    player: Player,
}));
