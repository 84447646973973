import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { mean, round } from 'lodash';

const ItemRespondentTable = props => {
    const { groupLevel, data, respondent, items } = props;
    const cols = [
        {
            name: 'label',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        ...items.map(i => ({
            name: i,
            label: i,
            tooltip: window.cccisd.appDefs.wfiQuestions[`${respondent.label.replace(/\s/g, '')}_${i}`].question,
        })),
    ];

    const getTotal = d => {
        const tr = { label: 'Total:' };
        for (const row of d) {
            for (const q of Object.keys(row).filter(r => r !== 'label')) {
                if (tr[q]) {
                    tr[q].push(parseFloat(row[q].split(',')[0], 10));
                } else {
                    tr[q] = [parseFloat(row[q].split(',')[0], 10)];
                }
            }
        }
        for (const k of Object.keys(tr).filter(r => r !== 'label')) {
            tr[k] = `${round(mean(tr[k]), 2)}`;
        }
        return tr;
    };

    const totalRow = getTotal(data);

    return (
        <Table
            rowKey="label"
            columns={cols}
            data={data}
            footer={totalRow}
            isCsvDownload
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            csvFilename="section-C-satisfaction-items"
        />
    );
};

ItemRespondentTable.propTypes = {
    data: PropTypes.array,
    respondent: PropTypes.object,
    items: PropTypes.array,
    groupLevel: PropTypes.string,
};

export default ItemRespondentTable;
