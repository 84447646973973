import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round, mean } from 'lodash';

const SubscaleChart = props => {
    const { data, subscales } = props;

    const getData = d => {
        const rows = [];
        const ccmap = {};
        for (const subscale of subscales) {
            for (const item of subscale.items) {
                for (const cc of d.flows[item].frequency) {
                    if (cc.value) {
                        if (ccmap[cc.value]) {
                            if (ccmap[cc.value][subscale.name]) {
                                ccmap[cc.value][subscale.name].push(cc.avg);
                            } else {
                                ccmap[cc.value][subscale.name] = [cc.avg];
                            }
                        } else {
                            ccmap[cc.value] = { [subscale.name]: [cc.avg] };
                        }
                    }
                }
            }
        }
        for (const cc of Object.keys(ccmap)) {
            const row = {
                cc,
                careCoord: cc.split(', ')[0],
            };
            for (const sub of Object.keys(ccmap[cc])) {
                let allNull = true;
                for (const i of ccmap[cc][sub]) {
                    if (i !== null) {
                        allNull = false;
                        break;
                    }
                }
                row[sub] = allNull ? null : mean(ccmap[cc][sub]) * 100;
            }
            rows.push(row);
        }

        let total = { careCoord: 'Total: ', cc: 'totalRow' };
        for (const key of Object.keys(rows[0])) {
            if (!['careCoord', 'cc'].includes(key)) {
                const keyTotal = [];
                let allNull = true;
                for (const row of rows) {
                    if (row[key] !== null) {
                        allNull = false;
                        keyTotal.push(row[key]);
                    }
                }
                if (allNull) {
                    total[key] = '';
                } else {
                    total[key] = `${round(mean(keyTotal), 2)}%`;
                }
            }
        }
        const bars = [];
        for (const key of Object.keys(total)) {
            if (!['careCoord', 'cc'].includes(key)) {
                bars.push({
                    subscale: subscales.find(s => s.name === key).label,
                    score: parseFloat(total[key].replace('%', ''), 10),
                });
            }
        }
        return bars;
    };

    const chartData = getData(data);

    if (!chartData.length > 0) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    const longestLabel = Math.max(
        ...chartData.map(o => {
            return o.subscale.length;
        })
    );

    return (
        <div style={{ width: '770px', height: `${300 + 6.5 * longestLabel}px` }}>
            <ResponsiveBar
                indexBy="subscale"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={['score']}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 6.5 * longestLabel, left: 50 }}
            />
        </div>
    );
};

SubscaleChart.propTypes = {
    data: PropTypes.object,
    subscales: PropTypes.array,
};

export default SubscaleChart;
