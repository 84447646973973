import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const OptOutReasonTable = props => {
    const { data } = props;

    return (
        <Table
            data={data.otherReasons?.map((other, i) => ({ other, i }))}
            rowKey="i"
            columns={[{ name: 'other', label: 'Text Response' }]}
            showPerPageOptions={false}
            isCsvDownload
        />
    );
};

OptOutReasonTable.propTypes = {
    data: PropTypes.object,
};

export default OptOutReasonTable;
