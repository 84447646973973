import React, { useState, useEffect } from 'react';
import { round } from 'lodash';
import PropTypes from 'prop-types';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import EvaluationCycleTable from './tables/EvaluationCycleTable';
import DataCollectionInfoTable from './tables/DataCollectionInfoTable';
import OtherResponseTable from './tables/OtherResponseTable';
import OptOutReasonTable from './tables/OptOutReasonTable';
import DuplicateInfoTable from './tables/DuplicateInfoTable';
import ExclusionInfoTable from './tables/ExclusionInfoTable';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';

import query from './query.graphql';

import { showTable, renderSubtitle } from 'js/vendor/reports/helpers.js';

function SampleResponseSummary(props) {
    const [evalCycleTableRows, setEvalCycleTableRows] = useState(null);
    const [formattedData, setFormattedData] = useState(null);
    const [duplicateData, setDuplicateData] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getSectionData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getSectionData = async () => {
        const variables = {
            deploymentIds: props.filters.evalCycleSelectorField || [],
            // ccList: props.filters.careCoordSelectorField.map(cc => cc.split('-')[0]),
            ccList: props.filters.careCoordSelectorField.map(cc => {
                const pawnDashIndex = cc.lastIndexOf('-');
                return cc.substring(0, pawnDashIndex);
            }),
        };
        const response = await taskMasterClient.query({
            query,
            variables,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        const evalCycleRows = getEvalCycleTableRows(response.data);
        const formatted = formatData(response.data, evalCycleRows);
        setFormattedData(formatted);
        setEvalCycleTableRows(evalCycleRows);
        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };

    const formatData = (data, evalCycleRows) => {
        const keys = [
            'discharged',
            'incomplete',
            'institution',
            'invalid',
            'invalidFMA',
            'invalidNC',
            'invalidOB',
            'invalidPN',
            'excluded',
            'noConsent',
            'numCompleted',
            'optedOut',
            'other',
            'otherReasons', // special case
            'respondentList', // special case
            'respondentCount',
            'unableToAttend',
        ];
        const ccoDataMap = {};
        const wrapIdMap = {};

        const ccList = props.filters.careCoordSelectorField.map(cc => {
            const pawnDashIndex = cc.lastIndexOf('-');
            return cc.substring(0, pawnDashIndex);
        });

        for (const d of data.flows.deploymentList) {
            const deploymentCCs = [];
            const deploymentIdsIncluded = evalCycleRows.map(r => parseInt(r.deploymentId, 10));
            for (const key of keys) {
                if (key === 'respondentList' && deploymentIdsIncluded.includes(d.deploymentId)) {
                    if (d.respondentList.length) {
                        for (const respondent of d.respondentList) {
                            if (wrapIdMap[respondent.pawn.fields.wrapId]) {
                                wrapIdMap[respondent.pawn.fields.wrapId].deployments.push(d.label);
                            } else {
                                wrapIdMap[respondent.pawn.fields.wrapId] = {
                                    deployments: [d.label],
                                    site: d.siteName,
                                };
                            }
                        }
                    }
                    continue;
                }
                if (key === 'otherReasons') {
                    if (d.otherReasons.length > 0) {
                        ccoDataMap.otherReasons = ccoDataMap.otherReasons || [];
                        ccoDataMap.otherReasons.push(
                            ...d.otherReasons.map(o => o.devTagsLists.OptOutOther).reduce((a, c) => [...a, ...c], [])
                        );
                    }
                    continue;
                }
                if (d[key].frequency) {
                    for (const freq of d[key].frequency) {
                        if (freq.value && ccList.some(cc => cc === freq.value)) {
                            deploymentCCs.push(freq.value);
                            ccoDataMap[freq.value] = ccoDataMap[freq.value] || {
                                deploymentIds: [d.deploymentId],
                            };
                            ccoDataMap[freq.value][key] = (ccoDataMap[freq.value][key] || 0) + freq.valueCount;
                            if (!ccoDataMap[freq.value].deploymentIds.includes(d.deploymentId)) {
                                ccoDataMap[freq.value].deploymentIds.push(d.deploymentId);
                            }
                        }
                    }
                }
            }
        }
        let duplicateRows = [];
        for (const wrapId of Object.keys(wrapIdMap)) {
            if (wrapIdMap[wrapId].deployments.length > 1) {
                duplicateRows.push({
                    wrapId,
                    site: wrapIdMap[wrapId].site,
                    cycles: [...new Set(wrapIdMap[wrapId].deployments)].join(', '),
                });
            }
        }
        setDuplicateData(duplicateRows);
        return ccoDataMap;
    };

    const getEvalCycleTableRows = data => {
        let rows = [];
        const deploymentCCMap = {};
        for (var cycle of data.flows.deploymentList) {
            const deploymentCCs = {};
            for (var key of ['respondentCount', 'invalid', 'numCompleted']) {
                if (cycle[key].frequency) {
                    for (const freq of cycle[key].frequency) {
                        deploymentCCs[freq.value] = deploymentCCs[freq.value] || {};
                        deploymentCCs[freq.value][key] = (deploymentCCs[freq.value][key] || 0) + freq.valueCount || 0;
                    }
                }
            }
            if (deploymentCCs) {
                deploymentCCMap[cycle.deploymentId] = { label: cycle.label, deploymentCCs };
            }
        }
        for (const deploymentId of Object.keys(deploymentCCMap)) {
            for (const cc of Object.keys(deploymentCCMap[deploymentId].deploymentCCs)) {
                const { numCompleted, invalid, respondentCount } = deploymentCCMap[deploymentId].deploymentCCs[cc];
                const responseRate = (numCompleted || 0) - (invalid || 0);
                const responseRateCount = (respondentCount || 0) - (invalid || 0);
                rows.push({
                    cycleKey: `${cc}-${deploymentId}`,
                    deploymentId,
                    cycle: deploymentCCMap[deploymentId].label,
                    sampleSize: respondentCount,
                    numCompleted: (numCompleted || 0) - (invalid || 0),
                    responseRate: `${round((responseRate / responseRateCount) * 100, 2) || 0}%`,
                });
            }
        }

        return rows;
    };

    return (
        <>
            {renderSubtitle('Evaluation Cycles and Response Rates')}
            {showTable(<EvaluationCycleTable data={evalCycleTableRows} />, loading)}
            {renderSubtitle(`Data Collection Information for TOM 2.0 Form`)}
            {showTable(<DataCollectionInfoTable data={formattedData} />, loading)}
            {renderSubtitle(`Opt-out Reason for TOM 2.0 Form`)}
            {showTable(<OptOutReasonTable data={formattedData} />, loading)}
            {showTable(
                <Collapsable
                    title={`"Other" responses`}
                    collapsed
                    content={<OtherResponseTable data={formattedData} />}
                />,
                loading
            )}
            {renderSubtitle('Duplicate Information')}
            {showTable(<DuplicateInfoTable data={duplicateData} />, loading)}
            {renderSubtitle('Exclusion Information')}
            {showTable(
                <ExclusionInfoTable data={formattedData} ccFilter={props.filters.careCoordSelectorField} />,
                loading
            )}
        </>
    );
}

SampleResponseSummary.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded })(SampleResponseSummary);
