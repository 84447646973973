import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';
import { hiddenChart } from 'js/vendor/reports/helpers.js';

const StrengthsTable = props => {
    const { wfiQuestions } = window.cccisd.appDefs;
    const { respondent, data, subscales } = props;

    const getData = () => {
        let rows = [];
        if (data.flows[respondent.label].frequency)
            for (const cc of data.flows[respondent.label].frequency) {
                if (cc.value) {
                    for (const subscale of subscales) {
                        if (subscale.items) {
                            for (const itm of subscale.items) {
                                const item = itm.replace('*', '');
                                const { question, WS_Mean, WS_SD } = wfiQuestions[`${respondent.label}_${item}`];
                                rows.push({
                                    careCoord: cc.value.split(', ')[0],
                                    cc: cc.value,
                                    item,
                                    mean: round(cc[item], 2),
                                    itemDesc: question,
                                    wrapMean: parseFloat(WS_Mean, 10),
                                    wrapSD: parseFloat(WS_SD, 10),
                                });
                            }
                        }
                    }
                }
            }
        return rows.filter(row => row.mean > row.wrapMean + 0.4);
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        {
            name: 'item',
            label: 'Item Number',
        },
        {
            name: 'itemDesc',
            label: 'Item Description',
        },
        {
            name: 'mean',
            label: `${respondent.label} Mean`,
        },
        {
            name: 'wrapSD',
            label: `${respondent.label} Wrapstat Mean (SD)`,
            render: (value, row) => `${row.wrapMean} (${value})`,
        },
    ];

    const tableData = getData();

    if (tableData.length > 0) {
        return (
            <Table
                data={tableData}
                rowKey="cc"
                orderBy="item"
                columns={cols}
                perPage={5000}
                perPageOptions={[250, 500, 1000, 5000]}
                isCsvDownload
                csvFilename="strengths"
            />
        );
    }
    return hiddenChart('No fidelity items scored 0.4 or above the Wrapstat Mean.');
};

StrengthsTable.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.object,
    subscales: PropTypes.array,
};

export default StrengthsTable;
