import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const ItemQuestionMeans = props => {
    const { tom20Questions } = window.cccisd.appDefs;
    const { items } = props;

    const getData = () => {
        let rows = [];
        for (var item of items) {
            const itemKey = item.split('').reverse().join('');

            rows.push({
                item: itemKey,
                WS_Mean: tom20Questions[itemKey.toLowerCase()].WS_Mean,
                WS_SD: tom20Questions[itemKey.toLowerCase()].WS_SD,
            });
        }
        return rows;
    };

    const cols = [
        { name: 'item', label: 'Item' },
        { name: 'WS_Mean', label: `Wrapstat Mean` },
        {
            name: 'WS_SD',
            label: `Wrapstat Standard Deviation`,
        },
    ];
    return (
        <Table
            isCsvDownload
            columns={cols}
            data={getData()}
            rowKey="item"
            orderBy="item"
            showPerPageOptions={false}
        />
    );
};

ItemQuestionMeans.propTypes = {
    level: PropTypes.string,
    items: PropTypes.array,
};

export default ItemQuestionMeans;
