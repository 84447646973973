import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round, mean } from 'lodash';

const ItemQuestionTable = props => {
    const { data, groupLevelMap, groupLevel, respondent, subscale } = props;
    const { wfiQuestions } = window.cccisd.appDefs;

    const getData = () => {
        let rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            var row = { group };
            var itemTotals = {};
            var groupItemNs = {};
            for (var siteId of groupLevelMap[group]) {
                const site = data.groups.siteList.find(s => siteId === s.group.groupId);
                if (site) {
                    for (var item of subscale.items) {
                        const siteN = site.descendantRoles[respondent.name]['N' + item];
                        if (siteN) {
                            if (groupItemNs[item]) {
                                groupItemNs[item] += siteN;
                            } else {
                                groupItemNs[item] = siteN;
                            }
                        }
                        const siteItemMean = site.descendantRoles[respondent.name][item];
                        if (siteItemMean) {
                            if (itemTotals[item]) {
                                itemTotals[item] += siteItemMean;
                            } else {
                                itemTotals[item] = siteItemMean;
                            }
                        }
                    }
                }
            }
            let rowHasData = false;
            for (var i of subscale.items) {
                const countYes = itemTotals[i] || 0;
                const percentYes = groupItemNs[i] ? round(countYes / groupItemNs[i], 2) : 0;

                if (groupItemNs[i] > 0) {
                    rowHasData = true;
                }

                row[i] = `${percentYes}, N=${groupItemNs[i] || 0}`;
            }
            if (rowHasData) {
                rows.push(row);
            }
        }
        return rows;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        ...subscale.items.map(i => ({
            name: i,
            label: i,
            tooltip: wfiQuestions[`${props.respondent.label.replace(' ', '')}_${i}`].question,
        })),
    ];

    const getTotal = d => {
        const tr = { group: 'Total:' };
        for (const row of d) {
            for (const q of Object.keys(row).filter(r => r !== 'group')) {
                if (tr[q]) {
                    tr[q].push(parseFloat(row[q].split(',')[0], 10));
                } else {
                    tr[q] = [parseFloat(row[q].split(',')[0], 10)];
                }
            }
        }
        for (const k of Object.keys(tr).filter(r => r !== 'group')) {
            tr[k] = `${round(mean(tr[k]), 2)}`;
        }
        return tr;
    };

    const tableData = getData();
    const totalRow = getTotal(tableData);

    return (
        <Table
            data={tableData}
            rowKey="group"
            columns={cols}
            footer={totalRow}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            csvFilename="section-B-Fidelity-items"
            isCsvDownload
        />
    );
};

ItemQuestionTable.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.object,
    subscale: PropTypes.object,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default ItemQuestionTable;
