import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { client } from 'cccisd-apollo';
import Skeleton from 'react-loading-skeleton';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
// import { getTeamMemberFilter } from 'js/vendor/reports/reportTemplates/WFI-EZ/Youth/queryFilters.js';
import { setWidgetLoaded } from 'js/reducers/report.js';

const DevTagCountTable = props => {
    const [data, setData] = useState([]);
    const [otherData, setOtherData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const response = await client.query({
            query: props.query,
            variables: {
                pawnIds: props.youthPawnIds,
                deploymentIds: props.filters.evalCycleSelectorField || [],
                // filter: getTeamMemberFilter(props.filters.teamMemberSelectorField),
                siteIds: props.filters.siteSelectorField || [],
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        parseData(response.data);
        setLoading(false);
        props.setWidgetLoaded(props.table.name + 'Table');
    };

    const parseData = res => {
        let rows = [];
        let otherRows = [];
        let total = 0;
        let relationshipTypesCount = {};
        let otherResponseCount = {};
        // count all the types
        for (var youth of res.roles.youthList) {
            if (youth.assignmentProgressList[0]) {
                const relation = youth.assignmentProgressList[0].devTags.WfiCaregiverRelationship;
                const otherResponse =
                    youth.assignmentProgressList[0].devTags.WfiCaregiverOtherRelationship;
                if (relation) {
                    total += 1;
                    if (relationshipTypesCount[relation]) {
                        relationshipTypesCount[relation] += 1;
                    } else {
                        relationshipTypesCount[relation] = 1;
                    }
                }
                if (otherResponse) {
                    if (otherResponseCount[otherResponse]) {
                        otherResponseCount[otherResponse] += 1;
                    } else {
                        otherResponseCount[otherResponse] = 1;
                    }
                }
            }
        }
        for (var response of Object.keys(relationshipTypesCount)) {
            rows.push({ value: response, count: relationshipTypesCount[response] });
        }
        rows.push({ value: 'Total:', count: total });
        for (var otherRes of Object.keys(otherResponseCount)) {
            otherRows.push({ value: otherRes, count: otherResponseCount[otherRes] });
        }
        setData(rows);
        setOtherData(otherRows);
    };

    const getColumns = () => {
        return [
            {
                name: 'value',
                label: props.label,
                sort: true,
                filter: true,
            },
            { name: 'count', label: 'Count', sort: true, filter: true },
        ];
    };

    if (data.length === 0 && !loading) {
        return <h4>There were no other responses for youth counted in this report</h4>;
    }

    let foot = data[data.length - 1];
    return (
        <>
            {loading ? (
                <Skeleton count={15} />
            ) : (
                <Table
                    isCsvDownload
                    columns={getColumns()}
                    data={data.slice(0, data.length - 1)}
                    rowKey="value"
                    orderBy="value"
                    showPerPageOptions={false}
                    footer={foot}
                />
            )}
            {loading ? (
                <Skeleton count={15} />
            ) : (
                <Collapsable
                    collapsed
                    title='Caregiver "Other" Responses'
                    content={
                        loading ? (
                            <Skeleton count={15} />
                        ) : (
                            <Table
                                isCsvDownload
                                columns={getColumns()}
                                data={otherData}
                                rowKey="value"
                                orderBy="value"
                                showPerPageOptions={false}
                            />
                        )
                    }
                />
            )}
        </>
    );
};

DevTagCountTable.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    devTag: PropTypes.string,
    table: PropTypes.object,
    query: PropTypes.object,
    label: PropTypes.string,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    setWidgetLoaded: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(DevTagCountTable);
