import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const ItemMeanTable = props => {
    const { wfiQuestions } = window.cccisd.appDefs;
    const { items, respondent } = props;

    const getData = () => {
        let rows = [];
        for (var item of items) {
            const i = wfiQuestions[`${respondent.label}_${item}`];
            if (i && i.Item_Upper && i.Item_Lower && i.WS_Mean && i.WS_SD) {
                rows.push({
                    item,
                    Item_Upper: i.Item_Upper,
                    Item_Lower: i.Item_Lower,
                    WS_Mean: i.WS_Mean,
                    WS_SD: i.WS_SD,
                });
            }
        }
        return rows;
    };

    const cols = [
        { name: 'item', label: 'Item' },
        { name: 'Item_Upper', label: `Upper` },
        { name: 'Item_Lower', label: `Lower` },
        { name: 'WS_Mean', label: `Wrapstat Mean` },
        {
            name: 'WS_SD',
            label: `Wrapstat Standard Deviation`,
        },
    ];
    return (
        <Table
            columns={cols}
            data={getData()}
            rowKey="item"
            orderBy="item"
            perPage={5000}
            perPageOptions={[25, 50, 100, 500]}
            isCsvDownload
            csvFilename="section-D-item-means"
        />
    );
};

ItemMeanTable.propTypes = {
    items: PropTypes.array,
    respondent: PropTypes.object,
};

export default ItemMeanTable;
