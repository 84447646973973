import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round, mean } from 'lodash';

const ItemRespondentTable = props => {
    const { data, items, respondent } = props;

    const cols = [
        {
            name: 'respondent',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        ...items.map(i => ({
            name: i,
            label: i,
            tooltip: window.cccisd.appDefs.wfiQuestions[`${respondent.label}_${i}`].question,
            render: val => (val.count ? `${val.avg}, N=${val.count}` : 'N/A'),
        })),
    ];

    const getData = () => {
        const rows = [];
        if (data.flows[respondent.label].frequency) {
            for (const cc of data.flows[respondent.label].frequency) {
                const itemVals = {};
                for (const item of items) {
                    itemVals[item] = { avg: round(cc[`${item}avg`], 2), count: cc[`${item}count`] };
                }
                rows.push({ respondent: cc.value, ...itemVals });
            }
        }
        return rows;
    };

    const getTotal = rows => {
        const itemVals = {};
        for (const item of items) {
            const m = `${round(mean(rows.filter(r => r[item].count > 0).map(r => r[item].avg)), 2)}`;
            itemVals[item] = Number.isNaN(Number(m)) ? '' : m;
            // itemVals[item] = `${round(mean(rows.filter(r => r[item].count > 0).map(r => r[item].avg)), 2)}`;
        }
        return {
            respondent: 'Total: ',
            ...itemVals,
        };
    };

    const tableData = getData();
    const totalRow = getTotal(tableData);

    return (
        <Table
            rowKey="respondent"
            columns={cols}
            data={tableData}
            footer={totalRow}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="section-c-items"
        />
    );
};

ItemRespondentTable.propTypes = {
    data: PropTypes.object,
    items: PropTypes.array,
    respondent: PropTypes.object,
};

export default ItemRespondentTable;
