import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const QuestionTable = props => {
    const { items } = props;
    const cols = [
        {
            name: 'item',
            label: 'Item',
        },
        {
            name: 'question',
            label: 'Question',
        },
    ];

    const getData = () => {
        const d = [];
        for (var item of items) {
            const itemKey = item
                .split('')
                .reverse()
                .join('');
            const { question } = window.cccisd.appDefs.tom20Questions[itemKey.toLowerCase()];
            d.push({ item: itemKey, question });
        }
        return d;
    };

    return (
        <Table
            rowKey="item"
            columns={cols}
            data={getData()}
            isCsvDownload
            showPerPageOptions={false}
        />
    );
};

QuestionTable.propTypes = {
    items: PropTypes.array,
};

export default QuestionTable;
