import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import widgets, { templateViews } from '../widgets.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import filterFields from '../filterFields.js';
import { client, taskMasterClient } from 'cccisd-apollo';
import youthCountQuery from '../graphql/youthCount.graphql';
import moment from 'moment';
import { respondents } from 'js/vendor/reports/filters/RespondentSelector';
import { teamtypes } from 'js/vendor/reports/filters/TeamMemberSelector';
import { connect } from 'react-redux';
import { resetWidgetsLoaded, setYouthPawnIds } from 'js/reducers/report.js';
import { groupChildren, getGroupCount } from 'js/vendor/reports/helpers.js';
import TemplateWrapper from 'js/vendor/reports/components/TemplateWrapper';
import hash from 'object-hash';

const Component = props => {
    const {
        siteSelectorField,
        dateSelectorFrom,
        dateSelectorTo,
        showHideView,
        respondentSelectorField,
        teamMemberSelectorField,
        evalCycleSelectorField,
    } = props.filters;

    const loading = 'Loading...';

    const { settings, groups } = props;
    const [youthCount, setYouthCount] = useState(loading);

    const filtersChanged = hash(props.initialFilterValues) !== hash(props.filters);

    const getData = async () => {
        await getYouthCount();
    };

    useEffect(() => {
        client.cancelQueries('report');
        taskMasterClient.cancelQueries('report');
        props.resetWidgetsLoaded();
        if (siteSelectorField && filtersChanged) {
            getData();
        }
    }, [
        showHideView,
        siteSelectorField,
        dateSelectorFrom,
        dateSelectorTo,
        teamMemberSelectorField,
        evalCycleSelectorField,
    ]);

    const headerGroups = settings.groupLevel
        ? groupChildren[settings.groupLevel].map(g => ({
              title: `Number of ${g.label}`,
              value: getGroupCount(g.name, siteSelectorField, groups),
              showIf: true,
          }))
        : [{ title: 'Groups', value: 'Select Group Level from Report Settings', showIf: true }];

    const sum = [
        ...headerGroups,
        {
            title: 'Date Range',
            value:
                dateSelectorFrom &&
                dateSelectorTo &&
                moment(dateSelectorFrom).format('MM/DD/YYYY') +
                    ' - ' +
                    moment(dateSelectorTo).format('MM/DD/YYYY'),
            showIf:
                settings.filterFields.includes('dateSelectorFrom') &&
                settings.filterFields.includes('dateSelectorTo'),
        },
        {
            title: 'Respondent Types',
            value:
                respondentSelectorField && respondents.length === respondentSelectorField.length
                    ? 'All'
                    : respondents
                          .filter(r => respondentSelectorField.includes(r.value))
                          .map(r => r.label)
                          .join(', '),
            showIf: settings.filterFields.includes('respondentSelectorField'),
        },
        {
            title: 'Team Member Types',
            value:
                teamMemberSelectorField && teamtypes.length === teamMemberSelectorField.length
                    ? 'All'
                    : teamtypes
                          .filter(r => teamMemberSelectorField.includes(r.value))
                          .map(r => r.label)
                          .join(', '),
            showIf:
                settings.filterFields.includes('respondentSelectorField') &&
                respondentSelectorField.includes('4'),
        },
        {
            title: 'Evaluation Cycles',
            value: evalCycleSelectorField && evalCycleSelectorField.length,
            showIf: settings.filterFields.includes('evalCycleSelectorField'),
        },
    ];
    // add the non-filter related summary items
    sum.push({
        title: 'Number of Youth',
        value: youthCount,
        showIf: true,
    });

    const getYouthCount = async siteIds => {
        setYouthCount(loading);
        const response = await client.query({
            query: youthCountQuery,
            variables: {
                deploymentIds: evalCycleSelectorField,
                teamMemberTypes: teamMemberSelectorField,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        props.setYouthPawnIds(response.data.roles.youthList.map(y => y.pawn.pawnId));
        setYouthCount(response.data.roles.youthList.length);
    };

    return (
        <TemplateWrapper
            settings={settings}
            summary={sum}
            initialFilterValues={props.initialFilterValues}
            filters={props.filters}
            widgetsConfig={widgets}
            widgetComponents={props.widgets}
            templateViews={templateViews}
            alwaysShownWidgets={['summary']}
            shouldShowWidget={w =>
                settings[w.handle] && showHideView.includes(w.label) && youthCount !== loading
            }
            showWhen={youthCount !== loading}
            showError={youthCount === 0 || evalCycleSelectorField.length === 0}
            errorText="There are zero youth for this filter selection. Try selecting different options."
        />
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    groups: PropTypes.array,
    defaultCycles: PropTypes.array,
    defaultSites: PropTypes.array,
    initialFilterValues: PropTypes.object,
    // redux
    resetWidgetsLoaded: PropTypes.func,
    setYouthPawnIds: PropTypes.func,
};

const mapStateToProps = state => ({});
const Connected = connect(mapStateToProps, { resetWidgetsLoaded, setYouthPawnIds })(Component);

export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        const defaults = filterFields.map(f => ({
            ...f,
            initialValue: props.initialFilterValues[f.name],
        }));
        return defaults;
    },
})(Connected);

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
