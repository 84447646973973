export const siteTableFilter = (filters, groupLevel) => {
    const { siteSelectorField } = filters;
    if (groupLevel === 'site') {
        return {
            AND: [
                {
                    in: {
                        field: 'group.groupId',
                        int: siteSelectorField ? siteSelectorField : [],
                    },
                },
            ],
        };
    }

    return {
        AND: [
            {
                in: {
                    field: 'descendantGroups.site.group.groupId',
                    int: siteSelectorField ? siteSelectorField : [],
                },
            },
        ],
    };
};

export const getTeamMemberFilter = (tmField, extract, alt) => {
    const field = alt
        ? 'pawn.fields.relationshipToYouth'
        : 'descendantRoles.youth.fields.relationshipToYouth';
    let filter = {
        in: {
            field,
            string: tmField ? tmField : [],
        },
    };
    if (tmField.includes(null)) {
        filter = {
            OR: [
                {
                    isNull: {
                        field,
                    },
                },
                {
                    in: {
                        field,
                        string: tmField ? tmField : [],
                    },
                },
            ],
        };
    }
    if (extract) {
        return [filter];
    }
    return filter;
};
