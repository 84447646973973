import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const MeetingFormatTable = props => {
    const { groupLevel, groupLevelMap, data } = props;

    const labels = {
        2: {
            label: 'Via Telehealth',
            name: 'telehealth',
        },
        1: {
            label: 'In Person',
            name: 'person',
        },
    };

    const getData = d => {
        const rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            const youthInGroup = d.flows.assignmentProgressList.filter(y =>
                groupLevelMap[group].includes(y.pawn.parentGroup.site.group.groupId)
            );
            let groupTotal = { telehealth: 0, person: 0, groupSum: 0 };
            for (var youth of youthInGroup) {
                const format = youth.devTags.MeetingFormat;
                if (format) {
                    groupTotal[labels[format].name] += 1;
                    groupTotal.groupSum += 1;
                }
            }
            rows.push({
                group,
                telehealth: groupTotal.telehealth,
                person: groupTotal.person,
                groupSum: groupTotal.groupSum,
            });
        }
        return rows;
    };

    const getTotalRow = td => {
        let total = { telehealth: 0, person: 0, groupSum: 0 };
        for (var row of td) {
            total.telehealth += row.telehealth;
            total.person += row.person;
            total.groupSum += row.groupSum;
        }
        return {
            group: 'Total:',
            telehealth: `${total.telehealth} (${round((total.telehealth / total.groupSum) * 100, 2)}%)`,
            person: `${total.person} (${round((total.person / total.groupSum) * 100, 2)}%)`,
        };
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        ...Object.keys(labels).map(n => ({
            name: labels[n].name,
            label: labels[n].label,
            render(value, row) {
                return value > 0 ? `${value} (${round((value / row.groupSum) * 100, 2)}%)` : 0;
            },
        })),
    ];

    const tableData = getData(data);
    const totalRow = getTotalRow(tableData);

    return (
        <Table
            data={tableData}
            rowKey="group"
            columns={cols}
            showPerPageOptions={false}
            footer={totalRow}
            isCsvDownload
        />
    );
};

MeetingFormatTable.propTypes = {
    data: PropTypes.object,
    groupLevelMap: PropTypes.object,
    groupLevel: PropTypes.string,
};

export default MeetingFormatTable;
