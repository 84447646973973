import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const MeetingFormatTable = props => {
    const { data } = props;

    const labels = {
        1: {
            label: 'Via Telehealth',
            name: 'telehealth',
        },
        2: {
            label: 'In Person',
            name: 'inperson',
        },
    };

    const getData = d => {
        const rows = [];
        for (var c of Object.keys(d)) {
            const cc = props.data[c];
            const row = {
                cc: c,
                careCoord: c.split(',')[0],
                inperson: 0,
                telehealth: 0,
                ccTotal: 0,
            };
            for (var e of ['telehealth', 'inperson']) {
                if (cc[e]) {
                    row[e] += cc[e];
                    row.ccTotal += cc[e];
                }
            }
            rows.push(row);
        }
        return rows;
    };

    const getTotalRow = td => {
        let total = { telehealth: 0, inperson: 0, ccTotal: 0 };
        for (var row of td) {
            total.telehealth += row.telehealth;
            total.inperson += row.inperson;
            total.ccTotal += row.ccTotal;
        }
        return {
            careCoord: 'Total:',
            cc: 'total',
            inperson: `${total.inperson} (${round((total.inperson / total.ccTotal) * 100, 2)}%)`,
            telehealth: `${total.telehealth} (${round((total.telehealth / total.ccTotal) * 100, 2)}%)`,
        };
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        ...Object.keys(labels).map(n => ({
            name: labels[n].name,
            label: labels[n].label,
            sort: true,
            render(value, row) {
                return value > 0 ? `${value} (${round((value / row.ccTotal) * 100, 2)}%)` : 0;
            },
        })),
    ];

    const tableData = getData(data);
    const totalRow = getTotalRow(tableData);

    return (
        <Table
            data={tableData}
            rowKey="cc"
            orderBy="careCoord"
            columns={cols}
            showPerPageOptions={false}
            footer={totalRow}
            isCsvDownload
        />
    );
};

MeetingFormatTable.propTypes = {
    data: PropTypes.object,
};

export default MeetingFormatTable;
