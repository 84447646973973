import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import InfoTooltip from 'js/vendor/reports/components/InfoTooltip';

const ExclusionInfoTable = props => {
    const { data } = props;

    const filters = [
        {
            text: 'Invalid Full Meeting Attendance Subscale',
            tooltip: 'Invalid if 4 or more items from 1A-1F are N/A',
            key: 'invalidFMA',
            sort: true,
        },
        {
            text: 'Invalid Based on Priority Needs Subscale',
            tooltip: 'Invalid if 4 or more items from 4A-4E are N/A',
            key: 'invalidPN',
            sort: true,
        },
        {
            text: 'Invalid Natural and Community Supports Subscale',
            tooltip: 'Invalid if 4 or more items from 5A-5E N/A',
            key: 'invalidNC',
            sort: true,
        },
        {
            text: 'Invalid Outcomes-Based Process Subscale',
            tooltip: 'Invalid if 4 or more items from 6A-6E are N/A',
            key: 'invalidOB',
            sort: true,
        },
        {
            text: 'Invalid TOM 2.0 Form',
            tooltip: 'Entire record invalid if 4 or more subscales are N/A.',
            key: 'excluded',
            sort: true,
        },
    ];

    const getData = () => {
        let rows = [];
        let totals = {
            invalid: 0,
            invalidOB: 0,
            invalidNC: 0,
            invalidPN: 0,
            invalidFMA: 0,
            excluded: 0,
        };
        for (var cc of Object.keys(data)) {
            for (var filter of filters) {
                if (data[cc][filter.key] > 0) {
                    totals[filter.key] += data[cc][filter.key];
                }
            }
        }
        for (var row of filters) {
            rows.push({ filter: row, count: totals[row.key], key: row.key });
        }
        return rows;
    };

    const cols = [
        {
            name: 'filter',
            label: 'Filter',
            render(value, row) {
                return (
                    <div style={{ display: 'flex' }}>
                        {value.text}
                        <InfoTooltip text={value.tooltip} />
                    </div>
                );
            },
        },
        { name: 'count', label: 'Count' },
    ];

    const tableData = getData();
    return <Table data={tableData} rowKey="key" columns={cols} showPerPageOptions={false} isCsvDownload />;
};

ExclusionInfoTable.propTypes = {
    data: PropTypes.object,
};

export default ExclusionInfoTable;
