import Builder, { initialValues as builderInitialValues } from 'js/vendor/reports/widgets/TablePicker/Builder';
import Player from 'js/vendor/reports/widgets/TablePicker/Player';

// components
import RespondentSummarySection from './custom/sections/RespondentSummarySection';
import EnrollmentDischargeSection from './custom/sections/EnrollmentDischargeSection';
import FidelitySection from './custom/sections/FidelitySection';
import SampleResponseSummary from './custom/sections/SampleResponseSummary';
import TeamMemberAttendanceSection from './custom/sections/TeamMemberAttendanceSection';
import CommentSection from './custom/sections/CommentSection';
import ReportOverviewSection from './custom/sections/ReportOverviewSection';

const respondentSummarySections = [
    {
        name: 'respondentSummary',
        label: 'Respondent Summary',
        component: RespondentSummarySection,
    },
];

const reportOverviewSections = [
    {
        name: 'reportOverview',
        label: 'Report Overview',
        component: ReportOverviewSection,
    },
];

const enrollmentDischargeSections = [
    {
        name: 'enrollmentDischarge',
        label: 'Enrollment and Discharge Information',
        component: EnrollmentDischargeSection,
    },
];

const fidelitySections = [
    {
        name: 'fidelity',
        label: 'Section B. Fidelity',
        component: FidelitySection,
    },
];

const responseSummarySections = [
    {
        name: 'responseSummary',
        label: 'Sample and Response Summary',
        component: SampleResponseSummary,
    },
];

const teamMemberAttendance = [
    {
        name: 'teamMemberAttendance',
        label: 'Team Member Attendance',
        component: TeamMemberAttendanceSection,
    },
];

const commentSections = [
    {
        name: 'comments',
        label: 'Comments',
        component: CommentSection,
    },
];

export const templateViews = [
    {
        handle: 'summary',
        label: 'Respondent Summary',
        sections: respondentSummarySections,
    },
    {
        handle: 'responseSummary',
        label: 'Sample and Response Summary',
        sections: responseSummarySections,
    },
    {
        handle: 'reportOverview',
        label: 'Report Overview',
        sections: reportOverviewSections,
    },
    {
        handle: 'enrollmentDischarge',
        label: 'Enrollment and Discharge Information',
        sections: enrollmentDischargeSections,
    },
    {
        handle: 'teamMemberAttendance',
        label: 'Child Family and Team Meeting Information',
        sections: teamMemberAttendance,
    },
    {
        handle: 'fidelity',
        label: 'Fidelity',
        sections: fidelitySections,
    },
    {
        handle: 'comments',
        label: 'Comments',
        sections: commentSections,
    },
];

export default templateViews.map(s => ({
    handle: s.handle,
    label: s.label,
    builderInitialValues,
    builder: Builder,
    player: Player,
}));
