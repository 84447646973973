import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { mean, round } from 'lodash';
import subscales from '../subscales.js';

const OverallKeyElementsChart = props => {
    const { respondents, data } = props;

    const getData = () => {
        const chartData = [];
        for (const sub of subscales) {
            const bar = { subscale: sub.label };
            for (const res of respondents) {
                const subScores = [];
                if (data.flows[res.label].frequency)
                    for (const cc of data.flows[res.label].frequency) {
                        const subScore = cc[`${res.key}${sub.key}`];
                        if (cc.value && subScore !== null) {
                            subScores.push(subScore);
                        }
                    }
                bar[res.label] = round(mean(subScores), 2);
            }
            chartData.push(bar);
        }
        return chartData;
    };

    const chartData = getData();

    return (
        <div style={{ width: '770px', height: '600px' }}>
            <ResponsiveBar
                indexBy="subscale"
                groupMode="grouped"
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                maxValue={100}
                minValue={0}
                labelSkipWidth={0}
                labelSkipHeight={1}
                axisBottom={{ tickRotation: 45 }}
                keys={respondents.map(r => r.label)}
                theme={{
                    text: {
                        fontSize: 20,
                    },
                    axis: {
                        ticks: {
                            text: {
                                fontSize: 16,
                            },
                        },
                    },
                    labels: {
                        text: { fontSize: '13px' },
                    },
                }}
                data={chartData}
                margin={{ top: 70, right: 80, bottom: 170, left: 50 }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top',
                        direction: 'row',
                        justify: false,
                        translateX: 15,
                        translateY: -40,
                        itemsSpacing: 20,
                        itemWidth: 150,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
};

OverallKeyElementsChart.propTypes = {
    data: PropTypes.object,
    respondents: PropTypes.array,
};

export default OverallKeyElementsChart;
