import React from 'react';
import { Field, CccisdInput, CccisdFieldWrapper, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';
import Select from 'react-select';

const tables = window.cccisd.appDefs.customTables.list
    .map(t => ({
        value: t.id,
        label: t.title,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const initialValues = {
    table: tables[0].value,
};

const renderTableFields = t => {
    return (
        <div key={t.name}>
            <Field name={t.name + 'Desc'} component={CccisdWysiwyg} label="Description" />

            {t.component ? (
                <>
                    <p style={{ fontWeight: 'bold' }}>{t.label}</p>
                    <p>This table is built by a developer</p>
                </>
            ) : (
                <Field name={t.name + 'Table'}>
                    {({ field, form }) => (
                        <CccisdFieldWrapper field={field} form={form} label={t.label}>
                            <Select
                                defaultValue={tables.find(tab => tab.value === field.value)}
                                options={tables}
                                onChange={s => {
                                    form.setFieldValue(t.name + 'Table', s.value);
                                }}
                            />
                        </CccisdFieldWrapper>
                    )}
                </Field>
            )}
        </div>
    );
};

const Component = props => {
    return (
        <div style={{ paddingBottom: '10em' }}>
            <Field name="title" component={CccisdInput} label="Section Title" />
            <Field name="desc" component={CccisdWysiwyg} label="Description" />
            {props.sections.map(s => {
                if (s.tables && s.tables.length > 0) {
                    return (
                        <div key={s.name}>
                            <br />
                            <h4 style={{ textAlign: 'right' }}>{s.label}</h4>
                            {s.tables && s.tables.map(t => renderTableFields(t))}
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(Component);
