import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const SubscaleTable = props => {
    const { groupLevel, groupLevelMap, data, subscales } = props;
    const getData = d => {
        const rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            let groupTotals = {};
            const sites = d.groups.siteList.filter(s => groupLevelMap[group].includes(s.group.groupId));
            for (var site of sites) {
                for (var subscale of subscales) {
                    let siteSubscaleTotal = null;
                    for (var item of subscale.items) {
                        const score = site.descendantRoles.youthSummary[item];
                        if (score !== null) {
                            siteSubscaleTotal += site.descendantRoles.youthSummary[item];
                        }
                    }
                    if (groupTotals[subscale.name]) {
                        if (siteSubscaleTotal !== null) {
                            groupTotals[subscale.name] += siteSubscaleTotal;
                        }
                    } else {
                        groupTotals[subscale.name] = siteSubscaleTotal;
                    }
                }
            }
            let row = { group };
            for (var sub of subscales) {
                if (groupTotals[sub.name] === null) {
                    row[sub.name] = null;
                } else {
                    row[sub.name] = groupTotals[sub.name] / (sub.items.length * sites.length);
                }
            }
            rows.push(row);
        }
        return rows.filter(row =>
            Object.keys(row).some(key => {
                if (key !== 'group') {
                    return row[key] >= 0 || row[key] === null;
                }
                return false;
            })
        );
    };

    const getTotal = td => {
        const totals = {};
        const nullScores = {};
        for (let i = 0; i < td.length; i++) {
            const row = td[i];
            for (var key of Object.keys(row)) {
                if (key !== 'group') {
                    const value = row[key];
                    if (value !== null) {
                        if (totals[key]) {
                            totals[key] += row[key];
                        } else {
                            totals[key] = row[key];
                        }
                    } else {
                        if (!nullScores[key]) {
                            nullScores[key] = [];
                        }
                        nullScores[key].push(i);
                    }
                }
            }
        }
        let total = { group: 'Total: ' };
        for (var sub of Object.keys(totals)) {
            const numNull = nullScores[sub] ? nullScores[sub].length : 0;
            if (numNull === td.length) {
                total[sub] = '';
            } else {
                total[sub] = `${round(100 * (totals[sub] / (td.length - numNull)), 2)}%`;
            }
        }
        return total;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        ...subscales.map(s => ({
            name: s.name,
            label: s.label,
            render: (value, row) => (value !== null ? `${round(value * 100, 2)}%` : 'N/A*'),
        })),
    ];

    const tableData = getData(data);
    const totalRow = getTotal(tableData);

    let showNotice = false;
    for (const row of tableData) {
        if (!showNotice) {
            if (Object.keys(row).some(key => row[key] === null)) {
                showNotice = true;
            }
        }
    }

    return (
        <>
            <Table
                data={tableData}
                rowKey="group"
                columns={cols}
                footer={totalRow}
                showPerPageOptions={false}
                isCsvDownload
            />
            {showNotice && (
                <p>
                    *All TOMs for this item were scored as &quot;N/A&quot;, thus a summary score can not be calculated
                </p>
            )}
        </>
    );
};

SubscaleTable.propTypes = {
    data: PropTypes.object,
    groupLevelMap: PropTypes.object,
    groupLevel: PropTypes.string,
    subscales: PropTypes.array,
};

export default SubscaleTable;
