import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const CommentsTable = props => {
    const { data, respondent, groupLevel, groupLevelMap } = props;
    const getData = () => {
        let rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            for (var siteId of groupLevelMap[group]) {
                const site = data.groups.siteList.find(s => s.group.groupId === siteId);
                if (site) {
                    const { comments } = site.descendantRoles[respondent.name];
                    if (comments) {
                        for (var comment of comments) {
                            if (comment.value) {
                                for (var i = 0; i < comment.valueCount; i++) {
                                    const key = `${group}-${comment.value}-${i}`;
                                    rows.push({ group, comment: comment.value, key });
                                }
                            }
                        }
                    }
                }
            }
        }
        return rows;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        {
            name: 'comment',
            label: 'Comment',
            filter: true,
        },
    ];

    return (
        <Table
            data={getData()}
            rowKey="key"
            orderBy="group"
            columns={cols}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            csvFilename="section-B-comments"
            isCsvDownload
        />
    );
};

CommentsTable.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.object,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default CommentsTable;
