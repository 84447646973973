import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const ItemQuestionTable = props => {
    const { items, respondent } = props;
    const cols = [
        {
            name: 'item',
            label: 'Item',
        },
        {
            name: 'question',
            label: 'Question',
        },
    ];

    const getData = () => {
        const d = [];
        for (var item of items) {
            const { question } = window.cccisd.appDefs.wfiQuestions[`${respondent.label}_${item.replace('*', '')}`];
            d.push({ item, question });
        }
        return d;
    };

    return <Table rowKey="item" columns={cols} data={getData()} isCsvDownload showPerPageOptions={false} />;
};

ItemQuestionTable.propTypes = {
    items: PropTypes.array,
    respondent: PropTypes.object,
};

export default ItemQuestionTable;
