import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { mean, round } from 'lodash';

const ElementItemChart = props => {
    const { data, respondents, subscale, filters, groupLevelMap } = props;

    const doAlt = subscale.items.includes('D9');

    const getData = () => {
        const chartData = [];
        for (const item of subscale.items) {
            let barGroup = { label: item };
            for (const r of respondents) {
                if (filters.respondentSelectorField.includes(r.id)) {
                    const groupAvgs = [];
                    for (const g of Object.keys(groupLevelMap)) {
                        let groupN = 0;
                        let groupTotal = 0;
                        for (const site of data.groups.siteList) {
                            if (groupLevelMap[g].includes(site.group.groupId)) {
                                if (site.descendantRoles[r.name][item]) {
                                    groupTotal += site.descendantRoles[r.name][item];
                                }
                                if (site.descendantRoles[r.name][item + 'N']) {
                                    groupN += site.descendantRoles[r.name][item + 'N'];
                                }
                            }
                        }
                        const score = groupTotal / groupN;
                        if (score >= 0) {
                            if (doAlt) {
                                groupAvgs.push(round(score, 2));
                            } else {
                                groupAvgs.push(round(score * 100, 2));
                            }
                        }
                    }
                    if (groupAvgs.length > 0) {
                        barGroup[r.label] = round(mean(groupAvgs), 2);
                    }
                }
            }
            chartData.push(barGroup);
        }
        return chartData;
    };

    const chartData = getData();

    const yAxisMax = doAlt ? 3 : 100;

    return (
        <div style={{ width: '770px', height: '300px' }}>
            <ResponsiveBar
                indexBy="label"
                groupMode="grouped"
                maxValue={yAxisMax}
                minValue={0}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                axisBottom={{ tickRotation: 45 }}
                keys={respondents.map(r => r.label)}
                theme={{ fontSize: 16 }}
                data={chartData}
                margin={{ top: 15, right: 175, bottom: 50, left: 50 }}
            />
        </div>
    );
};

ElementItemChart.propTypes = {
    subscale: PropTypes.object,
    respondents: PropTypes.array,
    filters: PropTypes.object,
    data: PropTypes.object,
    groupLevelMap: PropTypes.object,
};

export default ElementItemChart;
