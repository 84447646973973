import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const ItemQuestionTable = props => {
    const { items, respondent } = props;
    const cols = [
        {
            name: 'item',
            label: 'Item',
        },
        {
            name: 'question',
            label: 'Question',
        },
    ];

    const getData = () => {
        const d = [];
        for (var item of items) {
            const q = window.cccisd.appDefs.wfiQuestions[`${respondent.label}_${item}`];
            if (q && q.question) {
                d.push({ item, question: q.question });
            }
        }
        return d;
    };

    return (
        <Table
            rowKey="item"
            columns={cols}
            data={getData()}
            perPage={5000}
            perPageOptions={[25, 50, 100, 500]}
            isCsvDownload
            csvFilename="section-D-item-questions"
        />
    );
};

ItemQuestionTable.propTypes = {
    items: PropTypes.array,
    respondent: PropTypes.object,
};

export default ItemQuestionTable;
