import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { mean, round } from 'lodash';

const OverallElementChart = props => {
    const { respondentSelectorField, respondents, data, subscale, groupLevelMap } = props;

    const getData = () => {
        const chartData = [];
        for (const id of respondentSelectorField) {
            const respondent = respondents[id];
            const barGroup = { label: respondent.label };
            let rows = [];
            for (var group of Object.keys(groupLevelMap)) {
                var row = { group };
                var itemTotals = {};
                var groupItemNs = {};
                for (var siteId of groupLevelMap[group]) {
                    const site = data[respondent.name].groups.siteList.find(s => siteId === s.group.groupId);
                    if (site) {
                        for (var item of subscale.items) {
                            const siteN = site.descendantRoles[respondent.name]['N' + item];
                            if (siteN) {
                                if (groupItemNs[item]) {
                                    groupItemNs[item] += siteN;
                                } else {
                                    groupItemNs[item] = siteN;
                                }
                            }
                            const siteItemMean = site.descendantRoles[respondent.name][item];
                            if (siteItemMean) {
                                if (itemTotals[item]) {
                                    itemTotals[item] += siteItemMean;
                                } else {
                                    itemTotals[item] = siteItemMean;
                                }
                            }
                        }
                    }
                }
                let rowHasData = false;
                for (var i of subscale.items) {
                    const countYes = itemTotals[i] || 0;
                    const percentYes = groupItemNs[i] ? round(countYes / groupItemNs[i], 2) : 0;

                    if (groupItemNs[i] > 0) {
                        rowHasData = true;
                    }

                    row[i] = `${percentYes}, N=${groupItemNs[i] || 0}`;
                }
                if (rowHasData) {
                    rows.push(row);
                }
            }

            const tr = {};
            for (const r of rows) {
                for (const q of Object.keys(r).filter(ri => ri !== 'group')) {
                    if (tr[q]) {
                        tr[q].push(parseFloat(r[q].split(',')[0], 10));
                    } else {
                        tr[q] = [parseFloat(r[q].split(',')[0], 10)];
                    }
                }
            }
            for (const k of Object.keys(tr)) {
                barGroup[k] = round(mean(tr[k]), 1) || 0;
            }
            chartData.push(barGroup);
        }
        const formattedData = [];
        for (const it of subscale.items) {
            const itemRes = { label: it };
            for (const res of chartData) {
                itemRes[res.label] = res[it];
            }
            formattedData.push(itemRes);
        }
        return formattedData;
    };

    const chartData = getData();

    return (
        <div style={{ width: '770px', height: '400px' }}>
            <ResponsiveBar
                indexBy="label"
                groupMode="grouped"
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                maxValue={2}
                minValue={-2}
                labelSkipWidth={0}
                labelSkipHeight={1}
                axisBottom={{ tickRotation: 45 }}
                keys={respondentSelectorField.map(r => respondents[r].label)}
                theme={{ fontSize: 16 }}
                data={chartData}
                margin={{ top: 70, right: 50, bottom: 50, left: 50 }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top',
                        direction: 'row',
                        justify: false,
                        translateX: 15,
                        translateY: -40,
                        itemsSpacing: 20,
                        itemWidth: 150,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
};

OverallElementChart.propTypes = {
    groupLevelMap: PropTypes.object,
    subscale: PropTypes.object,
    data: PropTypes.object,
    respondentSelectorField: PropTypes.array,
    respondents: PropTypes.object,
};

export default OverallElementChart;
