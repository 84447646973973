import React from 'react';
import PropTypes from 'prop-types';
import query from './query.graphql';
import OtherResponseTable from '../OtherResponseTable';

const OtherExitReason = props => {
    return (
        <OtherResponseTable
            filters={props.filters}
            settings={props.settings}
            query={query}
            field="specifyExitReason"
            table={props.table}
        />
    );
};

OtherExitReason.propTypes = {
    filters: PropTypes.object,
    table: PropTypes.object,
    settings: PropTypes.object,
};

export default OtherExitReason;
