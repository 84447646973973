import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round, mean, min, max } from 'lodash';
import { std } from '../../../ReportOverviewSection/helpers';

const MonthsInWraparoundTable = props => {
    const { data } = props;

    const getData = d => {
        const rows = [];
        const ccMap = {};
        const all = [];
        if (d.roles.youthSummary?.frequency) {
            for (const cc of d.roles.youthSummary.frequency) {
                if (cc.frequency) {
                    for (const month of cc.frequency) {
                        if (month.value) {
                            if (!ccMap[cc.value]) {
                                ccMap[cc.value] = [];
                            }
                            const val = parseFloat(month.value, 10);
                            for (let i = 0; i < month.valueCount; i++) {
                                ccMap[cc.value].push(val);
                                all.push(val);
                            }
                        }
                    }
                }
            }
        }
        for (const cc of Object.keys(ccMap)) {
            rows.push({
                careCoord: cc.split(', ')[0],
                cc,
                mean: mean(ccMap[cc]),
                std: std(ccMap[cc]),
                min: min(ccMap[cc]),
                max: max(ccMap[cc]),
            });
        }
        rows.push(all);

        return rows;
    };

    const getTotal = all => {
        return {
            careCoord: 'Total:',
            cc: 'totalrow',
            mean: round(mean(all), 2),
            range: `${round(min(all), 2)} - ${round(max(all), 2)}`,
        };
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        {
            name: 'mean',
            label: 'Mean (SD) Months in Wraparound',
            tooltip:
                'Months in Wraparound is calculated using the Enrollment Start Date and Discharge Date. If a youth does not have a Discharge Date within the specified date range, the date range End Date is used.',
            render: (_, row) => `${round(row.mean, 2)} (${round(row.std, 2)})`,
            sort: true,
            filter: true,
        },
        {
            name: 'range',
            label: 'Range: Months in Wraparound',
            tooltip:
                'Months in Wraparound is calculated using the Enrollment Start Date and Discharge Date. If a youth does not have a Discharge Date within the specified date range, the date range End Date is used.',
            render: (_, row) => `${round(row.min, 2)} - ${round(row.max, 2)}`,
            sort: true,
            filter: true,
        },
    ];

    const tableData = getData(data);
    const totalRow = getTotal(tableData.pop());

    return (
        <Table
            data={tableData}
            rowKey="cc"
            orderBy="careCoord"
            columns={cols}
            footer={totalRow}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="months-in-wraparound"
        />
    );
};

MonthsInWraparoundTable.propTypes = {
    data: PropTypes.object,
};

export default MonthsInWraparoundTable;
