import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import widgets, { templateViews } from '../widgets.js';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import filterFields from '../filterFields.js';
import { client, taskMasterClient } from 'cccisd-apollo';
import youthCountQuery from '../graphql/youthPawnIds.graphql';
import internalStaffQuery from '../graphql/internalStaff.graphql';
import moment from 'moment';
import { connect } from 'react-redux';
import { resetWidgetsLoaded, setYouthPawnIds, setCcoYouthMap } from 'js/reducers/report.js';
import TemplateWrapper from 'js/vendor/reports/components/TemplateWrapper';
import { getGroupCount, groupChildren } from 'js/vendor/reports/helpers.js';
import hash from 'object-hash';

const Component = props => {
    const {
        siteSelectorField,
        dateSelectorFrom,
        dateSelectorTo,
        showHideView,
        evalCycleSelectorField,
        careCoordSelectorField,
    } = props.filters;

    const { settings, groups } = props;
    const loading = 'Loading...';
    const [youthCount, setYouthCount] = useState(loading);
    const [internalEvaluatorMajority, setInternalEvaluatorMajority] = useState(loading);

    const filtersChanged = hash(props.initialFilterValues) !== hash(props.filters);
    const showError = youthCount === 0 || evalCycleSelectorField.length === 0 || careCoordSelectorField.length === 0;

    const prevCareCoordsRef = useRef();
    const prevEvalCyclesRef = useRef();

    const getData = async () => {
        if (
            filtersChanged &&
            props.filters.careCoordSelectorField.length > 0 &&
            props.youthPawnIds.length > 0 &&
            (internalEvaluatorMajority === loading || hash(prevCareCoordsRef.current) !== hash(careCoordSelectorField))
        ) {
            console.log('GET INTERNAL EVALUATORS');
            await checkInternalEvaluators();
        }
    };

    const getYouth = async () => {
        await getYouthCount();
    };

    useEffect(() => {
        if (
            (evalCycleSelectorField.length > 0 && prevEvalCyclesRef.current !== evalCycleSelectorField) ||
            (careCoordSelectorField.length > 0 && prevCareCoordsRef.current !== careCoordSelectorField)
        ) {
            getYouth();
        }
        prevEvalCyclesRef.current = evalCycleSelectorField;
    }, [hash(evalCycleSelectorField), hash(careCoordSelectorField)]);

    useEffect(() => {
        props.resetWidgetsLoaded();
        if (siteSelectorField && filtersChanged) {
            getData();
        }
        prevCareCoordsRef.current = careCoordSelectorField;
    }, [
        showHideView,
        siteSelectorField,
        dateSelectorFrom,
        dateSelectorTo,
        evalCycleSelectorField,
        careCoordSelectorField,
        props.youthPawnIds,
    ]);

    const headerGroups = settings.groupLevel
        ? groupChildren[settings.groupLevel].map(g => ({
              title: `Number of ${g.label}`,
              value: getGroupCount(g.name, siteSelectorField, groups),
              showIf: true,
          }))
        : [{ title: 'Groups', value: 'Select Group Level from Report Settings', showIf: true }];

    const sum = [
        ...headerGroups,
        {
            title: 'Care Coordinators',
            value: careCoordSelectorField.length,
            showIf: true,
        },
        {
            title: 'Date Range',
            value:
                dateSelectorFrom &&
                dateSelectorTo &&
                moment(dateSelectorFrom).format('MM/DD/YYYY') + ' - ' + moment(dateSelectorTo).format('MM/DD/YYYY'),
            showIf:
                settings.filterFields.includes('dateSelectorFrom') && settings.filterFields.includes('dateSelectorTo'),
        },
        {
            title: 'Evaluation Cycles',
            value: evalCycleSelectorField && evalCycleSelectorField.length,
            showIf: settings.filterFields.includes('evalCycleSelectorField'),
        },
    ];
    // add the non-filter related summary items
    sum.push({
        title: 'Number of Youth',
        value: youthCount,
        showIf: true,
    });

    const getYouthCount = async siteIds => {
        setYouthCount(loading);
        const response = await taskMasterClient.query({
            query: youthCountQuery,
            variables: {
                filter: null,
                deploymentIds: evalCycleSelectorField,
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        // console.log({ youthResponse: response });
        const ccoMap = {};
        for (const y of response.data.roles.youthList) {
            const { pawn } = y;
            for (const a of y.assignmentProgressList) {
                const cco = a.variables.app_variable_careCoordinator;
                if (cco) {
                    if (ccoMap[cco]) {
                        if (!ccoMap[cco]?.includes(pawn)) {
                            ccoMap[cco].push(pawn);
                        }
                    } else {
                        ccoMap[cco] = [pawn];
                    }
                }
            }
        }

        const includedYouthPawnIds = [];
        for (const y of response.data.roles.youthList) {
            const pId = y.pawn.pawnId;
            for (const cc of careCoordSelectorField) {
                const pawnDashIndex = cc.lastIndexOf('-');
                const ccString = cc.substring(0, pawnDashIndex);
                if (ccoMap[ccString]?.some(pawn => pawn.pawnId === pId)) {
                    includedYouthPawnIds.push(pId);
                }
            }
        }

        props.setYouthPawnIds(includedYouthPawnIds);
        props.setCcoYouthMap(ccoMap);
        setYouthCount(includedYouthPawnIds.length);
        prevEvalCyclesRef.current = evalCycleSelectorField;
    };

    const checkInternalEvaluators = async () => {
        const variables = {
            pawnIds: props.youthPawnIds || [],
            deploymentIds: evalCycleSelectorField,
            ccList: careCoordSelectorField.map(cc => {
                const pawnDashIndex = cc.lastIndexOf('-');
                return cc.substring(0, pawnDashIndex);
            }),
        };
        const response = await taskMasterClient.query({
            query: internalStaffQuery,
            variables,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        let total = 0;
        for (const evaluator of response.data.flows.assignmentProgressSummary.completedBy) {
            if (['1', '2'].includes(evaluator.value)) {
                total += evaluator.valueCount;
            }
        }
        const { valueCount } = response.data.flows.assignmentProgressSummary.completedBy.find(e => e.value === '1');
        console.log({
            internalStaffBannerData: {
                internalCount: valueCount,
                externalCount: total - valueCount,
                totalCount: total,
                queryVars: variables,
                props,
            },
        });
        const majorityInternal = valueCount / total > 0.5;
        setInternalEvaluatorMajority(majorityInternal);
    };

    return (
        <TemplateWrapper
            settings={settings}
            summary={sum}
            showBanner={internalEvaluatorMajority === loading ? false : internalEvaluatorMajority}
            bannerText="More than 50% of forms were completed by Internal Staff"
            initialFilterValues={props.initialFilterValues}
            filters={props.filters}
            widgetsConfig={widgets}
            widgetComponents={props.widgets}
            templateViews={templateViews}
            alwaysShownWidgets={['summary']}
            shouldShowWidget={w => settings[w.handle] && showHideView.includes(w.label) && youthCount !== loading}
            showWhen={youthCount !== loading}
            showError={showError}
            errorText="There are zero youth for this filter selection. Try selecting different options."
        />
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    groups: PropTypes.array,
    initialFilterValues: PropTypes.object,
    // redux
    resetWidgetsLoaded: PropTypes.func,
    setYouthPawnIds: PropTypes.func,
    setCcoYouthMap: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    youthPawnIds: state.app.report.youthPawnIds,
});
const Connected = connect(mapStateToProps, { resetWidgetsLoaded, setYouthPawnIds, setCcoYouthMap })(Component);

export default reportTemplatePlayer({
    widgets,
    getFilterFields: props => {
        const defaults = filterFields.map(f => ({
            ...f,
            initialValue: props.initialFilterValues[f.name],
        }));
        return defaults;
    },
})(Connected);

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
