import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round } from 'lodash';

const ItemRespondentChart = props => {
    const { data, item, careCoordSelectorField, respondents } = props;

    const getData = () => {
        const ccList = careCoordSelectorField.map(cc => {
            const pawnDashIndex = cc.lastIndexOf('-');
            return cc.substring(0, pawnDashIndex);
        });
        let ccBars = [];
        let ccBarGroup = [];
        for (var cc of ccList) {
            ccBarGroup = { label: cc };
            for (var rt of respondents) {
                if (data.flows[rt.label].frequency) {
                    const ccValue = data.flows[rt.label].frequency.find(c => c.value === cc);
                    if (ccValue) {
                        if (ccValue[`${item}avg`]) {
                            ccBarGroup[rt.label] = round(ccValue[`${item}avg`], 2);
                        }
                    }
                }
            }

            ccBars.push(ccBarGroup);
        }

        return ccBars;
    };

    const chartData = getData();

    const longestLabel = Math.max(
        ...chartData.map(o => {
            return o.label.length;
        })
    );

    return (
        <div style={{ width: '770px', height: `${300 + 6.5 * longestLabel}px` }}>
            <ResponsiveBar
                indexBy="label"
                groupMode="grouped"
                keys={['Youth', 'Caregiver']}
                axisBottom={{
                    tickRotation: 45,
                }}
                minValue={-2}
                maxValue={2}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                margin={{ top: 15, right: 150, bottom: 6.5 * longestLabel, left: 50 }}
                data={chartData}
            />
        </div>
    );
};

ItemRespondentChart.propTypes = {
    data: PropTypes.object,
    item: PropTypes.string,
    careCoordSelectorField: PropTypes.object,
    respondents: PropTypes.array,
};

export default ItemRespondentChart;
