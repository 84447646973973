// takes Y or CG or YCG
import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { mean, round } from 'lodash';

const SubscaleItemChart = props => {
    const { respondents, data, subscale } = props;

    const getData = () => {
        const chartData = [];
        for (const item of subscale.items) {
            const itm = item.replace('*', '');
            const bar = { item: itm };
            for (const res of respondents) {
                const itemScores = [];
                if (data.flows[res.label].frequency)
                    for (const cc of data.flows[res.label].frequency) {
                        if (cc.value && cc[`N${itm}`]) {
                            itemScores.push(cc[itm]);
                        }
                    }
                bar[res.label] = round(mean(itemScores), 2);
            }
            chartData.push(bar);
        }
        return chartData;
    };

    const chartData = getData();

    return (
        <div style={{ width: '770px', height: '400px' }}>
            <ResponsiveBar
                indexBy="item"
                groupMode="grouped"
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                maxValue={2}
                minValue={-2}
                labelSkipWidth={0}
                labelSkipHeight={1}
                axisBottom={{ tickRotation: 45 }}
                keys={respondents.map(r => r.label)}
                theme={{ fontSize: 16 }}
                data={chartData}
                margin={{ top: 70, right: 50, bottom: 50, left: 50 }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'top',
                        direction: 'row',
                        justify: false,
                        translateX: 15,
                        translateY: -40,
                        itemsSpacing: 20,
                        itemWidth: 150,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
};

SubscaleItemChart.propTypes = {
    subscale: PropTypes.object,
    data: PropTypes.object,
    respondents: PropTypes.array,
};

export default SubscaleItemChart;
