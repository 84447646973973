import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const CommentTable = props => {
    const { data } = props;

    const getData = () => {
        let rows = [];

        for (var cc of data) {
            if (cc.value) {
                for (var comment of cc.frequency) {
                    if (comment.value) {
                        for (var i = 0; i < comment.valueCount; i++) {
                            rows.push({
                                cc,
                                careCoord: cc.value.split(',')[0],
                                comment: comment.value,
                            });
                        }
                    }
                }
            }
        }
        return rows;
    };

    const cols = [
        { name: 'careCoord', label: 'Care Coordinator', sort: true },
        { name: 'comment', label: 'Text Response' },
    ];

    return (
        <Table
            data={getData()}
            rowKey="cc"
            orderBy="careCoord"
            columns={cols}
            showPerPageOptions={false}
            isCsvDownload
        />
    );
};

CommentTable.propTypes = {
    data: PropTypes.array,
};

export default CommentTable;
