import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { client } from 'cccisd-apollo';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { ResponsiveLine } from 'cccisd-nivo/line';
import { ResponsivePie } from 'cccisd-nivo/pie';
import Skeleton from 'react-loading-skeleton';
import hash from 'object-hash';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';

const ChartRender = props => {
    const { chart, filters, settings, groups, groupLevel } = props;
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);

    if (chart.component) {
        const Component = chart.component;
        return (
            <Component
                filters={filters}
                chart={chart}
                settings={settings}
                groupLevel={groupLevel}
            />
        );
    }

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getChartData();
        }
    }, [hash(props.filters), props.groups.length, props.youthPawnIds]);

    const extraVars = chart.variables
        ? chart.variables(filters, chart.altField && chart.altField)
        : {};

    const variables = {
        filter: chart.chartFilter && chart.chartFilter(filters),
        fromDate: filters.monthSelectorFrom && filters.monthSelectorFrom,
        toDate: filters.monthSelectorTo && filters.monthSelectorTo,
        pawnIds: props.youthPawnIds || [],
        ...extraVars,
    };

    const getChartData = async () => {
        const response = await client.query({
            query: chart.query,
            variables,
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        });
        setData(chart.convertData(response.data, filters, groups, groupLevel));
        setLoading(false);
        props.setWidgetLoaded(chart.name + 'Chart');
    };

    const renderChart = () => {
        const key = 'count';
        if (chart.type === 'pie') {
            data.some(item => item[key] > 0);
        }
        switch (chart.type) {
            case 'bar':
                if (data.length === 0) {
                    return (
                        <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                            <h2 style={{ marginTop: '125px' }}>No data</h2>
                        </div>
                    );
                }
                return (
                    <div style={chart.size || { width: '770px', height: '300px' }}>
                        <ResponsiveBar
                            data={data}
                            theme={{ fontSize: 16 }}
                            keys={['count']}
                            axisBottom={{
                                tickRotation: 45,
                            }}
                            labelFormat={d => <tspan y={-8}>{d}</tspan>}
                            labelSkipWidth={0}
                            labelSkipHeight={1}
                            margin={chart.margin || { top: 15, right: 150, bottom: 50, left: 50 }}
                        />
                    </div>
                );
            case 'pie':
                return (
                    <div style={chart.size || { width: '770px', height: '500px' }}>
                        <ResponsivePie
                            data={data}
                            enableArcLinkLabels={false}
                            theme={{ fontSize: 16 }}
                            keys={[key]}
                            arcLabelsSkipAngle={10}
                            arcLinkLabelsSkipAngle={20}
                            legends={chart.legend}
                            margin={chart.margin || { top: 50, right: 0, bottom: 65, left: 200 }}
                        />
                    </div>
                );
            case 'line':
                if (data.length === 0) {
                    return (
                        <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                            <h2 style={{ marginTop: '125px' }}>No data</h2>
                        </div>
                    );
                }
                return (
                    <div style={chart.size || { width: '770px', height: '500px' }}>
                        <ResponsiveLine
                            data={data}
                            theme={{ fontSize: 16 }}
                            legends={chart.legend}
                            yScale={{ type: 'linear', min: 0 }}
                            margin={chart.margin || { top: 10, right: 150, bottom: 50, left: 50 }}
                        />
                    </div>
                );
            default:
        }
    };

    return loading ? <Skeleton height={300} /> : renderChart();
};

ChartRender.propTypes = {
    filters: PropTypes.object,
    chart: PropTypes.object,
    settings: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    groups: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded })(ChartRender);
