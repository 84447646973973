import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round, mean } from 'lodash';

const RespondentKeyElementsTable = props => {
    const { scales, data, respondent, groupLevel, groupLevelMap } = props;

    const getData = d => {
        let rows = [];
        for (let group of Object.keys(groupLevelMap)) {
            let row = { group };
            let totalScaleMeans = {};
            let sitesWithScale = {};
            for (let siteId of groupLevelMap[group]) {
                const site = d.groups.siteList.find(s => s.group.groupId === siteId);
                if (site) {
                    for (let scale of scales) {
                        const key = respondent.short + scale.short;
                        const avg = site.descendantRoles[respondent.name][key];
                        if (avg) {
                            if (totalScaleMeans[scale.name]) {
                                totalScaleMeans[scale.name] += avg;
                            } else {
                                totalScaleMeans[scale.name] = avg;
                            }
                            if (sitesWithScale[scale.name]) {
                                sitesWithScale[scale.name] += 1;
                            } else {
                                sitesWithScale[scale.name] = 1;
                            }
                        }
                    }
                }
            }
            for (let scalename of Object.keys(totalScaleMeans)) {
                const groupScaleMean = round(totalScaleMeans[scalename] / sitesWithScale[scalename], 2);
                row[scalename] = groupScaleMean;
            }
            const rowHasData = Object.keys(row).some(sc => row[sc] > 0);
            if (rowHasData) {
                rows.push(row);
            }
        }
        return rows;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        ...scales.map(scale => ({
            name: scale.name,
            label: scale.label,
            render: (value, row) => `${value}%`,
        })),
    ];

    const getTotal = d => {
        const tr = { group: 'Total:' };
        for (const row of d) {
            for (const q of Object.keys(row).filter(r => r !== 'group')) {
                if (tr[q]) {
                    tr[q].push(row[q]);
                } else {
                    tr[q] = [row[q]];
                }
            }
        }
        for (const k of Object.keys(tr).filter(r => r !== 'group')) {
            tr[k] = `${round(mean(tr[k]), 2)}%`;
        }
        return tr;
    };

    const tableData = getData(data);
    const totalRow = getTotal(tableData);

    return (
        <Table
            data={getData(data)}
            rowKey="group"
            columns={cols}
            isCsvDownload
            footer={totalRow}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            csvFilename="section-B-fidelity-key-elements"
        />
    );
};

RespondentKeyElementsTable.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.object,
    scales: PropTypes.array,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default RespondentKeyElementsTable;
