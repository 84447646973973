import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round } from 'lodash';

const SubscaleItemChart = props => {
    const { data, subscale, groupLevelMap } = props;

    const getData = d => {
        let rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            let groupItemTotal = {};
            let groupTotalN = 0;
            const sites = d.groups.siteList.filter(s => groupLevelMap[group].includes(s.group.groupId));
            for (var site of sites) {
                for (var item of subscale.items) {
                    const score = site.descendantRoles.youthSummary[item];
                    if (groupItemTotal[item]) {
                        groupItemTotal[item] += score;
                    } else {
                        groupItemTotal[item] = score;
                    }
                }
                groupTotalN += site.descendantRoles.youthCount && site.descendantRoles.youthCount;
            }
            let row = { group };
            for (var i of Object.keys(groupItemTotal)) {
                row[i] = {
                    score: groupItemTotal[i] !== null ? round(100 * (groupItemTotal[i] / sites.length), 2) : null,
                    nVal: round(groupTotalN, 2),
                };
            }
            rows.push(row);
        }
        rows = rows.filter(row =>
            Object.keys(row).some(key => {
                if (key !== 'group') {
                    return row[key].score >= 0 || row[key].score === null;
                }
                return false;
            })
        );
        const totals = {};
        const nullScores = {};

        for (let s = 0; s < rows.length; s++) {
            const row = rows[s];
            for (let key of Object.keys(row)) {
                if (key !== 'group') {
                    const score = row[key].score;
                    if (score !== null) {
                        if (totals[key] || totals[key] === 0) {
                            totals[key] += score;
                        } else {
                            totals[key] = score;
                        }
                    } else {
                        if (!nullScores[key]) {
                            nullScores[key] = [];
                        }
                        nullScores[key].push(i);
                    }
                }
            }
        }

        let total = { group: 'Total: ' };

        for (let sub of Object.keys(totals)) {
            const numNull = nullScores[sub] ? nullScores[sub].length : 0;
            if (numNull === rows.length) {
                total[sub] = ''; // Set total[sub] to an empty string for keys with all null scores
            } else {
                total[sub] = round(totals[sub] / (rows.length - numNull), 2);
            }
        }

        let bars = [];
        for (var it of subscale.items) {
            total[it] &&
                bars.push({
                    subscale: it.split('').reverse().join(''),
                    score: total[it],
                });
        }

        return bars;
    };

    const chartData = getData(data);

    if (!chartData.length > 0) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    const longestLabel = Math.max(
        ...chartData.map(o => {
            return o.subscale.length;
        })
    );

    return (
        <div style={{ width: '770px', height: `${300 + 6.5 * longestLabel + 10}px` }}>
            <ResponsiveBar
                indexBy="subscale"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={['score']}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 30, left: 50 }}
            />
        </div>
    );
};

SubscaleItemChart.propTypes = {
    data: PropTypes.object,
    subscale: PropTypes.object,
    groupLevelMap: PropTypes.object,
};

export default SubscaleItemChart;
