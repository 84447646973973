import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { renderSubtitle } from 'js/vendor/reports/helpers.js';

const ExitReasonsTables = props => {
    const { data } = props;

    const getData = d => {
        const rows = [];
        const ccMap = {};
        if (d.roles.youthSummary?.frequency) {
            for (const cc of d.roles.youthSummary.frequency) {
                for (const dis of cc.frequency) {
                    if (dis.value) {
                        if (ccMap[cc.value]) {
                            if (ccMap[cc.value][dis.value]) {
                                ccMap[cc.value][dis.value] += dis.valueCount;
                            } else {
                                ccMap[cc.value][dis.value] = dis.valueCount;
                            }
                        } else {
                            ccMap[cc.value] = {
                                [dis.value]: dis.valueCount,
                            };
                        }
                    } else if (dis.value === '' || dis.value === null) {
                        if (ccMap[cc.value]) {
                            if (ccMap[cc.value].other) {
                                ccMap[cc.value].other += dis.valueCount;
                            } else {
                                ccMap[cc.value].other = dis.valueCount;
                            }
                        } else {
                            ccMap[cc.value] = {
                                other: dis.valueCount,
                            };
                        }
                    }
                }
            }
        }
        for (const cc of Object.keys(ccMap)) {
            if (cc.includes('@')) {
                let numDischarged = 0;
                for (const reason of Object.keys(ccMap[cc])) {
                    numDischarged += ccMap[cc][reason];
                }
                rows.push({
                    cc,
                    careCoord: cc.split(', ')[0],
                    numDischarged,
                    completedMetGoalsPOCSteppedDown: 0,
                    completedMetGoalsPOCDischarged: 0,
                    incompleteNoEngagement: 0,
                    incompleteFamilyChoice: 0,
                    incompleteHigherLevelOfCare: 0,
                    incompleteExcludedSetting: 0,
                    incompleteDetention: 0,
                    ineligibleAgedOut: 0,
                    ineligibleLossOfInsurance: 0,
                    ineligibleChangeInIncome: 0,
                    relocated: 0,
                    other: 0,
                    ...ccMap[cc],
                });
            }
        }
        return rows;
    };

    const getTotal = td => {
        const totalRow = {
            careCoord: 'Total:',
            cc: 'totalRow',
            numDischarged: 0,
            completedMetGoalsPOCSteppedDown: 0,
            completedMetGoalsPOCDischarged: 0,
            incompleteNoEngagement: 0,
            incompleteFamilyChoice: 0,
            incompleteHigherLevelOfCare: 0,
            incompleteExcludedSetting: 0,
            incompleteDetention: 0,
            ineligibleAgedOut: 0,
            ineligibleLossOfInsurance: 0,
            ineligibleChangeInIncome: 0,
            relocated: 0,
            other: 0,
        };
        for (const row of td) {
            totalRow.numDischarged += row.numDischarged;
            totalRow.completedMetGoalsPOCSteppedDown += row.completedMetGoalsPOCSteppedDown;
            totalRow.completedMetGoalsPOCDischarged += row.completedMetGoalsPOCDischarged;
            totalRow.incompleteNoEngagement += row.incompleteNoEngagement;
            totalRow.incompleteFamilyChoice += row.incompleteFamilyChoice;
            totalRow.incompleteHigherLevelOfCare += row.incompleteHigherLevelOfCare;
            totalRow.incompleteExcludedSetting += row.incompleteExcludedSetting;
            totalRow.incompleteDetention += row.incompleteDetention;
            totalRow.ineligibleAgedOut += row.ineligibleAgedOut;
            totalRow.ineligibleLossOfInsurance += row.ineligibleLossOfInsurance;
            totalRow.ineligibleChangeInIncome += row.ineligibleChangeInIncome;
            totalRow.relocated += row.relocated;
            totalRow.other += row.other;
        }
        return totalRow;
    };

    const commonCols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        {
            name: 'numDischarged',
            label: 'Number of Youth Discharged',
            sort: true,
            filter: true,
        },
    ];

    const cols1 = [
        ...commonCols,
        {
            name: 'completedMetGoalsPOCSteppedDown',
            label: 'Step-Down',
            sort: true,
            filter: true,
            topHeader: 'Complete',
            topHeaderTooltip: 'A complete exit reason is considered a successful discharge',
            topHeaderSpan: 2,
        },
        {
            name: 'completedMetGoalsPOCDischarged',
            label: 'Met Goals on POC',
            sort: true,
            filter: true,
        },
    ];

    const cols2 = [
        ...commonCols,
        {
            name: 'incompleteNoEngagement',
            label: 'No Engagement',
            sort: true,
            filter: true,
            topHeader: 'Incomplete',
            topHeaderTooltip: 'An incomplete exit reason is considered an unsuccessful discharge',
            topHeaderSpan: 5,
        },
        {
            name: 'incompleteFamilyChoice',
            label: 'Family Choice',
            sort: true,
            filter: true,
        },
        {
            name: 'incompleteHigherLevelOfCare',
            label: 'Higher Level of Care',
            sort: true,
            filter: true,
        },
        {
            name: 'incompleteExcludedSetting',
            label: 'Excluded Setting',
            sort: true,
            filter: true,
        },
        {
            name: 'incompleteDetention',
            label: 'Placed in Detention or Correctional Facility',
            sort: true,
            filter: true,
        },
    ];
    const cols3 = [
        ...commonCols,
        {
            name: 'ineligibleAgedOut',
            label: 'Aged Out',
            sort: true,
            filter: true,
            topHeader: 'Administratively Ineligible',
            topHeaderTooltip: 'An administratively ineligible exit reason is considered an unsuccessful discharge',
            topHeaderSpan: 3,
        },
        {
            name: 'ineligibleLossOfInsurance',
            label: 'Loss of Insurance',
            sort: true,
            filter: true,
        },
        {
            name: 'ineligibleChangeInIncome',
            label: 'Change in Income/Over 150% FPL',
            sort: true,
            filter: true,
        },
        {
            name: 'relocated',
            label: 'Relocated Out of Area',
            sort: true,
            filter: true,
            topHeader: 'Uncategorized',
            topHeaderTooltip: 'An uncategorized exit reason is considered an unsuccessful discharge',
            topHeaderSpan: 2,
        },
        {
            name: 'other',
            label: 'Other',
            sort: true,
            filter: true,
        },
    ];

    const tableData = getData(data);
    const totalRow = getTotal(tableData);

    return (
        <>
            <Table
                data={tableData}
                rowKey="cc"
                orderBy="careCoord"
                columns={cols1}
                footer={totalRow}
                perPage={5000}
                perPageOptions={[250, 500, 1000, 5000]}
                isCsvDownload
                csvFilename="exit-reasons-one"
            />
            {renderSubtitle('Exit Reason (Continued)')}
            <Table
                data={tableData}
                rowKey="cc"
                orderBy="careCoord"
                columns={cols2}
                footer={totalRow}
                perPage={5000}
                perPageOptions={[250, 500, 1000, 5000]}
                isCsvDownload
                csvFilename="exit-reasons-two"
            />
            {renderSubtitle('Exit Reason (Continued)')}
            <Table
                data={tableData}
                rowKey="cc"
                orderBy="careCoord"
                columns={cols3}
                footer={totalRow}
                perPage={5000}
                perPageOptions={[250, 500, 1000, 5000]}
                isCsvDownload
                csvFilename="exit-reasons-three"
            />
        </>
    );
};

ExitReasonsTables.propTypes = {
    data: PropTypes.object,
};

export default ExitReasonsTables;
