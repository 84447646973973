import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { taskMasterClient } from 'cccisd-apollo';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import query from './query.graphql';
import { startCase } from 'lodash';
import QuestionTable from '../../tables/QuestionTable';
import ElementItemChart from './charts/ElementItemChart';
import ItemQuestionTable from './tables/ItemQuestionTable';
import RespondentItemChart from './charts/RespondentItemChart';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import ItemQuestionMeans from '../../tables/ItemQuestionMeans';
import { setWidgetLoaded } from 'js/reducers/report.js';
import {
    showChart,
    showTable,
    renderTitle,
    renderSubtitle,
    getGroupLevelMap,
    renderDescription,
    hiddenChart,
} from 'js/vendor/reports/helpers.js';
import { getTeamMemberFilter } from 'js/vendor/reports/reportTemplates/WFI-EZ/Youth/queryFilters.js';
import CommentsTable from '../FidelitySectionB/tables/CommentsTable';

const respondents = [
    { name: 'careCoordinator', label: 'Care Coordinator', id: '2' },
    { name: 'caregiver', label: 'Caregiver', id: '3' },
];

const subscales = [
    {
        name: 'schoolAndCommunity',
        label: 'School and Community Outcomes',
        desc: 'The School and Community Outcomes subscale consists of items D1 – D4. Items are rated on a binary scale, “Yes” or “No”. Users also have the option to respond “don’t know” to any item, which renders the item “blank”.',
        items: ['D1', 'D2', 'D3', 'D4'],
    },
    {
        name: 'functioningOutcomes',
        label: 'Functioning Outcomes',
        desc: 'The Functioning Outcomes subscale consists of items D5 – D9. Items are rated on a 4 point Likert scale (0 to 3, Not At All to Very Much). Users also have the option to respond “don’t know” to any item, which renders the item “N/A”.',
        items: ['D5', 'D6', 'D7', 'D8', 'D9'],
    },
];

const OutcomesSection = props => {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    // should show charts with more than X groups
    const maxGroups = 10;
    const hideChart = groupLevelMap && Object.keys(groupLevelMap).length > maxGroups;
    const plural = {
        site: 'sites',
        agency: 'agencies',
        organization: 'organizations',
        collaborator: 'collaborators',
    };

    const { evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField } =
        props.filters;

    const groupLevel = props.groupLevel || 'group';
    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            setGroupLevelMap(getGroupLevelMap(props.groups, groupLevel, siteSelectorField));
            getData();
        }
    }, [evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField]);

    const CACHE_DATA = false;
    // localStorage.setItem('sectionD', false);

    const getData = async () => {
        let response;
        if (CACHE_DATA) {
            const cache = JSON.parse(localStorage.getItem('sectionD'));
            if (cache) {
                response = cache;
            } else {
                response = await doQuery();
                localStorage.setItem('sectionD', JSON.stringify(response));
            }
        } else {
            response = await doQuery();
        }
        setData(response.data);
        setLoading(false);
        props.setWidgetLoaded(props.section.name + 'Section');
    };

    const doQuery = () => {
        return taskMasterClient.query({
            query,
            variables: {
                pawnIds: props.youthPawnIds,
                deploymentIds: evalCycleSelectorField || [],
                siteIds: siteSelectorField || [],
                filter: getTeamMemberFilter(teamMemberSelectorField),
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
    };

    return (
        <>
            {renderTitle('Subcale and Item Responses')}
            {renderDescription(
                'Section D consists of two subscales, School and Community Outcomes and Functioning Outcomes.'
            )}

            {
                respondents
                    .filter(r => respondentSelectorField.includes(r.id))
                    .map(
                        respondent => (
                            <>
                                {subscales.map(subscale => {
                                    let { items } = subscale;
                                    // remove item D5 from careCoordinator
                                    if (items.includes('D5') && respondent.name === 'careCoordinator') {
                                        items = items.filter(i => i !== 'D5');
                                    }
                                    return (
                                        <div key={subscale.name}>
                                            <div>
                                                {renderSubtitle(subscale.label)}
                                                {subscale.desc}
                                                {showChart(
                                                    <ElementItemChart
                                                        subscale={subscale}
                                                        respondents={respondents.filter(r =>
                                                            respondentSelectorField.includes(r.id)
                                                        )}
                                                        data={data}
                                                        filters={props.filters}
                                                        groupLevelMap={groupLevelMap}
                                                    />,
                                                    loading
                                                )}
                                            </div>
                                            <div key={respondent.name}>
                                                {renderSubtitle(
                                                    `Section D ${subscale.label} by ${startCase(
                                                        groupLevel
                                                    )} for WFI-EZ ${respondent.label} Form`
                                                )}
                                                {items.map(item => {
                                                    return (
                                                        <div key={item}>
                                                            {renderSubtitle(`Item ${item} - ${respondent.label}`)}
                                                            {hideChart
                                                                ? hiddenChart(
                                                                      `Chart hidden for reports with more than ${maxGroups} ${plural[groupLevel]}`
                                                                  )
                                                                : showChart(
                                                                      <RespondentItemChart
                                                                          data={data}
                                                                          respondent={respondent}
                                                                          item={item}
                                                                          groupLevelMap={groupLevelMap}
                                                                          renderSubtitle={renderSubtitle}
                                                                      />,
                                                                      loading
                                                                  )}
                                                        </div>
                                                    );
                                                })}
                                                {showTable(
                                                    <ItemQuestionTable
                                                        items={items}
                                                        respondent={respondent}
                                                        data={data}
                                                        groupLevelMap={groupLevelMap}
                                                        groupLevel={groupLevel}
                                                    />,
                                                    loading
                                                )}
                                                {showTable(
                                                    <ItemQuestionMeans
                                                        items={items}
                                                        level={respondent.label.replace(' ', '')}
                                                    />,
                                                    loading
                                                )}
                                                <div>
                                                    {renderDescription(
                                                        'The table below presents the survey question for each item.'
                                                    )}

                                                    {showTable(
                                                        <QuestionTable
                                                            items={items}
                                                            type={`${respondent.label.replace(' ', '')}`}
                                                        />,
                                                        loading
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <Collapsable
                                    collapsed
                                    title={`Additional comments from ${respondent.label} form`}
                                    content={showTable(
                                        <CommentsTable
                                            data={!loading && data}
                                            respondent={respondent}
                                            groupLevel={groupLevel}
                                            groupLevelMap={groupLevelMap}
                                        />,
                                        loading
                                    )}
                                />
                            </>
                        ) // return
                    ) // map
            }
        </>
    ); // return
}; // component

OutcomesSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    setWidgetLoaded: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(OutcomesSection);
