import React from 'react';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { ResponsivePie } from 'cccisd-nivo/pie';

import {
    convertAgeAtEnrollment,
    convertDemographicsPieChart,
    convertDemographicsBarChart,
    pieLegend,
    commonColumns,
} from './helpers.js';

const sections = [
    {
        title: 'Age at Enrollment',
        name: 'ageAtEnrollment',
        desc: false,
        columns: [
            ...commonColumns,
            {
                name: 'meanAgeAtEnrollmentSD',
                label: 'Mean Age at Enrollment (SD)',
                sort: true,
                tooltip: 'Age is calculated as of the End Date in the Date Range',
                render(value, row, callbacks) {
                    return `${row.avgAge} (${row.sdAge})`;
                },
            },
            {
                name: 'range',
                label: 'Range',
                sort: true,
                render(value, row, callbacks) {
                    return `${row.minAge} - ${row.maxAge}`;
                },
            },
        ],
        convertData: convertAgeAtEnrollment,
        columnsCont: false,
        chart: data => (
            <div style={{ width: '770px', height: '300px' }}>
                <ResponsiveBar
                    data={data}
                    theme={{ fontSize: 16 }}
                    keys={['count']}
                    axisBottom={{
                        tickRotation: 45,
                    }}
                    labelFormat={d => <tspan y={-8}>{d}</tspan>}
                    labelSkipWidth={0}
                    labelSkipHeight={1}
                    margin={{ top: 15, right: 150, bottom: 50, left: 50 }}
                />
            </div>
        ),
        otherResponses: false,
    },
    {
        title: 'Gender Identity',
        name: 'genderIdentity',
        desc: false,
        columns: [
            ...commonColumns,
            {
                name: 'male',
                label: 'Male',
                sort: true,
            },
            {
                name: 'female',
                label: 'Female',
                sort: true,
            },
            {
                name: 'nonBinary',
                label: 'Non-binary',
                sort: true,
            },
            {
                name: 'other',
                label: 'Other',
                sort: true,
            },
            {
                name: 'preferNotToSay',
                label: 'Prefer Not to Say',
                sort: true,
            },
            {
                name: 'missing',
                label: 'Missing',
                sort: true,
            },
        ],
        convertData: convertDemographicsPieChart,
        columnsCont: false,
        chart: data => (
            <div style={{ width: '770px', height: '500px' }}>
                <ResponsivePie
                    data={data}
                    enableArcLinkLabels={false}
                    theme={{ fontSize: 16 }}
                    keys={['count']}
                    arcLabelsSkipAngle={10}
                    arcLinkLabelsSkipAngle={20}
                    legends={pieLegend}
                    margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
                />
            </div>
        ),
        otherResponses: true,
    },
    {
        title: 'Transgender Identity',
        name: 'transgenderIdentity',
        desc: false,
        columns: [
            ...commonColumns,
            {
                name: 'yes',
                label: 'Yes',
                sort: true,
            },
            {
                name: 'no',
                label: 'No',
                sort: true,
            },
            {
                name: 'questioning',
                label: 'Questioning',
                sort: true,
            },
            {
                name: 'missing',
                label: 'Missing',
                sort: true,
            },
        ],
        convertData: convertDemographicsPieChart,
        columnsCont: false,
        chart: data => (
            <div style={{ width: '770px', height: '500px' }}>
                <ResponsivePie
                    data={data}
                    enableArcLinkLabels={false}
                    theme={{ fontSize: 16 }}
                    keys={['count']}
                    arcLabelsSkipAngle={10}
                    arcLinkLabelsSkipAngle={20}
                    legends={pieLegend}
                    margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
                />
            </div>
        ),
        otherResponses: false,
    },
    {
        title: 'Sexual Orientation',
        name: 'sexualOrientation',
        desc: 'Note that this demographic information is presented across multiple tables below.',
        columns: [
            ...commonColumns,
            {
                name: 'asexual',
                label: 'Asexual',
                sort: true,
            },
            {
                name: 'bisexual',
                label: 'Bisexual',
                sort: true,
            },
            {
                name: 'gay',
                label: 'Gay',
                sort: true,
            },
            {
                name: 'straight',
                label: 'Heterosexual/Straight',
                sort: true,
            },
            {
                name: 'lesbian',
                label: 'Lesbian',
                sort: true,
            },
        ],
        convertData: convertDemographicsPieChart,
        columnsCont: [
            ...commonColumns,
            {
                name: 'pansexual',
                label: 'Pansexual',
                sort: true,
            },
            {
                name: 'queer',
                label: 'Queer',
                sort: true,
            },
            {
                name: 'questioning',
                label: 'Questioning',
                sort: true,
            },
            {
                name: 'other',
                label: 'Other',
                tooltip: 'Sexual orientation not listed',
                sort: true,
            },
            {
                name: 'preferNotToSay',
                label: 'Prefer Not to Answer',
                sort: true,
            },
            {
                name: 'missing',
                label: 'Missing',
                sort: true,
            },
        ],
        chart: data => (
            <div style={{ width: '770px', height: '500px' }}>
                <ResponsivePie
                    data={data}
                    enableArcLinkLabels={false}
                    theme={{ fontSize: 16 }}
                    keys={['count']}
                    arcLabelsSkipAngle={10}
                    arcLinkLabelsSkipAngle={20}
                    legends={pieLegend}
                    margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
                />
            </div>
        ),
        otherResponses: true,
    },
    {
        title: 'Race',
        name: 'race',
        desc: 'Note that this demographic information is presented across multiple tables below.',
        columns: [
            ...commonColumns,
            {
                name: 'white',
                label: 'White',
                sort: true,
            },
            {
                name: 'blackAfricanAmerican',
                label: 'Black/African American',
                sort: true,
            },
            {
                name: 'nativeAmericanAlaskaNative',
                label: 'Native American/Alaska Native',
                sort: true,
            },
            {
                name: 'asian',
                label: 'Asian',
                sort: true,
            },
            {
                name: 'hawaiianPacificIslander',
                label: 'Hawaiian/Pacific Islander',
                sort: true,
            },
        ],
        convertData: convertDemographicsPieChart,
        columnsCont: [
            ...commonColumns,
            {
                name: 'hispanicLatinoAsRaceOnly',
                label: 'Hispanic/Latino as Race Only',
                tooltip: 'Youth who did not select a race but marked Hispanic/Latio under ethnicity.',
                sort: true,
            },
            {
                name: 'other',
                label: 'Other',
                sort: true,
            },
            {
                name: 'multiRacial',
                label: 'Multi-Racial',
                sort: true,
            },
            {
                name: 'preferNotToSay',
                label: 'Prefer Not to Say',
                sort: true,
            },
            {
                name: 'missing',
                label: 'Missing',
                sort: true,
            },
        ],
        chart: data => (
            <div style={{ width: '770px', height: '500px' }}>
                <ResponsivePie
                    data={data}
                    enableArcLinkLabels={false}
                    theme={{ fontSize: 16 }}
                    keys={['count']}
                    arcLabelsSkipAngle={10}
                    arcLinkLabelsSkipAngle={20}
                    legends={pieLegend}
                    margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
                />
            </div>
        ),
        otherResponses: true,
    },
    {
        title: 'Ethnicity',
        name: 'ethnicity',
        desc: false,
        columns: [
            ...commonColumns,
            {
                name: 'hispanicLatino',
                label: 'Hispanic/Latino',
                sort: true,
            },
            {
                name: 'nonHispanicLatino',
                label: 'Non-Hispanic/Latino',
                sort: true,
            },
            {
                name: 'missing',
                label: 'Missing',
                sort: true,
            },
        ],
        convertData: convertDemographicsPieChart,
        columnsCont: false,
        chart: data => (
            <div style={{ width: '770px', height: '500px' }}>
                <ResponsivePie
                    data={data}
                    enableArcLinkLabels={false}
                    theme={{ fontSize: 16 }}
                    keys={['count']}
                    arcLabelsSkipAngle={10}
                    arcLinkLabelsSkipAngle={20}
                    legends={pieLegend}
                    margin={{ top: 50, right: 0, bottom: 65, left: 200 }}
                />
            </div>
        ),
        otherResponses: false,
    },
    {
        title: 'Legal Custodian',
        name: 'legalCustodian',
        desc: 'This section displays the breakdown of legal custodians specified in the Youth Roster. Note that this demographic information is presented across multiple tables below.',
        columns: [
            ...commonColumns,
            {
                name: 'twoParents',
                label: 'Two Parents',
                tooltip: 'This includes two birth parents OR one birth parent & one step-parent.',
                sort: true,
            },
            {
                name: 'birthMother',
                label: 'Birth Mother Only',
                sort: true,
            },
            {
                name: 'birthFather',
                label: 'Birth Father Only',
                sort: true,
            },
            {
                name: 'adoptiveParents',
                label: 'Adoptive Parent(s)',
                sort: true,
            },
            {
                name: 'fosterParents',
                label: 'Foster Parent(s)',
                sort: true,
            },
            {
                name: 'siblings',
                label: 'Siblings',
                sort: true,
            },
        ],
        convertData: convertDemographicsBarChart,
        columnsCont: [
            ...commonColumns,
            {
                name: 'auntUncle',
                label: 'Aunt Uncle',
                sort: true,
            },
            {
                name: 'grandparents',
                label: 'Grandparent(s)',
                sort: true,
            },
            {
                name: 'friends',
                label: 'Friend(s)',
                sort: true,
            },
            {
                name: 'stateWard',
                label: 'Ward of the State',
                sort: true,
            },
            {
                name: 'other',
                label: 'Other',
                sort: true,
            },
            {
                name: 'missing',
                label: 'Missing',
                sort: true,
            },
        ],
        chart: data => (
            <div style={{ width: '770px', height: '300px' }}>
                <ResponsiveBar
                    data={data}
                    theme={{ fontSize: 16 }}
                    keys={['count']}
                    axisBottom={{
                        tickRotation: 45,
                    }}
                    labelFormat={d => <tspan y={-8}>{d}</tspan>}
                    labelSkipWidth={0}
                    labelSkipHeight={1}
                    margin={{ top: 15, right: 150, bottom: 100, left: 50 }}
                />
            </div>
        ),
        otherResponses: true,
    },
];

export default sections;
