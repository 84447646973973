import React from 'react';
import PropTypes from 'prop-types';
import query from './query.graphql';
import OtherResponseTable from '../OtherResponseTable';

const OtherRace = props => {
    return (
        <OtherResponseTable
            filters={props.filters}
            settings={props.settings}
            query={query}
            field="specifyRace"
            table={props.table}
            responsePrepend="race"
        />
    );
};

OtherRace.propTypes = {
    filters: PropTypes.object,
    table: PropTypes.object,
    settings: PropTypes.object,
};

export default OtherRace;
