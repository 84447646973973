import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const OtherMeetingAttendanceTable = props => {
    const { data } = props;

    const getData = d => {
        const rows = [];
        for (var other of d.flows?.meeting.other) {
            if (other.value) {
                for (var i = 0; i < other.valueCount; i++) {
                    rows.push({ res: other.value, key: `${other.value}-${i}` });
                }
            }
        }
        return rows;
    };

    const cols = [
        {
            name: 'res',
            label: 'Text Response',
        },
    ];

    const tableData = getData(data);

    return <Table data={tableData} rowKey="key" columns={cols} showPerPageOptions={false} isCsvDownload />;
};

OtherMeetingAttendanceTable.propTypes = {
    data: PropTypes.object,
};

export default OtherMeetingAttendanceTable;
