import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const MonthsInWraparoundChart = props => {
    const { data } = props;

    const getData = d => {
        const bars = [];
        const months = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0,
            6: 0,
            7: 0,
            8: 0,
            9: 0,
            10: 0,
            11: 0,
            12: 0,
            13: 0,
            14: 0,
            15: 0,
            16: 0,
            17: 0,
            18: 0,
            19: 0,
            20: 0,
            21: 0,
            22: 0,
            23: 0,
            '24+': 0,
        };
        if (data.flows.assignmentProgressSummary?.frequency) {
            for (const cc of data.flows.assignmentProgressSummary.frequency) {
                for (const month of cc.frequency) {
                    const key = month.value.split('.')[0];
                    if (parseInt(key, 10) > 23) {
                        months['24+'] += month.valueCount;
                    } else {
                        months[key] += month.valueCount;
                    }
                }
            }
        }
        for (const m of Object.keys(months)) {
            bars.push({
                months: m,
                count: months[m],
            });
        }

        return bars;
    };

    const chartData = getData(data);

    if (!chartData.length > 0) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    return (
        <div style={{ width: '770px', height: '300px' }}>
            <ResponsiveBar
                indexBy="months"
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={['count']}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 30, left: 50 }}
            />
        </div>
    );
};

MonthsInWraparoundChart.propTypes = {
    data: PropTypes.object,
};

export default MonthsInWraparoundChart;
