import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const LengthOfMeetingTable = props => {
    const { groupLevel, data } = props;

    const getData = d => {
        const rows = [];
        let longestMeeting = 0;
        let shortestMeeting = Infinity;
        for (var group of d.groups[groupLevel + 'List']) {
            const siteMinLength = parseInt(group.descendantRoles.youthSummary.meetingLengthMin, 10);
            const siteMaxLength = parseInt(group.descendantRoles.youthSummary.meetingLengthMax, 10);
            let {
                meetingLengthAvg,
                meetingLengthMax,
                meetingLengthMin,
            } = group.descendantRoles.youthSummary;
            meetingLengthAvg = parseInt(meetingLengthAvg, 10);
            meetingLengthMax = parseInt(meetingLengthMax, 10);
            meetingLengthMin = parseInt(meetingLengthMin, 10);
            if (siteMinLength < shortestMeeting) {
                shortestMeeting = siteMinLength;
            }
            if (siteMaxLength > longestMeeting) {
                longestMeeting = siteMaxLength;
            }

            rows.push({
                group: group.group.label,
                mean: `${round(meetingLengthAvg)}`,
                range: `${round(meetingLengthMin)} - ${round(meetingLengthMax)}`,
            });
        }

        rows.push({
            group: 'Total: ',
            mean: `${round(data.groups[groupLevel + 'Summary'].siteMLAvg)}`,
            range: `${round(shortestMeeting === Infinity ? 0 : shortestMeeting)} - ${round(
                longestMeeting
            )}`,
        });

        return rows;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        {
            name: 'mean',
            label: 'Mean',
            tooltip: 'Length of meeting is reported in minutes',
        },
        {
            name: 'range',
            label: 'Range',
        },
    ];

    const tableData = getData(data);
    const totalRow = tableData.pop();

    return (
        <Table
            data={tableData}
            rowKey="group"
            columns={cols}
            showPerPageOptions={false}
            footer={totalRow}
            isCsvDownload
        />
    );
};

LengthOfMeetingTable.propTypes = {
    data: PropTypes.object,
    groupLevelMap: PropTypes.object,
    groupLevel: PropTypes.string,
};

export default LengthOfMeetingTable;
