import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';
import { round } from 'lodash';

const OverallKeyElementChart = props => {
    const { data, subscales, groupLevelMap } = props;

    const getData = d => {
        const rows = [];
        const numItems = getNumItems();
        const numKeyItems = getNumKeyItems();
        for (var group of Object.keys(groupLevelMap)) {
            let groupOverall = null;
            let groupKeyElem = null;
            let numSitesInGroup = 0;
            for (var site of d.groups.siteList) {
                if (groupLevelMap[group].includes(site.group.groupId)) {
                    numSitesInGroup += 1;
                    for (var subscale of subscales) {
                        for (var item of subscale.items) {
                            const score = site.descendantRoles.youthSummary[item];
                            if (score !== null) {
                                groupOverall += score;
                                if (subscale.keyElement) {
                                    groupKeyElem += score;
                                }
                            }
                        }
                    }
                }
            }

            if (numSitesInGroup > 0) {
                rows.push({
                    group,
                    overall: groupOverall !== null ? groupOverall / (numItems * numSitesInGroup) : null,
                    keyElements: groupKeyElem !== null ? groupKeyElem / (numKeyItems * numSitesInGroup) : null,
                });
            }
        }
        let totalScore = 0;
        let totalKeyElements = 0;
        const nullScores = {};
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if (row.overall === null) {
                if (!nullScores.overall) {
                    nullScores.overall = [];
                }
                nullScores.overall.push(i);
            } else {
                totalScore += row.overall;
            }
            if (row.keyElements === null) {
                if (!nullScores.keyElements) {
                    nullScores.keyElements = [];
                }
                nullScores.keyElements.push(i);
            } else {
                totalKeyElements += row.keyElements;
            }
        }
        const numNullOverall = nullScores.overall ? nullScores.overall.length : 0;
        const numNullKeyElm = nullScores.keyElements ? nullScores.keyElements : 0;
        return [
            {
                group: 'Overall TOM 2.0 Score',
                average: round((totalScore / (rows.length - numNullOverall)) * 100, 2),
            },
            {
                group: 'Key Elements Score',
                average: round((totalKeyElements / (rows.length - numNullKeyElm)) * 100, 2),
            },
        ];
    };

    const getNumItems = () => {
        let total = 0;
        for (var sub of subscales) {
            total += sub.items.length;
        }
        return total;
    };

    const getNumKeyItems = () => {
        let total = 0;
        for (var sub of subscales) {
            if (sub.keyElement) {
                total += sub.items.length;
            }
        }
        return total;
    };

    const chartData = getData(data);

    if (!chartData.length > 0) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    const longestLabel = Math.max(
        ...chartData.map(o => {
            return o.group.length;
        })
    );

    return (
        <div style={{ width: '770px', height: `${300 + 6.5 * longestLabel}px` }}>
            <ResponsiveBar
                indexBy="group"
                maxValue={100}
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={['average']}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 6.5 * longestLabel, left: 50 }}
            />
        </div>
    );
};

OverallKeyElementChart.propTypes = {
    data: PropTypes.object,
    subscales: PropTypes.array,
    groupLevelMap: PropTypes.object,
    hiddenChart: PropTypes.func,
};

export default OverallKeyElementChart;
