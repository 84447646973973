import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';
const { tom20Questions } = window.cccisd.appDefs;

const ItemTable = props => {
    const { groupLevel, groupLevelMap, data, subscale } = props;

    const getData = d => {
        const rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            let groupItemTotal = {};
            let groupTotalN = 0;
            const sites = d.groups.siteList.filter(s => groupLevelMap[group].includes(s.group.groupId));
            for (var site of sites) {
                for (var item of subscale.items) {
                    const score = site.descendantRoles.youthSummary[item];
                    if (groupItemTotal[item]) {
                        groupItemTotal[item] += score;
                    } else {
                        groupItemTotal[item] = score;
                    }
                }
                groupTotalN += site.descendantRoles.youthCount && site.descendantRoles.youthCount;
            }
            let row = { group };
            for (var i of Object.keys(groupItemTotal)) {
                row[i] = {
                    score: groupItemTotal[i] !== null ? round(100 * (groupItemTotal[i] / sites.length), 2) : null,
                    nVal: round(groupTotalN, 2),
                };
            }
            rows.push(row);
        }
        return rows.filter(row =>
            Object.keys(row).some(key => {
                if (key !== 'group') {
                    return row[key].score >= 0 || row[key].score === null;
                }
                return false;
            })
        );
    };

    const getTotal = td => {
        const totals = {};
        const nullScores = {};

        for (let i = 0; i < td.length; i++) {
            const row = td[i];
            for (let key of Object.keys(row)) {
                if (key !== 'group') {
                    const score = row[key].score;
                    if (score !== null) {
                        if (totals[key] || totals[key] === 0) {
                            totals[key] += score;
                        } else {
                            totals[key] = score;
                        }
                    } else {
                        if (!nullScores[key]) {
                            nullScores[key] = [];
                        }
                        nullScores[key].push(i);
                    }
                }
            }
        }

        let total = { group: 'Total: ' };

        for (let sub of Object.keys(totals)) {
            const numNull = nullScores[sub] ? nullScores[sub].length : 0;
            if (numNull === td.length) {
                total[sub] = ''; // Set total[sub] to an empty string for keys with all null scores
            } else {
                total[sub] = `${round(totals[sub] / (td.length - numNull), 2)}%`;
            }
        }

        return total;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        ...subscale.items.map(i => ({
            name: i,
            label: i.split('').reverse().join(''),
            tooltip: tom20Questions[i.toLowerCase().split('').reverse().join('')].question,
            render: (value, row) => (value.score !== null ? `${value.score}%, N=${value.nVal}` : 'N/A'),
        })),
    ];

    const tableData = getData(data);
    const totalRow = getTotal(tableData);

    let showNotice = false;
    for (const row of tableData) {
        if (!showNotice) {
            if (Object.keys(row).some(key => row[key].score === null)) {
                showNotice = true;
            }
        }
    }
    return (
        <>
            <Table
                data={tableData}
                rowKey="group"
                columns={cols}
                footer={totalRow}
                showPerPageOptions={false}
                isCsvDownload
            />
            {showNotice && (
                <p>
                    *All TOMs for this item were scored as &quot;N/A&quot;, thus a summary score can not be calculated
                </p>
            )}
        </>
    );
};

ItemTable.propTypes = {
    data: PropTypes.object,
    groupLevelMap: PropTypes.object,
    groupLevel: PropTypes.string,
    subscale: PropTypes.object,
};

export default ItemTable;
