import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { showTable, showChart, renderSubtitle, renderDescription, hiddenChart } from 'js/vendor/reports/helpers.js';

import query from './query.graphql';
import ItemSubscaleChart from './charts/ItemSubscaleChart';
import OverallSubscaleChart from './charts/OverallSubscaleChart';
import ItemSubscaleTable from './tables/ItemSubscaleTable';
import ItemQuestionTable from './tables/ItemQuestionTable';

const SecAInvolvementSection = props => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const items = ['A1', 'A2', 'A3', 'A4'];
    const respondents = [
        {
            id: '5',
            label: 'Youth',
        },
        {
            id: '3',
            label: 'Caregiver',
        },
    ].filter(r => props.filters.respondentSelectorField.includes(r.id));

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            getSectionData();
        }
    }, [
        props.filters.evalCycleSelectorField,
        props.filters.siteSelectorField,
        props.youthPawnIds,
        props.filters.respondentSelectorField,
    ]);

    // should not show charts with more than X care coordinators
    const maxCC = 10;
    const hideChart =
        props.filters.careCoordSelectorField && Object.keys(props.filters.careCoordSelectorField).length > maxCC;

    const getSectionData = async () => {
        const variables = {
            deploymentIds: props.filters.evalCycleSelectorField || [],
            ccList: props.filters.careCoordSelectorField.map(cc => {
                const pawnDashIndex = cc.lastIndexOf('-');
                return cc.substring(0, pawnDashIndex);
            }),
        };
        const response = await taskMasterClient.query({
            query,
            variables,
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        setData(response.data);
        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };

    return (
        <>
            {renderSubtitle('Item Responses')}
            {showChart(<OverallSubscaleChart data={data} items={items} respondents={respondents} />, loading)}

            {respondents.map(r => (
                <div key={r.id} style={{ marginTop: '4em' }}>
                    {renderSubtitle(`Section A Item Responses for WFI-EZ ${r.label} Form`)}
                    {renderDescription(
                        `The graph and table below present the percentage of ${r.label}s that responded "Yes" to each Section A item.`
                    )}

                    {items.map(item => (
                        <div key={item}>
                            {renderSubtitle(`Item ${item}`)}
                            {hideChart
                                ? hiddenChart(`Chart hidden for reports with more than ${maxCC} Care Coordinators`)
                                : showChart(<ItemSubscaleChart item={item} data={data} respondent={r} />, loading)}
                        </div>
                    ))}
                    {showTable(<ItemSubscaleTable items={items} data={data} respondent={r} />, loading)}
                    {renderDescription('The table below presents the survey question for each item.')}
                    <ItemQuestionTable items={items} respondent={r} />
                </div>
            ))}
        </>
    );
};

SecAInvolvementSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded })(SecAInvolvementSection);
