import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import InfoTooltip from 'js/vendor/reports/components/InfoTooltip';

const ExclusionInfoTable = props => {
    const { data, respondentList } = props;

    const filters = [
        {
            name: 'invalidA',
            text: 'Invalid Section A - Missing Substantial Information',
            tooltip: 'Section A is not scored if user missed 1 or more items',
            y: 'invalidAY',
            cg: 'invalidACG',
        },
        {
            name: 'invalidBMissing',
            text: 'Invalid Section B - Missing Substantial Information',
            tooltip:
                'Entire record is not scored if Section B user responded "Don\'t Know" or is missing 8 or more items.',
            y: 'invalidBMissingY',
            cg: 'invalidBMissingCG',
        },
        {
            name: 'invalidBUniform',
            text: 'Invalid Section B - Uniform Rating Pattern',
            tooltip:
                'Entire record is not scored if all items in Section 8 have the same response, regaurdless of what the response is.',
            y: 'invalidBSameY',
            cg: 'invalidBSameCG',
        },
        {
            name: 'invalidC',
            text: 'Invalid Section C - Missing Substantial Information',
            tooltip:
                'Section C is not scored if user responded "Don\'t Know" or is missing 1 or more items. Section C is only valid for Youth and Caregivers.',
            y: 'invalidCY',
            cg: 'invalidCCG',
        },
        {
            name: 'invalidD',
            text: 'Invalid Section D - Missing Substantial Information',
            tooltip:
                'Section D is not scored if user responded "Don\'t Know" or is missing 2 or more items. Section D is only valid for Caregivers and Care Coordinators.',
            noYouth: true,
            y: 'N/A',
            cg: 'invalidDCG',
        },
    ];

    const getData = () => {
        let rows = [];
        let totals = {
            invalidAY: 0,
            invalidACG: 0,
            invalidBMissingY: 0,
            invalidBMissingCG: 0,
            invalidBSameY: 0,
            invalidBSameCG: 0,
            invalidCCG: 0,
            invalidCY: 0,
            invalidDCG: 0,
        };
        for (var cc of Object.keys(data)) {
            for (var filter of filters) {
                for (var key of ['y', 'cg']) {
                    if (!filter.noYouth || key === 'cg') {
                        if (data[cc][filter[key]] > 0) {
                            totals[filter[key]] += data[cc][filter[key]];
                        }
                    }
                }
            }
        }
        for (var row of filters) {
            rows.push({
                filter: row,
                youth: row.noYouth ? 'N/A' : totals[row.y],
                caregiver: totals[row.cg],
                key: row.cg,
            });
        }
        return rows;
    };

    const cols = [
        {
            name: 'filter',
            label: 'Filter',
            render(value, row) {
                return (
                    <div style={{ display: 'flex' }}>
                        {value.text}
                        <InfoTooltip text={value.tooltip} />
                    </div>
                );
            },
        },
        { id: '5', name: 'youth', label: 'Youth' },
        { id: '3', name: 'caregiver', label: 'Caregiver' },
    ].filter(c => c.name === 'filter' || respondentList.includes(c.id));

    const tableData = getData();

    return <Table data={tableData} rowKey="key" columns={cols} showPerPageOptions={false} isCsvDownload />;
};

ExclusionInfoTable.propTypes = {
    data: PropTypes.object,
    respondentList: PropTypes.array,
};

export default ExclusionInfoTable;
