import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const BenchmarkTable = props => {
    const { wfiBenchmarks } = window.cccisd.appDefs;

    const getData = () => {
        let rows = [];
        for (var level of props.levels) {
            let row = { element: level.label };
            for (var scale of props.scales) {
                const benchmarkLower = wfiBenchmarks[`${props.type}_${level.label.replace(' ', '')}_lower`][scale.name];
                const benchmarkUpper = wfiBenchmarks[`${props.type}_${level.label.replace(' ', '')}_upper`][scale.name];

                let benchmark = `${benchmarkLower} - ${benchmarkUpper}`;

                if (wfiBenchmarks[`${props.type}_${level.label.replace(' ', '')}_lower`][scale.name] === '0') {
                    benchmark = `< ${benchmarkUpper}`;
                }

                if (wfiBenchmarks[`${props.type}_${level.label.replace(' ', '')}_upper`][scale.name] === '100') {
                    benchmark = `${benchmarkLower}+`;
                }

                // const benchmark =
                //     wfiBenchmarks[
                //         `${props.type}_${level.label.replace(' ', '')}_${
                //             props.upper ? 'upper' : 'lower'
                //         }`
                //     ][scale.name];

                row[scale.name] = `${benchmark}`;
            }
            rows.push(row);
        }
        return rows;
    };

    // const cols = [{ name: 'element', label: 'Key Element' }, ...props.levels];
    const cols = [{ name: 'element', label: 'Category' }, ...props.scales];

    return <Table data={getData()} rowKey="element" columns={cols} isCsvDownload showPerPageOptions={false} />;
};

BenchmarkTable.propTypes = {
    upper: PropTypes.bool,
    scales: PropTypes.array,
    type: PropTypes.string,
    levels: PropTypes.array,
};

export default BenchmarkTable;
