import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round, mean } from 'lodash';

const items3pt = ['D5', 'D6', 'D7', 'D8', 'D9'];

const ItemTable = props => {
    const { wfiQuestions } = window.cccisd.appDefs;
    var { data, items, respondent } = props;

    const getData = () => {
        let rows = [];
        if (data.flows[respondent.label]) {
            if (!data.flows[respondent.label].frequency) {
                return rows;
            }

            for (const cc of data.flows[respondent.label].frequency) {
                if (cc.value) {
                    const row = { cc: cc.value, careCoord: cc.value.split(', ')[0] };
                    for (const i of items) {
                        const sum = cc[`${i}sum`];
                        const count = cc[`${i}count`];
                        if (items3pt.includes(i)) {
                            row[i] = { sum: round(sum / count, 2), percent: false, count };
                        } else {
                            row[i] = {
                                sum,
                                percent: round((sum / count) * 100, 2),
                                count,
                            };
                        }
                    }
                    rows.push(row);
                }
            }
        }

        return rows;
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        ...items.map(i => {
            const q = wfiQuestions[`${props.respondent.label}_${i}`];
            return {
                name: i,
                label: i,
                tooltip: q && q.question,
                render: val =>
                    val.count
                        ? `${val.sum}${!items3pt.includes(i) ? ` (${val.percent}%)` : ''}, N=${val.count}`
                        : 'N/A*',
            };
        }),
    ];

    const getTotal = d => {
        const tr = { careCoord: 'Total:' };
        for (const i of items) {
            const key = items3pt.includes(i) ? 'sum' : 'percent';

            const scores = [];
            for (const r of d) {
                if (r[i][key] || r[i][key] === 0) {
                    scores.push(r[i][key]);
                }
            }

            tr[i] = scores.length > 0 ? `${round(mean(scores), 2)}${key === 'percent' ? '%' : ''}` : 'N/A*';
        }

        return tr;
    };

    const tableData = getData();
    const totalRow = getTotal(tableData);
    const showNotice = tableData.some(row =>
        cols.some(col => Number.isNaN(row[col.name].percent) || Number.isNaN(row[col.name].sum))
    );

    return (
        <>
            <Table
                data={tableData}
                rowKey="cc"
                orderBy="careCoord"
                columns={cols}
                footer={totalRow}
                perPage={5000}
                perPageOptions={[250, 500, 1000, 5000]}
                isCsvDownload
                csvFilename="section-D-items"
            />
            {showNotice && (
                <p>
                    * Indicates all WFI-EZs for this item were scored as &quot;N/A&quot;, thus a summary score can not
                    be calculated
                </p>
            )}
        </>
    );
};

ItemTable.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.object,
    items: PropTypes.array,
};

export default ItemTable;
