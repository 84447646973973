import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const ItemMeanTable = props => {
    const { wfiQuestions } = window.cccisd.appDefs;
    const { items, respondent } = props;

    const getData = () => {
        let rows = [];
        for (var item of items) {
            rows.push({
                item,
                Item_Upper: wfiQuestions[`${respondent.label}_${item.replace('*', '')}`].Item_Upper,
                Item_Lower: wfiQuestions[`${respondent.label}_${item.replace('*', '')}`].Item_Lower,
                WS_Mean: wfiQuestions[`${respondent.label}_${item.replace('*', '')}`].WS_Mean,
                WS_SD: wfiQuestions[`${respondent.label}_${item.replace('*', '')}`].WS_SD,
            });
        }
        return rows;
    };

    const cols = [
        { name: 'item', label: 'Item' },
        { name: 'Item_Upper', label: `Upper` },
        { name: 'Item_Lower', label: `Lower` },
        { name: 'WS_Mean', label: `Wrapstat Mean` },
        {
            name: 'WS_SD',
            label: `Wrapstat Standard Deviation`,
        },
    ];
    return (
        <Table isCsvDownload columns={cols} data={getData()} rowKey="item" orderBy="item" showPerPageOptions={false} />
    );
};

ItemMeanTable.propTypes = {
    items: PropTypes.array,
    respondent: PropTypes.object,
};

export default ItemMeanTable;
