import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const OptOutReasonTable = props => {
    const { data, groupLevel, groupLevelMap } = props;
    const getData = () => {
        let rows = [];
        let d = {};
        for (var cycle of data) {
            const siteId = parseInt(cycle.siteId, 10);
            for (var group of Object.keys(groupLevelMap)) {
                if (groupLevelMap[group].includes(siteId)) {
                    const { unableToAttend, noConsent, institution, discharged, other } = cycle;
                    if (d[group]) {
                        d[group].unableToAttend += unableToAttend;
                        d[group].noConsent += noConsent;
                        d[group].institution += institution;
                        d[group].discharged += discharged;
                        d[group].other += other;
                    } else {
                        d[group] = {
                            unableToAttend,
                            noConsent,
                            institution,
                            discharged,
                            other,
                        };
                    }
                    break;
                }
            }
        }
        for (var g of Object.keys(d)) {
            const { unableToAttend, noConsent, institution, discharged, other } = d[g];

            rows.push({
                group: g,
                unableToAttend,
                noConsent,
                institution,
                discharged,
                other,
            });
        }
        return rows;
    };

    const getTotalRow = d => {
        let foot = {
            group: 'Total:',
            unableToAttend: 0,
            noConsent: 0,
            institution: 0,
            discharged: 0,
            other: 0,
        };
        for (var row of d) {
            foot.unableToAttend += row.unableToAttend;
            foot.noConsent += row.noConsent;
            foot.institution += row.institution;
            foot.discharged += row.discharged;
            foot.other += row.other;
        }
        return foot;
    };

    const cols = [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        { name: 'unableToAttend', label: 'Observer unable to attend meeting' },
        { name: 'noConsent', label: 'Team did not consent to meeting observation' },
        { name: 'institution', label: 'Youth institutionalized' },
        { name: 'discharged', label: 'Youth discharged' },
        { name: 'other', label: 'Other' },
    ];

    const tableData = getData();
    const footer = getTotalRow(tableData);

    return (
        <Table
            data={tableData}
            rowKey="group"
            columns={cols}
            showPerPageOptions={false}
            footer={footer}
            isCsvDownload
        />
    );
};

OptOutReasonTable.propTypes = {
    data: PropTypes.array,
    groupLevel: PropTypes.string,
    groupLevelMap: PropTypes.object,
};

export default OptOutReasonTable;
