import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import query from './query.graphql';
import { client } from 'cccisd-apollo';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import {
    showChart,
    showTable,
    renderTitle,
    renderSubtitle,
    getGroupLevelMap,
    renderDescription,
    hiddenChart,
} from 'js/vendor/reports/helpers.js';
import { getTeamMemberFilter } from 'js/vendor/reports/reportTemplates/WFI-EZ/Youth/queryFilters.js';
import QuestionTable from '../../tables/QuestionTable';
import SummaryChart from './charts/SummaryChart';
import RespondentItemChart from './charts/RespondentItemChart';
import ItemQuestionTable from './tables/ItemQuestionTable';

// import ItemQuestionMeans from '../../tables/ItemQuestionMeans';

const respondents = {
    2: { name: 'careCoordinator', label: 'Care Coordinator', short: 'cc' },
    3: { name: 'caregiver', label: 'Caregiver', short: 'cg' },
    4: { name: 'teamMember', label: 'Team Member', short: 'tm' },
    5: { name: 'youth', label: 'Youth', short: 'y' },
};

const items = ['A1', 'A2', 'A3', 'A4'];

const FundamentalsSection = props => {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    // should show charts with more than X groups
    const maxGroups = 10;
    const hideChart = groupLevelMap && Object.keys(groupLevelMap).length > maxGroups;
    const plural = {
        site: 'sites',
        agency: 'agencies',
        organization: 'organizations',
        collaborator: 'collaborators',
    };

    const { evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField } =
        props.filters;

    const groupLevel = props.groupLevel || 'group';

    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            setGroupLevelMap(getGroupLevelMap(props.groups, groupLevel, siteSelectorField));
            getData();
        }
    }, [evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField]);

    const doQuery = () => {
        return client.query({
            query,
            variables: {
                pawnIds: props.youthPawnIds,
                deploymentIds: evalCycleSelectorField || [],
                filter: getTeamMemberFilter(teamMemberSelectorField),
                siteIds: siteSelectorField || [],
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
    };

    const CACHE_DATA = false;
    // localStorage.setItem('sectionA', false);

    const getData = async () => {
        let response;
        if (CACHE_DATA) {
            const cache = JSON.parse(localStorage.getItem('sectionA'));
            if (cache) {
                response = cache;
            } else {
                response = await doQuery();
                localStorage.setItem('sectionA', JSON.stringify(response));
            }
        } else {
            response = await doQuery();
        }
        setData(response.data);
        setLoading(false);
        props.setWidgetLoaded(props.section.name + 'Section');
    };

    return (
        <>
            <div>
                {renderTitle('Item Responses')}

                {showChart(
                    <SummaryChart
                        data={data}
                        respondents={respondents}
                        items={items}
                        respondentSelectorField={respondentSelectorField}
                        groupLevelMap={groupLevelMap}
                    />,
                    loading
                )}
            </div>

            {respondentSelectorField.map(a => {
                return (
                    <div key={a}>
                        <div>
                            {renderSubtitle(`Section A Item Responses for WFI-EZ ${respondents[a].label} Form`)}
                            {renderDescription(
                                `The graph and table below present the percentage of ${respondents[a].label}s that responded "Yes" to each Section A item.`
                            )}

                            {showChart(
                                <RespondentItemChart
                                    data={data}
                                    items={items}
                                    assignmentId={a}
                                    respondents={respondents}
                                    groupLevel={groupLevel}
                                    groupLevelMap={groupLevelMap}
                                    renderSubtitle={renderSubtitle}
                                    hiddenChart={() =>
                                        hiddenChart(
                                            `Chart hidden for reports with more than ${maxGroups} ${plural[groupLevel]}`
                                        )
                                    }
                                    hideChart={hideChart}
                                />,
                                loading
                            )}
                        </div>

                        {showTable(
                            <ItemQuestionTable
                                data={data}
                                respondents={respondents}
                                items={items}
                                assignmentId={a}
                                groupLevel={groupLevel}
                                groupLevelMap={groupLevelMap}
                            />,
                            loading
                        )}
                        {renderDescription('The table below presents the survey question for each item.')}
                        {showTable(
                            <QuestionTable items={items} type={respondents[a].label.replace(' ', '')} />,
                            loading
                        )}
                    </div>
                );
            })}
        </>
    );
};

FundamentalsSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    setWidgetLoaded: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(FundamentalsSection);
