export const rowData = [
    { name: 'cc', label: 'Care Coordinator', alt: 'CC' },
    {
        name: 'y',
        label: 'Youth',
        alt: 'Y',
    },
    {
        name: 'prnt',
        label: 'Parent (birth or adoptive)',
        alt: 'Prnt',
    },
    {
        name: 'fprnt',
        label: 'Foster parent',
        alt: 'FPrnt',
    },
    {
        name: 'cg',
        label: 'Caregiver (if different from parent or foster parent)',
        alt: 'CG',
    },
    {
        name: 'sib',
        label: 'Sibling of the youth (17 or younger)',
        alt: 'Sib',
    },
    {
        name: 'sch',
        label: 'School representative',
        alt: 'Sch',
    },
    {
        name: 'jj',
        label: 'Juvenile justice representative/probation officer',
        alt: 'JJ',
    },
    {
        name: 'ss',
        label: 'Social services representative/social worker',
        alt: 'SS',
    },
    {
        name: 'casa',
        label: 'Court appointed special advocate (CASA)',
        alt: 'CASA',
    },
    {
        name: 'attrny',
        label: 'Attorney',
        alt: 'Attrny',
    },
    {
        name: 'mhp',
        label: 'Mental health provider',
        alt: 'MHP',
    },
    {
        name: 'mha',
        label: 'Mental health agency representative',
        alt: 'MHA',
    },
    {
        name: 'med',
        label: 'Medical provider',
        alt: 'Med',
    },
    {
        name: 'fs',
        label: 'Family support partner or advocate (peer)',
        alt: 'FS',
    },
    {
        name: 'ys',
        label: 'Youth support partner or advocate (peer)',
        alt: 'YS',
    },
    {
        name: 'oldsib',
        label: 'Older sibling of the youth (18 or older)',
        alt: 'OldSib',
        natural: true,
    },
    {
        name: 'extfam',
        label: 'Extended family member',
        alt: 'ExtFam',
        natural: true,
    },
    {
        name: 'frndp',
        label: 'Friend of parent/caregiver',
        alt: 'FrndP',
        natural: true,
    },
    {
        name: 'frndy',
        label: 'Friend of youth',
        alt: 'FrndY',
        natural: true,
    },
    {
        name: 'oth',
        label: 'Other natural or community support',
        alt: 'Oth',
        natural: true,
    },
    {
        name: 'user2',
        label: 'Other user-specified support',
        alt: '',
        natural: true,
    },
];
