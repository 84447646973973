import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Collapsable from 'js/vendor/reports/components/Collapsable';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';

import EvaluatorTypeChart from './charts/EvaluatorTypeChart';
import EvaluatorTypeTable from './tables/EvaluatorTypeTable';
import MeetingFormatChart from './charts/MeetingFormatChart';
import MeetingFormatTable from './tables/MeetingFormatTable';
import TypeOfMeetingChart from './charts/TypeOfMeetingChart';
import TypeOfMeetingTable from './tables/TypeOfMeetingTable';
import OtherTypeOfMeetingTable from './tables/OtherTypeOfMeetingTable';
import LengthOfMeetingTable from './tables/LengthOfMeetingTable';
import MeetingAttendanceChart from './charts/MeetingAttendanceChart';
import CoreTeamAndProfessionalSupportsTable from './tables/CoreTeamAndProfessionalSupportsTable';
import NaturalSupportsTable from './tables/NaturalSupportsTable';
import OtherMeetingAttendanceTable from './tables/OtherMeetingAttendanceTable';

import query from './graphql/query.graphql';

import { showTable, showChart, renderTitle, renderSubtitle, renderDescription } from 'js/vendor/reports/helpers.js';

function TeamMemberAttendanceSection(props) {
    const [sectionData, setSectionData] = useState(null);
    const [data, setData] = useState(null);

    const [loading, setLoading] = useState(true);

    const cache = false;

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            if (cache) {
                const cd = JSON.parse(localStorage.getItem('teamAttendance'));
                setSectionData(formatData(cd.data));
                setData(cd.data);
                setLoading(false);
            } else {
                getSectionData();
            }
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getSectionData = async () => {
        const response = await taskMasterClient.query({
            query,
            variables: {
                pawnIds: props.youthPawnIds,
                deploymentIds: props.filters.evalCycleSelectorField || [],
                ccList: props.filters.careCoordSelectorField.map(cc => {
                    const pawnDashIndex = cc.lastIndexOf('-');
                    return cc.substring(0, pawnDashIndex);
                }),
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        localStorage.setItem('teamAttendance', JSON.stringify(response));
        setSectionData(formatData(response.data));
        setData(response.data);
        props.setWidgetLoaded(props.section.name + 'Section');
        setLoading(false);
    };

    const formatData = d => {
        const keys = ['external', 'inperson', 'internal', 'telehealth', 'initial', 'followup', 'transition', 'other'];
        let ccMap = {};

        for (const key of keys) {
            if (d.flows[key]?.frequency) {
                for (const cc of d.flows[key].frequency) {
                    if (cc.value) {
                        if (ccMap[cc.value]) {
                            if (ccMap[cc.value][key]) {
                                ccMap[cc.value][key] += cc.valueCount;
                            } else {
                                ccMap[cc.value][key] = cc.valueCount;
                            }
                        } else {
                            let ccName = 'MISSING';
                            let ccEmail = cc.value.split(', ')[cc.value.split(', ').length - 1];
                            if (cc.value.split(', ').length > 1) {
                                ccName = cc.value.split(', ')[0];
                            }
                            ccMap[cc.value] = {
                                ccName,
                                ccEmail,
                            };
                            ccMap[cc.value][key] = cc.valueCount;
                        }
                    }
                }
            }
        }
        // meeting length
        if (d.flows.meeting?.meetingLength) {
            for (const cc of d.flows.meeting.meetingLength) {
                if (cc.value) {
                    for (const f of cc.frequency) {
                        if (f.value) {
                            const v = parseInt(f.value, 10);
                            for (let i = 0; i < f.valueCount; i++) {
                                if (ccMap[cc.value].meetingLengths) {
                                    ccMap[cc.value].meetingLengths.push(v);
                                } else {
                                    ccMap[cc.value].meetingLengths = [v];
                                }
                            }
                        }
                    }
                }
            }
        }
        return ccMap;
    };

    const proSups = [
        {
            label: 'Care Coordinator',
            name: 'cc',
        },
        {
            label: 'Youth',
            name: 'y',
        },
        {
            label: 'Parent (birth or adoptive)',
            name: 'prnt',
        },
        {
            label: 'Foster parent',
            name: 'fprnt',
        },
        {
            label: 'Caregiver (if different from parent or foster parent)',
            name: 'cg',
        },
        {
            label: 'Sibling of the youth (17 or younger)',
            name: 'sib',
        },
        {
            label: 'School representative',
            name: 'sch',
        },
        {
            label: 'Juvenile justice representative/probation officer',
            name: 'jj',
        },
        {
            label: 'Social services representative/social worker',
            name: 'ss',
        },
        {
            label: 'Court appointed special advocate (CASA)',
            name: 'casa',
        },
        {
            label: 'Attorney',
            name: 'attrny',
        },
        {
            label: 'Mental health provider',
            name: 'mhp',
        },
        {
            label: 'Mental health agency representative',
            name: 'mha',
        },
        {
            label: 'Medical provider',
            name: 'med',
        },
        {
            label: 'Family support partner or advocate (peer)',
            name: 'fs',
        },
        {
            label: 'Youth support partner or advocate (peer)',
            name: 'ys',
        },
    ];
    const nattySups = [
        {
            label: 'Older sibling of the youth (18 or older)',
            name: 'oldsib',
        },
        {
            label: 'Extended family member',
            name: 'extfam',
        },
        {
            label: 'Friend of parent/caregiver',
            name: 'frndp',
        },
        {
            label: 'Friend of youth',
            name: 'frndy',
        },
        {
            label: 'Other natural or community support',
            name: 'oth',
        },
        {
            label: 'Other user-specified support',
            name: 'user2',
        },
    ];

    return (
        <>
            {renderTitle('Evaluator Type')}
            {showChart(<EvaluatorTypeChart data={sectionData} />, loading)}
            {showTable(<EvaluatorTypeTable data={sectionData} />, loading)}

            {renderTitle(`Meeting Format`)}
            {showChart(<MeetingFormatChart data={sectionData} />, loading)}
            {showTable(<MeetingFormatTable data={sectionData} />, loading)}
            {renderTitle(`Type of Meeting`)}
            {showChart(<TypeOfMeetingChart data={sectionData} />, loading)}
            {showTable(<TypeOfMeetingTable data={sectionData} />, loading)}
            {showTable(
                <Collapsable title={`"Other" responses`} collapsed content={<OtherTypeOfMeetingTable data={data} />} />,
                loading
            )}
            {renderTitle('Length of Meeting in Minutes')}
            {showTable(<LengthOfMeetingTable data={sectionData} />, loading)}
            {renderTitle('Meeting Attendance')}
            {renderDescription(
                'This section displays information on who was part of the Wraparound teams for the completed TOM 2.0s. The tables below show both the total number of Wraparound team members by type and the number that were actually present at the observed meeting.'
            )}
            {showChart(<MeetingAttendanceChart data={data} proSups={proSups} nattySups={nattySups} />, loading)}
            {renderSubtitle('Core Team and Professional Supports')}
            {showTable(<CoreTeamAndProfessionalSupportsTable data={data} proSups={proSups} />, loading)}
            {renderSubtitle('Natural Supports')}
            {showTable(<NaturalSupportsTable data={data} nattySups={nattySups} />, loading)}
            {showTable(
                <Collapsable
                    title={`Other "Meeting Attendance" Responses`}
                    collapsed
                    content={<OtherMeetingAttendanceTable data={data} />}
                />,
                loading
            )}
        </>
    );
}

TeamMemberAttendanceSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded })(TeamMemberAttendanceSection);
