import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { taskMasterClient } from 'cccisd-apollo';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import query from './query.graphql';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { showTable, renderSubtitle, getGroupLevelMap, renderDescription } from 'js/vendor/reports/helpers.js';
import { scales } from 'js/vendor/reports/reportTemplates/WFI-EZ/Youth/custom/sections/FidelitySectionB/index.js';

// tables
import StrengthsTable from './tables/StrengthsTable';
import AreasToImproveTable from './tables/AreasToImproveTable';

const respondents = {
    2: { name: 'careCoordinator', label: 'Care Coordinator', short: 'cc' },
    3: { name: 'caregiver', label: 'Caregiver', short: 'cg' },
    4: { name: 'teamMember', label: 'Team Member', short: 'tm' },
    5: { name: 'youth', label: 'Youth', short: 'y' },
};

const StrengthsSection = props => {
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    const { evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField } =
        props.filters;

    const groupLevel = props.groupLevel || 'group';
    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            setGroupLevelMap(getGroupLevelMap(props.groups, groupLevel, siteSelectorField));
            getData();
        }
    }, [evalCycleSelectorField, teamMemberSelectorField, siteSelectorField, respondentSelectorField]);

    const doQuery = () => {
        return taskMasterClient.query({
            query,
            variables: {
                pawnIds: props.youthPawnIds,
                deploymentIds: evalCycleSelectorField || [],
                // filter: getTeamMemberFilter(teamMemberSelectorField),
                siteIds: siteSelectorField || [],
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
    };

    const CACHE_DATA = false;
    // localStorage.setItem('sectionBStrengths', false);

    const getData = async () => {
        let response;
        if (CACHE_DATA) {
            const cache = JSON.parse(localStorage.getItem('sectionBStrengths'));
            if (cache) {
                response = cache;
            } else {
                response = await doQuery();
                localStorage.setItem('sectionBStrengths', JSON.stringify(response));
            }
        } else {
            response = await doQuery();
        }
        setData(response.data);
        setLoading(false);
        props.setWidgetLoaded(props.section.name + 'Section');
    };

    return (
        <>
            {renderDescription(
                'Relative strength and areas to improve are those items for which the item score is 0.4 above or below the Wrapstat mean.'
            )}
            {respondentSelectorField.map(id => {
                return (
                    <div key={id}>
                        <div>
                            {renderSubtitle(`Section B Strengths for WFI-EZ ${respondents[id].label} Form`)}
                            {showTable(
                                <StrengthsTable
                                    data={data}
                                    groupLevel={groupLevel}
                                    groupLevelMap={groupLevelMap}
                                    scales={scales}
                                    respondent={respondents[id]}
                                />,
                                loading
                            )}
                        </div>
                        <div>
                            {renderSubtitle(`Section B Areas to Improve for WFI-EZ ${respondents[id].label} Form`)}
                            {showTable(
                                <AreasToImproveTable
                                    data={data}
                                    groupLevel={groupLevel}
                                    groupLevelMap={groupLevelMap}
                                    scales={scales}
                                    respondent={respondents[id]}
                                />,
                                loading
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

StrengthsSection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    setWidgetLoaded: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { loadGroups, setWidgetLoaded })(StrengthsSection);
