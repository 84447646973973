import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const CommentTable = props => {
    const { data } = props;

    const getData = () => {
        let rows = [];
        for (var comment of data) {
            if (comment.value) {
                for (var i = 0; i < comment.valueCount; i++) {
                    rows.push({ comment: comment.value });
                }
            }
        }
        return rows;
    };

    const cols = [{ name: 'comment', label: 'Text Response' }];

    return (
        <Table
            data={getData()}
            rowKey="comment"
            columns={cols}
            showPerPageOptions={false}
            isCsvDownload
        />
    );
};

CommentTable.propTypes = {
    data: PropTypes.array,
};

export default CommentTable;
