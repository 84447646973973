import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const DataCollectionInfoTable = props => {
    const { data, suffix } = props;

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        { name: `notStarted${suffix}`, sort: true, label: 'Number of Forms Not Started' },
        { name: `incomplete${suffix}`, sort: true, label: 'Number of Forms Incomplete' },
        { name: `completed${suffix}`, sort: true, label: 'Number of Forms Completed' },
        { name: `optedOut${suffix}`, sort: true, label: 'Number of Forms Opt-out' },
    ];

    const getData = () => {
        let rows = [];
        for (var cc of Object.keys(data)) {
            if (cc !== 'otherReasons') {
                let row = { careCoord: cc.split(', ')[0] };
                for (var col of cols) {
                    if (col.name !== 'careCoord') {
                        row[col.name] = data[cc][col.name] || 0;
                    }
                }
                rows.push(row);
            }
        }
        return rows;
    };

    const getTotalRow = d => {
        let foot = {
            careCoord: 'Total:',
            [`notStarted${suffix}`]: 0,
            [`incomplete${suffix}`]: 0,
            [`completed${suffix}`]: 0,
            [`optedOut${suffix}`]: 0,
        };
        for (var row of d) {
            foot[`notStarted${suffix}`] += row[`notStarted${suffix}`];
            foot[`incomplete${suffix}`] += row[`incomplete${suffix}`];
            foot[`completed${suffix}`] += row[`completed${suffix}`];
            foot[`optedOut${suffix}`] += row[`optedOut${suffix}`];
        }
        return foot;
    };

    const tableData = getData();
    const footer = getTotalRow(tableData);
    return (
        <Table
            data={tableData}
            rowKey="careCoord"
            orderBy="careCoord"
            columns={cols}
            footer={footer}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="data-collection-info"
        />
    );
};

DataCollectionInfoTable.propTypes = {
    data: PropTypes.object,
    suffix: PropTypes.string,
};

export default DataCollectionInfoTable;
