import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { connect } from 'react-redux';
import { mean, round } from 'lodash';

const ItemQuestionTable = props => {
    const { data, respondents, items, assignmentId, groupLevelMap, groupLevel } = props;

    const getData = () => {
        let rows = [];
        for (var group of Object.keys(groupLevelMap)) {
            var itemTotals = {};
            var row = { group };
            var groupN = 0;
            for (var siteId of groupLevelMap[group]) {
                const site = data.groups.siteList.find(s => siteId === s.group.groupId);
                if (site) {
                    const siteN = site.descendantRoles[respondents[assignmentId].name].totalN;
                    if (siteN) {
                        groupN += siteN;
                    }
                    for (var item of items) {
                        const siteItemMean = site ? site.descendantRoles[respondents[assignmentId].name][item] : null;
                        if (siteItemMean) {
                            if (itemTotals[item]) {
                                itemTotals[item] += siteItemMean;
                            } else {
                                itemTotals[item] = siteItemMean;
                            }
                        }
                    }
                }
            }
            if (groupN > 0) {
                for (var i of items) {
                    const countYes = itemTotals[i] || 0;
                    const percentYes = Math.round((countYes / groupN) * 100) || 0;
                    row[i] = { countYes, percentYes, groupN };
                }
                rows.push(row);
            }
        }
        return rows;
    };

    const getTotal = d => {
        const tr = { group: 'Total:' };
        for (const row of d) {
            for (const q of Object.keys(row).filter(r => r !== 'group')) {
                if (tr[q]) {
                    tr[q].push(row[q].percentYes);
                } else {
                    tr[q] = [row[q].percentYes];
                }
            }
        }
        for (const k of Object.keys(tr).filter(r => r !== 'group')) {
            tr[k] = `${round(mean(tr[k]), 2)}%`;
        }
        return tr;
    };

    const tableData = getData();
    const totalRow = getTotal(tableData);

    const tableCols = id => [
        {
            name: 'group',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        ...items.map(i => ({
            name: i,
            label: i,
            tooltip: window.cccisd.appDefs.wfiQuestions[`${respondents[id].label.replace(/\s/g, '')}_${i}`].question,
            render: (value, row) => `${value.countYes} (${value.percentYes}%), N=${value.groupN}`,
        })),
    ];

    return (
        <Table
            rowKey="group"
            columns={tableCols(assignmentId)}
            data={tableData}
            isCsvDownload
            footer={totalRow}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            csvFilename="section-A-Involvement"
        />
    );
};

ItemQuestionTable.propTypes = {
    data: PropTypes.array,
    respondents: PropTypes.object,
    items: PropTypes.array,
    assignmentId: PropTypes.number,
    groupLevelMap: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
});

export default connect(mapStateToProps, {})(ItemQuestionTable);
