import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { rowData } from './rows.js';
import { round } from 'lodash';
import { renderSubtitle } from 'js/vendor/reports/helpers.js';

const MeetingAttendanceTable = props => {
    const { data } = props;

    const getData = d => {
        let rows = [];
        let totalData = {};
        for (var rowItem of rowData) {
            for (var col of ['Team', 'Present']) {
                const key = rowItem.name + col;
                const val = d.flows.assignmentProgressSummary[key];
                if (totalData[key]) {
                    totalData[key] += parseInt(val, 10) || 0;
                } else {
                    totalData[key] = parseInt(val, 10) || 0;
                }
            }
        }
        for (var row of rowData) {
            const team = totalData[row.name + 'Team'];
            const present = totalData[row.name + 'Present'];
            let percentValue = round((present / team) * 100);
            rows.push({
                label: row.label,
                team,
                present,
                percent: percentValue && Number.isFinite(percentValue) ? `${percentValue || 0}%` : 0,
                // percent: `${round((present / team) * 100 || 0)}%`,
                natural: row.natural || false,
            });
        }
        return rows;
    };

    const cols = [
        {
            name: 'label',
            label: 'Team Member',
        },
        {
            name: 'team',
            label: 'Number on Team',
        },
        {
            name: 'present',
            label: 'Number Present at Meeting',
        },
        {
            name: 'percent',
            label: 'Percent Attendance',
        },
    ];

    const tableData = getData(data);
    let proTotalRow = { label: 'Total:', team: 0, present: 0 };
    let natTotalRow = { label: 'Total:', team: 0, present: 0 };

    for (var r of tableData) {
        if (r.natural) {
            natTotalRow.team += r.team;
            natTotalRow.present += r.present;
        } else {
            proTotalRow.team += r.team;
            proTotalRow.present += r.present;
        }
    }

    proTotalRow.percent = round((proTotalRow.present / proTotalRow.team) * 100, 2) + '%';
    natTotalRow.percent = round((natTotalRow.present / natTotalRow.team) * 100, 2) + '%';

    return (
        <>
            {renderSubtitle('Core Team and Professional Supports')}
            <Table
                data={tableData.filter(row => !row.natural)}
                rowKey="label"
                columns={cols}
                showPerPageOptions={false}
                isCsvDownload
                footer={proTotalRow}
            />
            {renderSubtitle('Natural Supports')}
            <Table
                data={tableData.filter(row => row.natural)}
                rowKey="label"
                columns={cols}
                showPerPageOptions={false}
                isCsvDownload
                footer={natTotalRow}
            />
        </>
    );
};

MeetingAttendanceTable.propTypes = {
    data: PropTypes.object,
};

export default MeetingAttendanceTable;
