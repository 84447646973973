import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const RespondentTotalTable = props => {
    const { groupLevel, data } = props;
    const footer = data.pop();
    const cols = [
        {
            name: 'label',
            label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
            sort: true,
            filter: true,
        },
        {
            name: 'score',
            label: 'Total Satisfaction Score',
        },
    ];

    return (
        <Table
            rowKey="label"
            columns={cols}
            data={data}
            footer={footer}
            isCsvDownload
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            csvFilename="section-C-satisfaction-totals"
        />
    );
};

RespondentTotalTable.propTypes = {
    data: PropTypes.array,
    respondent: PropTypes.object,
    items: PropTypes.array,
    groupLevelMap: PropTypes.object,
    groupLevel: PropTypes.string,
};

export default RespondentTotalTable;
