import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const ExitReasonChart = props => {
    const { data } = props;

    const getData = d => {
        const orderLabelMap = {
            completedMetGoalsPOCSteppedDown: {
                label: 'Compl. Step-down',
                value: 0,
            },
            completedMetGoalsPOCDischarged: {
                label: 'Compl. Met Goals on POC',
                value: 0,
            },
            incompleteNoEngagement: { label: 'Inc. No Engagement', value: 0 },
            incompleteFamilyChoice: { label: 'Inc. Family Choice', value: 0 },
            incompleteHigherLevelOfCare: {
                label: 'Inc. Higher Level of Care',
                value: 0,
            },
            incompleteExcludedSetting: {
                label: 'Inc. Excluded Setting',
                value: 0,
            },
            incompleteDetention: { label: 'Inc. Detention', value: 0 },
            ineligibleAgedOut: { label: 'Inel. Aged Out', value: 0 },
            ineligibleLossOfInsurance: {
                label: 'Inel. Loss of Insurance',
                value: 0,
            },
            ineligibleChangeInIncome: {
                label: 'Inel. Change in Income',
                value: 0,
            },
            relocated: { label: 'Relocated', value: 0 },
            other: { label: 'Other', value: 0 },
        };
        if (d.flows.assignmentProgressSummary?.frequency) {
            for (const cc of d.flows.assignmentProgressSummary.frequency) {
                if (cc.frequency) {
                    for (const dis of cc.frequency) {
                        if (dis.value) {
                            orderLabelMap[dis.value].value += dis.valueCount;
                        }
                    }
                }
            }
        }

        return Object.keys(orderLabelMap).map(r => ({
            reason: orderLabelMap[r].label,
            count: orderLabelMap[r].value,
        }));
    };

    const chartData = getData(data);

    if (!chartData.length > 0) {
        return (
            <div style={{ width: '770px', height: '300px', textAlign: 'center' }}>
                <h2 style={{ marginTop: '125px' }}>No data</h2>
            </div>
        );
    }

    return (
        <div style={{ width: '770px', height: '450px' }}>
            <ResponsiveBar
                indexBy="reason"
                labelFormat={d => <tspan y={-8}>{d}</tspan>}
                labelSkipWidth={0}
                labelSkipHeight={1}
                keys={['count']}
                theme={{ fontSize: 16 }}
                axisBottom={{ tickRotation: 45 }}
                data={chartData}
                margin={{ top: 15, right: 150, bottom: 150, left: 50 }}
            />
        </div>
    );
};

ExitReasonChart.propTypes = {
    data: PropTypes.object,
};

export default ExitReasonChart;
