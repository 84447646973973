import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const OptOutReasonTable = props => {
    const { data } = props;

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        { name: 'unableToAttend', sort: true, label: 'Observer unable to attend meeting' },
        { name: 'noConsent', sort: true, label: 'Team did not consent to meeting observation' },
        { name: 'institution', sort: true, label: 'Youth institutionalized' },
        { name: 'discharged', sort: true, label: 'Youth discharged' },
        { name: 'other', sort: true, label: 'Other' },
    ];

    const getData = () => {
        let rows = [];
        let i = 0;
        for (var cc of Object.keys(data)) {
            if (cc !== 'otherReasons') {
                let row = { careCoord: cc.split(', ')[0], cc: `${cc}-${i}` };
                for (var col of cols) {
                    if (col.name !== 'careCoord') {
                        row[col.name] = data[cc][col.name] || 0;
                    }
                }
                rows.push(row);
            }
            i += 1;
        }
        return rows;
    };

    const getTotalRow = d => {
        let foot = {
            careCoord: 'Total:',
            unableToAttend: 0,
            noConsent: 0,
            institution: 0,
            discharged: 0,
            other: 0,
            cc: 'totalRow',
        };
        for (var row of d) {
            foot.unableToAttend += row.unableToAttend;
            foot.noConsent += row.noConsent;
            foot.institution += row.institution;
            foot.discharged += row.discharged;
            foot.other += row.other;
        }
        return foot;
    };

    const tableData = getData();
    const footer = getTotalRow(tableData);
    return (
        <Table
            data={tableData}
            rowKey="cc"
            orderBy="careCoord"
            columns={cols}
            showPerPageOptions={false}
            footer={footer}
            isCsvDownload
        />
    );
};

OptOutReasonTable.propTypes = {
    data: PropTypes.object,
};

export default OptOutReasonTable;
