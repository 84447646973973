import { startCase } from 'lodash';

export const convertDischargeSuccessRate = (data, filters) => {
    var rates = {
        complete: 0,
        incomplete: 0,
    };
    const youthUsed = [];
    for (var d of data.flows.deploymentList) {
        for (var youth of d.assignmentProgressList) {
            const { exitReason } = youth.pawn.fields;
            // exclude youth who are in multiple surveys
            if (
                !youthUsed.includes(youth.pawnId) &&
                youth.pawn.fields.youthStatus.toLowerCase() !== 'active'
            ) {
                if (exitReason && exitReason.startsWith('complete')) {
                    rates.complete++;
                } else if (exitReason) {
                    rates.incomplete++;
                } else if (exitReason === '' || exitReason === null) {
                    rates.incomplete++;
                }
                youthUsed.push(youth.pawnId);
            }
        }
    }
    const totalCount = rates.incomplete + rates.complete;
    const ratesCalc = Object.keys(rates).map(a => ({
        id: startCase(a),
        label: `${startCase(a)} (${
            rates[a] > 0 ? ((rates[a] / totalCount) * 100).toFixed(2) : 0
        }%)`,
        value: `${rates[a] || 0}`,
    }));
    return ratesCalc;
};
