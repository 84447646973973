import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const CommentsTable = props => {
    const { data, respondent } = props;

    const getData = () => {
        let rows = [];
        if (data.flows[respondent.label].comments)
            for (const c of data.flows[respondent.label].comments) {
                if (c.value) {
                    for (let i = 0; i < c.valueCount; i++) {
                        rows.push({ comment: c.value });
                    }
                }
            }
        return rows;
    };

    const cols = [{ name: 'comment', label: 'Comment' }];

    return (
        <Table
            columns={cols}
            data={getData()}
            rowKey="comment"
            orderBy="comment"
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="section-b-comments"
        />
    );
};

CommentsTable.propTypes = {
    data: PropTypes.object,
    respondent: PropTypes.object,
};

export default CommentsTable;
