import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const TypeOfMeetingTable = props => {
    const { data } = props;

    const labels = {
        1: {
            label: 'Initial Team/Planning Meeting',
            name: 'initial',
        },
        2: {
            label: 'Follow-up Meeting',
            name: 'followup',
        },
        3: {
            label: 'Transition/Discharge Meeting',
            name: 'transition',
        },
        4: {
            label: 'Other',
            name: 'other',
        },
    };

    const getData = d => {
        const rows = [];
        for (var c of Object.keys(d)) {
            const cc = props.data[c];
            const row = {
                cc: c,
                careCoord: c.split(',')[0],
                followup: 0,
                initial: 0,
                transition: 0,
                other: 0,
                ccTotal: 0,
            };
            for (var e of ['followup', 'initial', 'transition', 'other', 'ccTotal']) {
                if (cc[e]) {
                    row[e] += cc[e];
                    row.ccTotal += cc[e];
                }
            }
            rows.push(row);
        }
        return rows;
    };

    const getTotalRow = td => {
        let total = { initial: 0, followup: 0, transition: 0, other: 0, ccTotal: 0 };
        for (var row of td) {
            total.initial += row.initial;
            total.followup += row.followup;
            total.transition += row.transition;
            total.other += row.other;
            total.ccTotal += row.ccTotal;
        }
        return {
            cc: 'total',
            careCoord: 'Total:',
            initial: `${total.initial} (${round((total.initial / total.ccTotal) * 100, 2)}%)`,
            followup: `${total.followup} (${round((total.followup / total.ccTotal) * 100, 2)}%)`,
            transition: `${total.transition} (${round((total.transition / total.ccTotal) * 100, 2)}%)`,
            other: `${total.other} (${round((total.other / total.ccTotal) * 100, 2)}%)`,
        };
    };

    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        ...Object.keys(labels).map(n => ({
            name: labels[n].name,
            label: labels[n].label,
            sort: true,
            render(value, row) {
                return value > 0 ? `${value} (${round((value / row.ccTotal) * 100, 2)}%)` : 0;
            },
        })),
    ];

    const tableData = getData(data);
    const totalRow = getTotalRow(tableData);

    return (
        <Table
            data={tableData}
            rowKey="cc"
            orderBy="careCoord"
            columns={cols}
            showPerPageOptions={false}
            footer={totalRow}
            isCsvDownload
        />
    );
};

TypeOfMeetingTable.propTypes = {
    data: PropTypes.object,
};

export default TypeOfMeetingTable;
