import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import Tooltip from 'cccisd-tooltip';
import IconInfo from 'cccisd-icons/info2';
import style from './style.css';

const ExclusionTable = props => {
    const { data, respondentSelectorField } = props;

    const cols = [
        { name: 'filter', label: 'Filter' },
        { name: 'Y', label: 'Youth', id: '5' },
        { name: 'CG', label: 'Caregiver', id: '3' },
        { name: 'CC', label: 'Care Coordinator', id: '2' },
        { name: 'TM', label: 'Team Member', id: '4' },
    ].filter(c => c.name === 'filter' || respondentSelectorField.includes(c.id));

    const rows = [
        {
            name: 'invalidA',
            text: 'Invalid Section A - Missing Substantial Information',
            tooltip: 'Section A is not scored if user missed 1 or more items.',
        },
        {
            name: 'invalidBMissing',

            text: 'Invalid Section B - Missing Substantial Information',
            tooltip:
                'Entire record is not scored if Section B user responded “don’t know” or is missing 8 or more items.',
        },
        {
            name: 'invalidBSame',

            text: 'Invalid Section B - Uniform Rating Pattern',
            tooltip:
                'Entire record is not scored if all items in Section B have the same response, regardless of what the response is.',
        },
        {
            name: 'invalidC',

            text: 'Invalid Section C - Missing Substantial Information',
            tooltip:
                'Section C is not scored if user responded “Don’t Know” or is missing 1 or more items. Section C is only valid for Youth and Caregivers.',
        },
        {
            name: 'invalidD',

            text: 'Invalid Section D - Missing Substantial Information',
            tooltip:
                'Section D is not scored if user responded “Don’t Know” or is missing 2 or more items. Section D is only valid for Caregivers and Care Coordinators.',
        },
    ];

    let rowdata = [];

    for (var row of rows) {
        let r = {
            filter: (
                <div style={{ float: 'left' }}>
                    <span style={{ display: 'inline' }}>
                        <Tooltip title={row.tooltip} className={style.tooltip}>
                            <IconInfo spaceRight />
                        </Tooltip>
                    </span>
                    <span style={{ display: 'inline' }}>{row.text}</span>
                </div>
            ),
        };
        for (var col of cols) {
            if (col.name !== 'filter') {
                const cell = data[`${row.name}${col.name}`];
                if (cell === 0 || cell) {
                    r[col.name] = cell;
                } else {
                    r[col.name] = 'N/A';
                }
            }
        }
        rowdata.push(r);
    }

    return <Table data={rowdata} rowKey="filter" columns={cols} isCsvDownload csvFilename="exclusion-information" />;
};

ExclusionTable.propTypes = {
    data: PropTypes.object,
    respondentSelectorField: PropTypes.array,
};

export default ExclusionTable;
