import React from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from 'cccisd-nivo/pie';
import { pieLegend } from 'js/vendor/reports/helpers.js';
import { round, sum } from 'lodash';

const TypeOfMeetingChart = props => {
    const labels = [
        {
            label: 'Initial Team/Planning Meeting',
            name: 'initial',
        },
        {
            label: 'Follow-up Meeting',
            name: 'followup',
        },
        {
            label: 'Transition/Discharge Meeting',
            name: 'transition',
        },
        {
            label: 'Other',
            name: 'other',
        },
    ];

    let totals = {};
    let data = [];
    for (var l of labels) {
        for (var c of Object.keys(props.data)) {
            const cc = props.data[c];
            if (cc[l.name]) {
                if (totals[l.name]) {
                    totals[l.name] += cc[l.name];
                } else {
                    totals[l.name] = cc[l.name];
                }
            }
        }
    }
    const total = sum(Object.keys(totals).map(k => totals[k]));
    for (var ll of labels) {
        if (totals[ll.name]) {
            const label = `${ll.label} (${round((totals[ll.name] / total) * 100, 2)}%)`;
            data.push({ id: ll.name, label, value: totals[ll.name] });
        } else {
            const label = `${ll.label} (0%)`;
            data.push({ id: ll.name, label, value: 0 });
        }
    }

    return (
        <div style={{ width: '770px', height: '500px' }}>
            <ResponsivePie
                data={data}
                enableArcLinkLabels={false}
                theme={{ fontSize: 16 }}
                keys={['id']}
                arcLabelsSkipAngle={10}
                arcLinkLabelsSkipAngle={20}
                legends={pieLegend}
                margin={{
                    top: 50,
                    right: 0,
                    bottom: 65,
                    left: 200,
                }}
            />
        </div>
    );
};

TypeOfMeetingChart.propTypes = {
    data: PropTypes.object,
};

export default TypeOfMeetingChart;
