export const subscales = [
    {
        name: 'attendance',
        label: 'Full Meeting Attendance',
        items: ['A1', 'B1', 'C1', 'D1', 'E1', 'F1'],
    },
    {
        name: 'teamwork',
        label: 'Effective Teamwork',
        items: ['A2', 'B2', 'C2', 'D2', 'E2'],
        keyElement: true,
    },
    {
        name: 'family',
        label: 'Driven by Strengths and Families',
        items: ['A3', 'B3', 'C3', 'D3', 'E3'],
        keyElement: true,
    },
    {
        name: 'needs',
        label: 'Based on Priority Needs',
        items: ['A4', 'B4', 'C4', 'D4', 'E4'],
        keyElement: true,
    },
    {
        name: 'support',
        label: 'Use of Natural and Community Supports',
        items: ['A5', 'B5', 'C5', 'D5', 'E5'],
        keyElement: true,
    },
    {
        name: 'outcomes',
        label: 'Outcomes-Based Process',
        items: ['A6', 'B6', 'C6', 'D6', 'E6'],
        keyElement: true,
    },
    {
        name: 'facilitation',
        label: 'Skilled Facilitation',
        items: ['A7', 'B7', 'C7', 'D7', 'E7'],
    },
];
