import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';

function RespondentSummarySection(props) {
    useEffect(() => {
        if (props.groups.length > 0) {
            getData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getData = () => {
        const rows = [];
        if (props.ccoYouthMap) {
            for (const cco of Object.keys(props.ccoYouthMap)) {
                if (props.filters.careCoordSelectorField.some(cc => cc.startsWith(cco))) {
                    rows.push({
                        cc: cco,
                        ccYouthAssigned: props.ccoYouthMap[cco].length,
                    });
                }
            }
        }
        return rows;
    };

    const cols = [
        {
            name: 'cc',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        {
            name: 'ccYouthAssigned',
            label: 'Count of Youth Assessed',
            tooltip:
                'The count of youth assessed refers to the number of youth included in the report with 1 or more TOM 2.0 forms.',
            sort: true,
            filter: true,
        },
    ];

    props.setWidgetLoaded(props.section.name + 'Section');
    return (
        <Table
            data={getData()}
            rowKey="cc"
            orderBy="cc"
            columns={cols}
            footer={null}
            showPerPageOptions={false}
            isCsvDownload
        />
    );
}

RespondentSummarySection.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
    ccoYouthMap: PropTypes.object,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
    ccoYouthMap: state.app.report.ccoYouthMap,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(RespondentSummarySection);
