import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { client } from 'cccisd-apollo';
import { loadGroups } from 'js/reducers/sites.js';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { getGroupLevelMap } from 'js/vendor/reports/helpers.js';
// import { getTeamMemberFilter } from 'js/vendor/reports/reportTemplates/WFI-EZ/Youth/queryFilters.js';

const OtherResponseTable = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.groups) {
            setLoading(true);
            getData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.teamMemberSelectorField, props.groups]);

    const groupLevel = props.settings.groupLevel || 'group';

    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    const groups = getGroupLevelMap(props.groups, groupLevel, props.filters.siteSelectorField);

    const getData = async () => {
        const response = await client.query({
            query: props.query,
            variables: {
                pawnIds: props.youthPawnIds,
                // filter: getTeamMemberFilter(props.filters.teamMemberSelectorField),
                deploymentIds: props.filters.evalCycleSelectorField || [],
            },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            cancelTag: 'report',
        });
        const { youthList } = response.data.roles;
        let tableData = [];
        for (var group of Object.keys(groups)) {
            const youthInGroup = youthList.filter(y => groups[group].includes(y.pawn.groupId));
            for (var youth of youthInGroup) {
                let res = youth.fields[props.field];
                if (res) {
                    tableData.push({ label: group, response: res });
                }
            }
        }
        setData(tableData);
        setLoading(false);
    };

    const getColumns = () => {
        return [
            {
                name: 'label',
                label: groupLevel.charAt(0).toUpperCase() + groupLevel.slice(1),
                sort: true,
                filter: true,
            },
            { name: 'response', label: 'Response', sort: true, filter: true },
        ];
    };

    if (data.length === 0 && !loading) {
        return <h4>There were no other responses for youth included in this report</h4>;
    }

    if (loading) {
        return <Skeleton count={15} />;
    }

    return (
        <Table
            isCsvDownload
            columns={getColumns()}
            data={data}
            rowKey="label"
            orderBy="label"
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            csvFilename="other-responses"
        />
    );
};

OtherResponseTable.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    field: PropTypes.string,
    table: PropTypes.object,
    query: PropTypes.object,
    responsePrepend: PropTypes.string,
    groupLevel: PropTypes.string,
    // redux
    groups: PropTypes.array,
    loadGroups: PropTypes.func,
    youthPawnIds: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { loadGroups })(OtherResponseTable);
