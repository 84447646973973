import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const DataCollectionInfoTable = props => {
    const getData = () => {
        let rows = [];
        for (var cycle of props.data) {
            const siteId = parseInt(cycle.siteId, 10);
            const siteName = props.groups.find(g => g.group.groupId === siteId).group.label;
            const responseRate = cycle.numCompleted - cycle.optedOut - cycle.notConsented;
            const sampleSize = cycle.respondentCount - cycle.invalid;

            rows.push({
                site: siteName,
                cycle: cycle.label,
                cycleKey: cycle.label + siteName,
                sampleSize,
                numCompleted: responseRate,
                responseRate: `${round((responseRate / sampleSize) * 100, 2) || 0}%`,
            });
        }
        return rows;
    };

    const cols = [
        { name: 'site', label: 'Site', sort: true, filter: true },
        { name: 'cycle', label: 'Evaluation Cycle', sort: true, filter: true },
        { name: 'sampleSize', label: 'Sample Size' },
        { name: 'numCompleted', label: 'Number of completed WFI-EZs' },
        {
            name: 'responseRate',
            label: 'Response Rate',
            tooltip:
                'The response rate is calculated by dividing the number of completed WFI-EZs by the number of forms that could have been administered (i.e. the youth was enrolled in Wraparound, was not institutionalized, and accurate contact information was provided).',
        },
    ];

    return (
        <Table
            data={getData()}
            rowKey="cycleKey"
            columns={cols}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="eval-cycles-response-rates"
        />
    );
};

DataCollectionInfoTable.propTypes = {
    data: PropTypes.array,
    groups: PropTypes.array,
};

export default DataCollectionInfoTable;
