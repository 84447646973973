import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const OptOutReasonTable = props => {
    const { data } = props;

    const getData = () => {
        let rows = [];
        var i = 1;
        for (var item of data) {
            const { reasons } = item;
            if (reasons.length > 0) {
                for (var reason of reasons) {
                    rows.push({ id: i, other: reason.devTagsLists.OptOutOther });
                    i++;
                }
            }
        }
        return rows;
    };

    const cols = [
        { name: 'id', label: '#' },
        { name: 'other', label: 'Text Response' },
    ];

    return (
        <Table
            data={getData()}
            rowKey="id"
            columns={cols}
            perPage={5000}
            perPageOptions={[250, 500, 1000, 5000]}
            isCsvDownload
            csvFilename="other-responses"
        />
    );
};

OptOutReasonTable.propTypes = {
    data: PropTypes.object,
};

export default OptOutReasonTable;
