import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const CommentsTable = props => {
    const { respondent, data } = props;
    const cols = [
        {
            name: 'comment',
            label: 'Comment',
        },
    ];

    const getData = () => {
        let rows = [];

        if (data.flows[respondent.label].comments) {
            for (const c of data.flows[respondent.label].comments) {
                if (c.value) {
                    for (let i = 0; i < c.valueCount; i++) {
                        rows.push({
                            comment: c.value,
                            key: [c.value, i].join(''),
                        });
                    }
                }
            }
        }

        return rows;
    };

    return (
        <Table
            rowKey="key"
            orderBy="comment"
            columns={cols}
            data={getData()}
            perPage={5000}
            perPageOptions={[25, 50, 100, 500]}
            isCsvDownload
            csvFilename="section-D-comments"
        />
    );
};

CommentsTable.propTypes = {
    data: PropTypes.array,
    respondent: PropTypes.object,
};

export default CommentsTable;
