import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const DuplicateInfoTable = props => {
    const { data } = props;

    const cols = [
        {
            name: 'wrapId',
            label: 'Wrap ID',
        },
        {
            name: 'site',
            label: 'Site',
        },
        {
            name: 'cycles',
            label: 'Evaluation Cycles',
        },
    ];

    return <Table data={data} rowKey="wrapId" columns={cols} showPerPageOptions={false} isCsvDownload />;
};

DuplicateInfoTable.propTypes = {
    data: PropTypes.array,
};

export default DuplicateInfoTable;
